import styled, { css } from "styled-components";

const Root = styled.h2`
  ${({ theme }) => css`
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: ${theme.typography.weights.bold};
    margin: 0;
    
    @media (min-width: ${theme.breakpoints.desktop}) {
      font-size: 4rem;
    }
  `}
`;

const Letter = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.newAccentBody};
  `}
`;

const Digits = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.headline};
  `}
`;

export const TicketDisplay: React.FC<{text: string}> = ({ text }) => {
  return <Root>
    <Letter>{text[0]}</Letter>
    <Digits>{text.substring(1)}</Digits>
  </Root>;
};