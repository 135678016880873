import { Typography, } from 'antd';

import styled from 'styled-components';
import { PageContainer } from '../page_container/page_container';
import Title from 'antd/lib/typography/Title';
import { MerchantModel } from '../../../merchant_service/merchant_model';
import { useContext, useEffect, useState } from 'react';
import { LocationItem } from './location_item/location_item';
import { AddLocation } from './add_location/add_location';
import { Loading } from '../loading/loading';
import { LocationContext, LocationDispatch } from '../../../contexts/location/provider';
import { fetchAndSetLocations } from '../../../contexts/location/async_actions';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export interface LocationsPageProps {
  merchantModel: MerchantModel;
}

export const LocationsPage: React.FC<LocationsPageProps> = ({
  merchantModel,
}) => {
  const [locationState, locationDispatch] = useContext(LocationContext);
  const [fetching, setFetching] = useState(false);

  const fetchLocations = async (locationDispatch: LocationDispatch) => {
    setFetching(true);
    await fetchAndSetLocations(locationDispatch);
    setFetching(false);
  }

  useEffect(() => {
    fetchLocations(locationDispatch);
  }, [locationDispatch]);

  return (
    <PageContainer merchantModel={merchantModel}>
      <Typography>
        <ContentContainer>
          <Title>Configure Locations</Title>
          {fetching ? (
            <Loading></Loading>
          ) : (
            <Locations>
              {locationState.locations.map(location => (
                <LocationItem onDelete={() => fetchLocations(locationDispatch)} data={location} key={location._id} showDeleteButton={locationState.locations.length > 1}></LocationItem>
              ))}
              <div>
                <AddLocation onSubmit={() => fetchLocations(locationDispatch)}></AddLocation>
              </div>
            </Locations>
          )}
        </ContentContainer>
      </Typography>
    </PageContainer>
  );
};
