import { fetchTicketsFromLiveDuration } from './async_actions';

const TEN_SECONDS_MS = 1000 * 10;
const CONFIGURED_REFRESH_TIMEOUT = Number(
  process.env.REACT_APP_TICKET_REFRESH_INTERVAL_MS,
);
const REFRESH_TIMEOUT = !isNaN(CONFIGURED_REFRESH_TIMEOUT)
  ? CONFIGURED_REFRESH_TIMEOUT
  : TEN_SECONDS_MS;

let pollerId: number | null = null;

export function startPollingForTickets(
  ...args: Parameters<typeof fetchTicketsFromLiveDuration>
) {
  stopPollingForTickets();
  pollerId = window.setInterval(() => {
    fetchTicketsFromLiveDuration(...args);
  }, REFRESH_TIMEOUT);
}

export function stopPollingForTickets() {
  if (pollerId === null) return;
  clearInterval(pollerId);
  pollerId = null;
}
