import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 1.5rem;
`;

export const InputContainer: React.FC = ({ children }) => (
  <Root>
    {children}
  </Root>
);
