import styled, { css } from 'styled-components';

const Root = styled.button`
  background: transparent;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ theme }) => css`
    color: ${theme.colors.surface};
    font-size: ${theme.typography.sizes.heading5};
    border: 0.125rem solid ${theme.colors.hint};
    border-radius: 0.25rem;
    margin-left: 2rem;
  `}
`;

export interface ToastButtonProps {
  onClick: () => void;
}

export const ToastButton: React.FC<ToastButtonProps> = ({
  children,
  onClick,
}) => <Root onClick={onClick}>{children}</Root>;
