import { TicketApiObject, TicketState, UpdateTicketResult } from '../../declarations';
import { ToastContent } from './toast_content';
import { ToastButton } from './toast_button';

import { unarchiveTicketAsOpen, unarchiveTicketAsPending } from '../../contexts/ticket/async_actions';
import { useContext } from 'react';
import { TicketContext } from '../../contexts/ticket/provider';
import toast from 'react-hot-toast';
import { BiUndo } from 'react-icons/bi';

export interface OrderArchivedProps {
  data: TicketApiObject;
  previousState: TicketState;
}

export const OrderArchived: React.FC<OrderArchivedProps> = ({ data, previousState }) => {
  const [, ticketDispatch] = useContext(TicketContext);

  return (
    <ToastContent>
      Order Archived.
      <ToastButton
        onClick={async () => {
          let result: UpdateTicketResult;
          if (previousState === TicketState.OPEN) {
            result = await unarchiveTicketAsOpen(ticketDispatch, data._id);
          } else {
            result = await unarchiveTicketAsPending(ticketDispatch, data._id);
          }
          if (result === UpdateTicketResult.SUCCESS) {
            toast.dismiss();
          } else {
            toast(() => <ToastContent error>Unable to unarchive. Please try again.</ToastContent>);
          }
        }}>
        <BiUndo></BiUndo>
        Undo
      </ToastButton>
    </ToastContent>
  );
};
