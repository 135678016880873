import { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { LocationContext } from '../../../contexts/location/provider';
import { setLiveDurationAndRefetchTickets } from '../../../contexts/ticket/async_actions';
import { TicketContext } from '../../../contexts/ticket/provider';
import { LiveDurationButtons } from '../live_duration_buttons/live_duration_buttons';
import { RefreshTicketsButton } from '../refresh_tickets_button/refresh_tickets_button';

const Root = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

export const FetchSettings: React.FC = () => {
  const [ticketState, ticketDispatch] = useContext(TicketContext);
  const [locationState] = useContext(LocationContext);

  const handleRefresh = useCallback(() => {
    setLiveDurationAndRefetchTickets(
      ticketDispatch,
      locationState.currentLocationId,
      ticketState.liveDuration,
    );
  }, [locationState.currentLocationId, ticketState.liveDuration]);

  return (
    <Root>
      <LiveDurationButtons></LiveDurationButtons>
      <RefreshTicketsButton
        onClick={() => handleRefresh()}></RefreshTicketsButton>
    </Root>
  );
};
