import { MessageDeliveryStatus, KnownMessageType } from '../declarations';

export function mapMessageTypeToLabel(type: KnownMessageType | string): string {
  switch (type) {
    case KnownMessageType.ORDER_READY:
      return 'Order ready message';
    case KnownMessageType.FOLLOW_UP:
      return 'Follow-up message';
    case KnownMessageType.ISSUE_WITH_ORDER:
      return 'Issue-with-order message';
    default:
      return '';
  }
}

export function getMessageDeliveryStatusLabel(
  messageDeliveryStatus: MessageDeliveryStatus,
  hasBeenNotified: boolean,
): string {
  switch (messageDeliveryStatus) {
    case MessageDeliveryStatus.DELIVERED:
      return 'Delivered';
    case MessageDeliveryStatus.DELIVERY_UNKNOWN:
      return 'Delivery unknown';
    case MessageDeliveryStatus.FAILED:
      return 'Failed';
    case MessageDeliveryStatus.SENT:
      return 'Sent';
    case MessageDeliveryStatus.UNDELIVERED:
      return 'Undelivered';
    case MessageDeliveryStatus.UNSET:
      if (hasBeenNotified) {
        return 'Pending';
      }
      return '';
  }
}

export function getDeliveryStatusMeaning(
  messageDeliveryStatus: MessageDeliveryStatus,
  hasBeenNotified: boolean,
): string {
  switch (messageDeliveryStatus) {
    case MessageDeliveryStatus.DELIVERED:
      // Delivered
      return 'This means that the carrier confirmed delivery to the recepient.';
    case MessageDeliveryStatus.DELIVERY_UNKNOWN:
      // Delivery unknown
      return "This means that the carrier could not confirm delivery to the recepient, or it has been too long since we've been notified of a successful delivery.";
    case MessageDeliveryStatus.FAILED:
      // Failed
      return 'This means that we could not send the message. Please contact support if this keeps occuring.';
    case MessageDeliveryStatus.SENT:
      // Sent
      return 'This means that the message was sent but we have not yet confirmed delivery.';
    case MessageDeliveryStatus.UNDELIVERED:
      // Undelivered
      return 'This means that the message never arrived. Common reasons for this include the carrier or handset being unreachable.';
    case MessageDeliveryStatus.UNSET:
      if (hasBeenNotified) {
        // Pending
        return 'This means that the message is still being sent by our system.';
      }
      return '';
  }
}

export enum DeliveryStatusNature {
  SUCCESS,
  FAILED,
  UNKNOWN,
}

export function getDeliveryStatusNature(
  messageDeliveryStatus: MessageDeliveryStatus,
): DeliveryStatusNature {
  switch (messageDeliveryStatus) {
    case MessageDeliveryStatus.DELIVERED:
      return DeliveryStatusNature.SUCCESS;
    case MessageDeliveryStatus.DELIVERY_UNKNOWN:
    case MessageDeliveryStatus.FAILED:
    case MessageDeliveryStatus.UNDELIVERED:
      return DeliveryStatusNature.FAILED;
    case MessageDeliveryStatus.UNSET:
    case MessageDeliveryStatus.SENT:
      // Sent is not a known-good status since it can ultimately
      // fail to reach the user's device (undelivered).
      return DeliveryStatusNature.UNKNOWN;
  }
}
