import styled, { css } from 'styled-components';
import { BiUndo } from 'react-icons/bi';

import { Button, ButtonStyle, ButtonEmphasis } from '../../../button/button';
import { ButtonContent } from '../../../button/button_content';
import { TicketApiObject, UpdateTicketResult } from '../../../../declarations';
import {
  unarchiveTicketAsOpen,
  unarchiveTicketAsPending,
} from '../../../../contexts/ticket/async_actions';
import { useContext } from 'react';
import { TicketContext } from '../../../../contexts/ticket/provider';
import toast from 'react-hot-toast';
import { ToastContent } from '../../../toast_content/toast_content';

const Root = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.desktop}) {
      flex-direction: row;
      justify-content: center;
    }
  `}
`;

interface ArchivedTicketActionsProps {
  data: TicketApiObject;
}

const BUTTON_GAP = '.75rem';
const ICON_SIZE = '2rem';

export const ArchivedTicketActions: React.FC<ArchivedTicketActionsProps> = ({
  data,
}) => {
  const [, ticketDispatch] = useContext(TicketContext);
  const handleMarkAsOpenClick = async () => {
    const result = await unarchiveTicketAsOpen(ticketDispatch, data._id);
    toast(
      result === UpdateTicketResult.SUCCESS ? (
        <ToastContent>Unarchived and marked as open</ToastContent>
      ) : (
        <ToastContent error>Unable to open ticket</ToastContent>
      ),
    );
  };
  const handleMarkAsPendingClick = async () => {
    const result = await unarchiveTicketAsPending(ticketDispatch, data._id);
    toast(
      result === UpdateTicketResult.SUCCESS ? (
        <ToastContent>Unarchived and marked as pending</ToastContent>
      ) : (
        <ToastContent error>Unable to open ticket</ToastContent>
      ),
    );
  };

  return (
    <Root>
      <Button
        buttonStyle={ButtonStyle.PRIMARY}
        buttonEmphasis={ButtonEmphasis.SECONDARY}
        onClick={handleMarkAsOpenClick}>
        <ButtonContent gap={BUTTON_GAP}>
          <BiUndo size={ICON_SIZE}></BiUndo>
          Re-open
        </ButtonContent>
      </Button>
      <Button
        buttonStyle={ButtonStyle.SECONDARY}
        buttonEmphasis={ButtonEmphasis.SECONDARY}
        onClick={handleMarkAsPendingClick}>
        <ButtonContent gap={BUTTON_GAP}>
          <BiUndo size={ICON_SIZE}></BiUndo>
          Re-open (Pending)
        </ButtonContent>
      </Button>
    </Root>
  );
};
