import { ApiClient } from '../../api_client/api_client';
import { MerchantActionType } from './actions';
import { MerchantDispatch } from './provider';

const apiClient = new ApiClient();

/**
 * @returns a boolean indicating successful dispatch.
 */
export async function fetchAndSetMerchant(
  dispatch: MerchantDispatch,
): Promise<boolean> {
  const merchant = await apiClient.fetchMerchant();
  if (!merchant) return false;

  dispatch({
    type: MerchantActionType.SET_MERCHANT,
    merchant,
  });
  return true;
}

/**
 * @returns a boolean indicating successful dispatch.
 */
export async function fetchAndSetOnboardStatus(
  dispatch: MerchantDispatch,
): Promise<boolean> {
  const status = await apiClient.fetchOnboardStatus();
  if (status === undefined) return false;
  dispatch({
    type: MerchantActionType.SET_ONBOARD_STATUS,
    status,
  });
  return true;
}
