import { LocationApiObject } from '../../declarations';

export enum LocationActionType {
  SET_CURRENT_LOCATION = 'set_current_locations',
  SET_LOCATIONS = 'set_locations',
  RESET_LOCATIONS = 'reset_locations'
}

export type LocationAction =
  | { type: LocationActionType.SET_CURRENT_LOCATION; currentLocationId: string }
  | { type: LocationActionType.SET_LOCATIONS; locations: LocationApiObject[] }
  | { type: LocationActionType.RESET_LOCATIONS };
