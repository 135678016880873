import { ApiClient } from '../../api_client/api_client';
import { TicketState, UpdateTicketResult } from '../../declarations';
import { TicketActionType, TicketLiveDuration } from './actions';
import { TicketDispatch } from './provider';
import { startPollingForTickets } from './poller';

const apiClient = new ApiClient();

export async function fetchTicketsFromLiveDuration(
  dispatch: TicketDispatch,
  locationId: string,
  liveDuration: TicketLiveDuration,
): Promise<boolean> {
  const tickets = await apiClient.fetchTickets({
    locationId,
    sinceMs: Date.now() - Number(liveDuration),
  });
  if (Array.isArray(tickets)) {
    dispatch({ type: TicketActionType.SET_TICKETS, tickets });
    return true;
  } else {
    return false;
  }
}

export async function setLiveDurationAndRefetchTickets(
  dispatch: TicketDispatch,
  locationId: string,
  liveDuration: TicketLiveDuration,
): Promise<boolean> {
  dispatch({
    type: TicketActionType.SET_LIVE_DURATION,
    duration: liveDuration,
  });
  try {
    await fetchTicketsFromLiveDuration(dispatch, locationId, liveDuration);
    startPollingForTickets(dispatch, locationId, liveDuration);
    return true;
  } catch {
    return false;
  }
}

export async function markTicketAsPendingPickup(
  dispatch: TicketDispatch,
  ticketId: string,
): Promise<UpdateTicketResult> {
  return await updateTicketState(
    dispatch,
    ticketId,
    TicketState.PENDING_PICKUP,
  );
}

export async function markTicketAsFollowedUp(
  dispatch: TicketDispatch,
  ticketId: string,
): Promise<UpdateTicketResult> {
  const result = await apiClient.updateTicket({
    id: ticketId,
    hasBeenFollowedUp: true,
  });
  if (result === UpdateTicketResult.SUCCESS) {
    dispatch({
      type: TicketActionType.FOLLOW_UP,
      ticketId,
    });
  }
  return result;
}

export async function markTicketAsArchived(
  dispatch: TicketDispatch,
  ticketId: string,
): Promise<UpdateTicketResult> {
  return await updateTicketState(dispatch, ticketId, TicketState.ARCHIVED);
}

export async function markTicketAsHavingAnIssue(
  dispatch: TicketDispatch,
  ticketId: string,
): Promise<UpdateTicketResult> {
  const result = await apiClient.updateTicket({
    id: ticketId,
    hasIssue: true,
  });
  if (result === UpdateTicketResult.SUCCESS) {
    dispatch({
      type: TicketActionType.MARK_ISSUE,
      ticketId,
    });
  }
  return result;
}

export async function unarchiveTicketAsOpen(
  dispatch: TicketDispatch,
  ticketId: string,
): Promise<UpdateTicketResult> {
  return await updateTicketState(
    dispatch,
    ticketId,
    TicketState.OPEN,
    /* sendMessage= */ false,
  );
}

export async function unarchiveTicketAsPending(
  dispatch: TicketDispatch,
  ticketId: string,
): Promise<UpdateTicketResult> {
  return await updateTicketState(
    dispatch,
    ticketId,
    TicketState.PENDING_PICKUP,
    /* sendMessage= */ false,
  );
}

async function updateTicketState(
  dispatch: TicketDispatch,
  id: string,
  state: TicketState,
  sendMessage?: boolean,
): Promise<UpdateTicketResult> {
  const result = await apiClient.updateTicket({ id, state, sendMessage });
  if (result === UpdateTicketResult.SUCCESS) {
    dispatch({
      type: TicketActionType.UPDATE_TICKET_STATE,
      ticketId: id,
      ticketState: state,
    });
  }
  return result;
}
