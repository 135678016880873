import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import styled, { css, ThemeContext } from 'styled-components';
import { Toaster } from 'react-hot-toast';

import { TicketList } from './ticket_list/ticket_list';
import { CreateTicket } from './create_ticket/create_ticket';
import { KioskSidebar } from './kiosk_sidebar/kiosk_sidebar';
import { TicketArchive } from './ticket_archive/ticket_archive';
import { hasActiveToken } from '../../utils/auth_util';
import { TicketProvider } from '../../contexts/ticket/provider';
import { useContext } from 'react';

const Root = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    display: grid;
    font-size: 1.125rem;
    grid-template-areas:
      'sidebar'
      'content';
    grid-template-rows: min-content 1fr;

    @media (min-width: ${theme.breakpoints.desktop}) {
      grid-template-columns: 198px 1fr;
      grid-template-areas: 'sidebar content';
      grid-template-rows: initial;
    }
  `}
`;

const Content = styled.div`
  grid-template-area: content;
  height: 100%;
  padding: 1.5rem;
`;

export const Kiosk = withRouter(({ location }) => {
  const theme = useContext(ThemeContext);

  if (location.pathname === '/') {
    return <Redirect to="/create"></Redirect>;
  }

  if (!hasActiveToken()) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <TicketProvider>
      <Root>
        <KioskSidebar></KioskSidebar>
        <Content>
          <Switch>
            <Route path="/create">
              <CreateTicket></CreateTicket>
            </Route>
            <Route path="/tickets">
              <TicketList></TicketList>
            </Route>
            <Route path="/archive">
              <TicketArchive></TicketArchive>
            </Route>
          </Switch>
        </Content>
      </Root>
      <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 3000,
            style: { padding: '0', background: theme.colors.body },
          }}></Toaster>
    </TicketProvider>
  )
});