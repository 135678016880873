import { Typography, Input, Button, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiClient } from '../../../../api_client/api_client';

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const EditAdminPasscode: React.FC = () => {
  const [currentAdminPasscode, setCurrentAdminPasscode] = useState('');
  const [newAdminPasscode, setNewAdminPasscode] = useState('');
  const [confirmationAdminPasscode, setConfirmationAdminPasscode] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);

  const canSubmit = useCallback(() => {
    if (newAdminPasscode !== confirmationAdminPasscode) return false;
    if (newAdminPasscode.length === 0) return false;
    return true;
  }, [newAdminPasscode, confirmationAdminPasscode]);

  useEffect(() => {
    if (newAdminPasscode !== confirmationAdminPasscode) {
      setError('New admin passcodes do not match.');
    } else {
      setError('');
    }
  }, [newAdminPasscode, confirmationAdminPasscode]);

  const reset = useCallback(() => {
    setCurrentAdminPasscode('');
    setNewAdminPasscode('');
    setConfirmationAdminPasscode('');
  }, []);

  const handleSave = useCallback(async () => {
    setProcessing(true);
    const success = await apiClient.updateAdminPasscode({
      currentAdminPasscode,
      newAdminPasscode,
    });
    if (success) {
      notification.success({
        message: 'Successfully updated admin passcode',
      });
      reset();
    } else {
      notification.error({
        message: 'Invalid current admin passcode',
      });
    }
    setProcessing(false);
  }, [setProcessing, reset, currentAdminPasscode, newAdminPasscode]);

  return (
    <Root>
      <Typography.Title level={2}>Edit Admin Passcode</Typography.Title>
      <div>
        <Typography.Paragraph>Current admin passcode:</Typography.Paragraph>
        <Input
          value={currentAdminPasscode}
          onChange={e => setCurrentAdminPasscode(e.target.value)}
          type="password"></Input>
      </div>
      <div>
        <Typography.Paragraph>New admin passcode:</Typography.Paragraph>
        <Input
          value={newAdminPasscode}
          onChange={e => setNewAdminPasscode(e.target.value)}
          type="password"></Input>
      </div>
      <div>
        <Typography.Paragraph>Confirm new admin passcode:</Typography.Paragraph>
        <Input
          value={confirmationAdminPasscode}
          onChange={e => setConfirmationAdminPasscode(e.target.value)}
          type="password"></Input>
      </div>
      {error && (
        <div>
          <Typography.Paragraph type="danger">{error}</Typography.Paragraph>
        </div>
      )}
      <div>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
          disabled={processing || !canSubmit()}
          loading={processing}>
          Save
        </Button>
      </div>
    </Root>
  );
};
