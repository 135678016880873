import { useCallback, useState } from 'react';
import { Divider, Form, Input, Typography } from 'antd';
import styled from 'styled-components';
import { Button, Alert } from 'antd';
import { useHistory } from 'react-router';

import { InputLabel } from '../../input_label/input_label';
import { InputContainer } from '../../input_container/input_container';
import { ApiClient } from '../../../api_client/api_client';
import { storeToken } from '../../../utils/auth_util';
import { SignupResponse } from '../../../api_client/declarations';
import { CreateMerchantStatus } from '../../../declarations';
import { AuthPage } from '../../auth_page/auth_page';
import {
  PasswordValidation,
  PASSWORD_SCHEMA,
} from '../../password_validation/password_validation';
import { Center } from '../../center/center';
import { RenderablePartner } from '../../../utils/partner_util';
import { REFERRER_QUERY_KEY } from '../../../constants/url_constants';

const apiClient = new ApiClient();

const NoAccountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`;

const AlertContainer = styled.div`
  margin: 1rem 0;
`;

export interface SignupFormProps {
  partner?: RenderablePartner;
}

export const SignupForm: React.FC<SignupFormProps> = ({ partner }) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [adminPasscode, setAdminPasscode] = useState('');
  const [error, setError] = useState('');
  const [inFlight, setInFlight] = useState(false);

  const isValid = useCallback(() => {
    const allFieldsPopulated: boolean = Boolean(
      username && password && email && merchantName && adminPasscode,
    );
    return allFieldsPopulated && PASSWORD_SCHEMA.validate(password);
  }, [username, password, email, merchantName, adminPasscode]);

  const handleSubmit = async () => {
    setInFlight(true);
    const response: SignupResponse = await apiClient.signup({
      username,
      password,
      email,
      merchantName,
      adminPasscode,
      affiliateReferrerId:
        new URLSearchParams(window.location.search).get(REFERRER_QUERY_KEY) ??
        undefined,
      marketPartner: partner?.name,
    });
    if (response.status !== CreateMerchantStatus.SUCCESS) {
      setInFlight(false);
      if (response.status === CreateMerchantStatus.FAILED_USERNAME_EXISTS) {
        setError('Username already exists');
        return;
      }
      if (response.status === CreateMerchantStatus.FAILED_EMAIL_EXISTS) {
        setError('Email already exists');
        return;
      }
      if (response.status === CreateMerchantStatus.FAILED_INVALID_REFERRER) {
        setError('The referrer ID in the URL is invalid. Please try again.');
        const url = new URL(window.location.href);
        url.searchParams.delete(REFERRER_QUERY_KEY);

        window.history.replaceState(
          {},
          '',
          url.search ? `${url.pathname}?${url.search}` : url.pathname,
        );
        return;
      }
      setError('Unable to create account. Please try again.');
      return;
    }
    // Runtime error, should not occur.
    if (!response.token) {
      setInFlight(false);
      setError(
        'Unable to authenticate after creating account. Please try logging in.',
      );
      return;
    }
    storeToken(response.token);
    history.push('/dashboard/locations');
  };

  return (
    <AuthPage>
      <Typography.Title level={4}>
        <Center>Sign Up</Center>
      </Typography.Title>
      <AlertContainer>
        <Alert
          showIcon
          message="Your account comes with 500 free credits 😎"
          type="info"
        />
      </AlertContainer>
      <Divider />
      {partner && (
        <Typography.Paragraph>
          <Center>(Market partner: {partner?.label})</Center>
        </Typography.Paragraph>
      )}
      <Form onFinish={handleSubmit}>
        <InputContainer>
          <InputLabel htmlFor="username">Username:</InputLabel>
          <Input
            size="large"
            id="username"
            value={username}
            onChange={e => void setUsername(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="password">
            Password:
            <br />
            <Typography.Text type="secondary">
              Your staff will need to use this password to login to the app.
            </Typography.Text>
          </InputLabel>
          <Input.Password
            size="large"
            id="password"
            value={password}
            onChange={e => void setPassword(e.target.value)}></Input.Password>
        </InputContainer>
        <PasswordValidation password={password}></PasswordValidation>
        <InputContainer>
          <InputLabel htmlFor="email">Email:</InputLabel>
          <Input
            size="large"
            type="email"
            id="email"
            value={email}
            onChange={e => void setEmail(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="merchant-name">Store Name:</InputLabel>
          <Input
            size="large"
            id="merchant-name"
            value={merchantName}
            onChange={e => void setMerchantName(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="admin-passcode">
            Admin Passcode:
            <br />
            <Typography.Text type="secondary">
              Used to access administrative settings, like the message
              templates.
            </Typography.Text>
          </InputLabel>
          {/* TODO: add validation to passcode */}
          <Input.Password
            size="large"
            id="admin-passcode"
            value={adminPasscode}
            onChange={e =>
              void setAdminPasscode(e.target.value)
            }></Input.Password>
        </InputContainer>
        <Typography.Paragraph>
          By clicking "Sign Up" below, you agree to our&nbsp;
          <a
            href="https://swiftexpo.io/terms-of-service/"
            target="_blank"
            rel="noreferrer">
            Terms and Conditions
          </a>
          .
        </Typography.Paragraph>
        <Center>
          <Button
            loading={inFlight}
            disabled={!isValid()}
            type="primary"
            size="large"
            htmlType="submit">
            Sign Up
          </Button>
        </Center>
        {error && (
          <Center>
            <Typography.Paragraph
              style={{ marginTop: '1rem', textAlign: 'right' }}
              type="danger">
              {error}
            </Typography.Paragraph>
          </Center>
        )}
      </Form>
      <Divider />
      <NoAccountContainer>
        <Typography.Text>Already have an account?</Typography.Text>
        <Button
          type="link"
          size="large"
          htmlType="button"
          onClick={() => history.push('/login')}>
          Login
        </Button>
      </NoAccountContainer>
    </AuthPage>
  );
};
