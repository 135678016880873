import styled from 'styled-components';
import { BiRefresh } from 'react-icons/bi';
import { KioskButton } from '../kiosk_button/kiosk_button';

const Icon = styled.div`
  font-size: 1.45rem;
  line-height: 1.45rem;
`;

export const RefreshTicketsButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <KioskButton onClick={onClick}>
      <Icon>
        <BiRefresh></BiRefresh>
      </Icon>
    </KioskButton>
  );
};
