import { Typography, Input, Button, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiClient } from '../../../../api_client/api_client';
import { PasswordValidation, PASSWORD_SCHEMA } from '../../../password_validation/password_validation';

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const EditPassword: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);

  const canSubmit = useCallback(() => {
    if (newPassword !== confirmationPassword) return false;
    if (newPassword.length === 0) return false;
    return PASSWORD_SCHEMA.validate(newPassword);
  }, [newPassword, confirmationPassword]);

  useEffect(() => {
    if (!PASSWORD_SCHEMA.validate(newPassword)) {
      setError('New password is invalid.');
    } else if (newPassword !== confirmationPassword) {
      setError('New passwords do not match.');
    } else {
      setError('');
    }
  }, [newPassword, confirmationPassword]);

  const reset = useCallback(() => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmationPassword('');
  }, []);

  const handleSave = useCallback(async () => {
    setProcessing(true);
    const success = await apiClient.updatePassword({
      currentPassword,
      newPassword,
    });
    if (success) {
      notification.success({
        message: 'Successfully updated password',
      });
      reset();
    } else {
      notification.error({
        message: 'Invalid current password',
      });
    }
    setProcessing(false);
  }, [setProcessing, reset, currentPassword, newPassword]);

  return (
    <Root>
      <Typography.Title level={2}>Edit Password</Typography.Title>
      <div>
        <Typography.Paragraph>Current Password:</Typography.Paragraph>
        <Input
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          type="password"></Input>
      </div>
      <div>
        <Typography.Paragraph>New password:</Typography.Paragraph>
        <Input
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          type="password"></Input>
        <PasswordValidation password={newPassword}></PasswordValidation>
      </div>
      <div>
        <Typography.Paragraph>Confirm new password:</Typography.Paragraph>
        <Input
          value={confirmationPassword}
          onChange={e => setConfirmationPassword(e.target.value)}
          type="password"></Input>
      </div>
      {error && (
        <div>
          <Typography.Paragraph type="danger">{error}</Typography.Paragraph>
        </div>
      )}
      <div>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
          disabled={processing || !canSubmit()}
          loading={processing}>
          Save
        </Button>
      </div>
    </Root>
  );
};
