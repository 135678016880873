import { useReducer, createContext } from 'react';
import { MerchantAction } from './actions';
import { reducer, initialState, MerchantState } from './reducer';

export interface MerchantDispatch {
  (action: MerchantAction): void;
}

export const MerchantContext = createContext([
  initialState,
  () => null,
] as [MerchantState, MerchantDispatch]);

export const MerchantProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MerchantContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </MerchantContext.Provider>
  )
};