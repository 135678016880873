import styled, { css } from "styled-components";
import { Typography, Button, Input, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { SyntheticEvent, useCallback, useState } from 'react';
import { MerchantModel } from "../../../../merchant_service/merchant_model";

const {Paragraph, Title} = Typography;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Username = styled.p`
  ${({ theme }) => css`
    border-left: .25rem solid ${theme.colors.hairline};
    padding: .25rem .75rem;
    font-weight: ${theme.typography.weights.bold};
    margin: 0;
  `}
`;

interface BasicSettingsProps {
  merchantModel: MerchantModel;
}

export const BasicSettings: React.FC<BasicSettingsProps> = ({ merchantModel }) => {
  const [email, setEmail] = useState<string>(
    merchantModel?.getEmail() ?? '',
  );
  const [merchantName, setMerchantName] = useState(merchantModel.getName());
  const [processing, setProcessing] = useState(false);

  const handleMerchantNameChange = useCallback((e: SyntheticEvent) => {
    const newMerchantName = (e.target as HTMLInputElement).value;
    setMerchantName(newMerchantName);
    merchantModel.setName(newMerchantName);
  }, [setMerchantName, merchantModel]);

  const handleEmailChange = useCallback((e: SyntheticEvent) => {
    const newEmail = (e.target as HTMLInputElement).value;
    setEmail(newEmail);
    merchantModel?.setEmail(newEmail);
  }, [setEmail, merchantModel]);

  const handleSave = useCallback(async () => {
    setProcessing(true);
    const result = await merchantModel?.save();
    if (result) {
      notification.success({
        message: (
          <div>
            <Paragraph>Successfully updated merchant settings.</Paragraph>
          </div>
        ),
        placement: 'bottomRight',
        duration: 8,
      });
    } else {
      notification.error({
        message: 'Error updating merchant settings.',
        placement: 'bottomRight',
      });
    }
    setProcessing(false);
  }, [merchantModel, setProcessing]);

  return (
  <Root>
      <Title level={2}>Settings</Title>
      <div>
        <Paragraph>Account Username (cannot be modified)</Paragraph>
        <Username>{merchantModel.getUsername()}</Username>
      </div>
      <div>
        <Paragraph>Store Name:</Paragraph>
        <Input
          value={merchantName}
          onChange={handleMerchantNameChange}
          type="text"></Input>
      </div>
      <div>
        <Paragraph>Email:</Paragraph>
        <Input
          value={email}
          onChange={handleEmailChange}
          type="email"></Input>
      </div>
      <div>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSave}
          disabled={processing}
          loading={processing}>
          Save
        </Button>
      </div>
  </Root> 
  );
};