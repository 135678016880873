import styled, { css } from 'styled-components';

const Root = styled.div<{ condition: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    transition: transform 0.6s ${theme.transitions.easeOutQuad};
    transform-origin: center;
  `}

  ${({ condition }) =>
    condition &&
    css`
      transform: rotate(180deg);
    `}
`;

export interface RotateIfProps {
  condition: boolean;
}

export const RotateIf: React.FC<RotateIfProps> = ({ condition, children }) => {
  return <Root condition={condition}>{children}</Root>;
};
