import { Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Pre } from '../../../pre/pre';

const { Title, Paragraph } = Typography;

export const VariableSubstitutions: React.FC = () => {
  return (
    <div>
      <Title level={4}>Variable Substitutions</Title>
      <Paragraph>
        You can use "variables" to replace certain parts of your message with
        information specific to each customer.
      </Paragraph>
      <Paragraph>The currently supported variables are:</Paragraph>
      <Paragraph>
        <ul>
          <li>
            <code>#&#123;merchant&#125;</code> – Merchant name
          </li>
          <li>
            <code>#&#123;ticket&#125;</code> – Ticket number
          </li>
        </ul>
      </Paragraph>
      <Paragraph type="secondary">
        <InfoCircleOutlined style={{ marginRight: '.25rem' }} />
        All unsupported variables will be removed.
      </Paragraph>
      <Paragraph>For example, this message:</Paragraph>
      <Paragraph>
        <Pre>
          Hi, this is #&#123;merchant&#125;. Your order (#&#123;ticket&#125;) is
          ready!
        </Pre>
      </Paragraph>
      <Paragraph>
        For a store named "100Donuts" and for a customer with ticket "Y35", this
        will send a message that reads:
      </Paragraph>
      <Paragraph>
        <Pre>Hi, this is 100Donuts. Your order (Y35) is ready!</Pre>
      </Paragraph>
    </div>
  );
};
