import styled from 'styled-components';
import { Typography, Button, Card } from 'antd';
import assert from 'assert-ts';
import { PricingTable } from './pricing_table/pricing_table';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useContext, useState } from 'react';

import { ApiClient } from '../../api_client/api_client';
import { SetupPayment } from './setup_payment/setup_payment';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AdminProtected } from '../admin_protected/admin_protected';
import { MerchantContext } from '../../contexts/merchant/provider';
import { OnboardStatus } from '../../declarations';
import { fetchAndSetOnboardStatus } from '../../contexts/merchant/async_actions';
import { MerchantActionType } from '../../contexts/merchant/actions';
import { Loading } from '../dashboard/loading/loading';

const { Title, Paragraph, Text } = Typography;

const stripePromise = loadStripe(
  assert(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
);

const apiClient = new ApiClient();

const Root = styled.div`
  height: 100%;
  width: 100%;
  padding: 5rem 0;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 42.5rem;
  width: 100%;
`;

export const Onboard: React.FC = () => {
  const [merchantState, merchantDispatch] = useContext(MerchantContext);
  const [inFlight, setInFlight] = useState(false);
  const [startingCredits, setStartingCredits] = useState(0);
  const [error, setError] = useState('');
  const [initializationError, setInitializationError] = useState('');

  const fetchAndSetStartingCredits = async () => {
    const { result, success } = await apiClient.fetchStartingCredits();
    if (!success) {
      setInitializationError(
        'Unable to initialize onboarding. Please try again.',
      );
      return;
    }
    setStartingCredits(result);
  };

  useEffect(() => {
    fetchAndSetOnboardStatus(merchantDispatch);
  }, [merchantDispatch]);

  useEffect(() => {
    if (startingCredits !== 0) return;
    fetchAndSetStartingCredits();
  }, [startingCredits, setStartingCredits]);

  const handleRegisterButtonClick = async () => {
    setInFlight(true);
    setError('');
    const succeeded = await apiClient.createStripeSubscription();
    setInFlight(false);
    if (succeeded) {
      merchantDispatch({
        type: MerchantActionType.SET_ONBOARD_STATUS,
        status: OnboardStatus.PAYMENT_METHOD_MISSING,
      });
    } else {
      setError(
        'Error initializing payment input. Please refresh and try again.',
      );
    }
  };

  if (merchantState.onboardStatus === OnboardStatus.ONBOARDED) {
    return <Redirect to="/dashboard"></Redirect>;
  }

  if (initializationError) {
    return <Paragraph type="danger">{initializationError}</Paragraph>;
  }

  if (startingCredits === 0) {
    return <Loading></Loading>;
  }

  return (
    <AdminProtected>
      <Elements stripe={stripePromise}>
        <Root>
          <Content>
            <Title>Welcome to SwiftExpo!</Title>
            <Paragraph>
              You can try out the app with {startingCredits} free credits.
            </Paragraph>
            <PricingTable startingCredits={startingCredits}></PricingTable>
            <Paragraph>
              We'll need your credit card information to complete registration.
              We will bill once a month for your usage (after your free credits
              are used.)
            </Paragraph>
            <Paragraph>
              {(() => {
                if (merchantState.onboardStatus === OnboardStatus.NONE) {
                  return (
                    <div>
                      <Paragraph>
                        <Button
                          loading={inFlight}
                          onClick={handleRegisterButtonClick}
                          size="large"
                          type="primary">
                          Enter Payment Information
                        </Button>
                      </Paragraph>
                    </div>
                  );
                }

                if (error) {
                  return <Text type="danger">{error}</Text>;
                }

                return (
                  <Card>
                    <SetupPayment onCompleteRedirectPath="/dashboard/account"></SetupPayment>
                  </Card>
                );
              })()}
            </Paragraph>
          </Content>
        </Root>
      </Elements>
    </AdminProtected>
  );
};
