import styled, { css } from 'styled-components';
import { useState } from 'react';

const Button = styled.button<{ deleteButton: boolean; pressed: boolean }>`
  ${({ theme, deleteButton, pressed }) => css`
    border: 0;
    background: ${deleteButton ? theme.colors.hint : theme.colors.newAccent};
    color: ${theme.colors.surface};
    font-size: 2.5rem;
    font-weight: 700;
    border-radius: 0.5rem;
    width: 100%;
    cursor: pointer;

    ${pressed &&
    css`
      background: ${deleteButton
        ? theme.colors.label
        : theme.colors.newAccentBody};
    `}
  `}
`;

interface NumberPadButtonProps {
  onClick: () => void;
  deleteButton?: boolean;
}

export const NumberPadButton: React.FC<NumberPadButtonProps> = ({
  children,
  deleteButton = false,
  onClick,
}) => {
  const [pressed, setPressed] = useState(false);
  const [isTouchEnabled, setIsTouchEnabled] = useState(false);

  const handleTouchStart = () => {
    setIsTouchEnabled(true);
    setPressed(true);
    onClick();
  };

  const handleTouchEnd = () => {
    setPressed(false);
  };

  const handleMouseDown = () => {
    if (isTouchEnabled) return;
    setPressed(true);
    onClick();
  };

  const handleMouseUp = () => {
    if (isTouchEnabled) return;
    setPressed(false);
  };

  return (
    <Button
      deleteButton={deleteButton}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      pressed={pressed}>
      {children}
    </Button>
  );
};
