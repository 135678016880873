import { useState } from 'react';
import { Drawer } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { BiAddToQueue, BiListUl, BiArchive, BiMenu } from 'react-icons/bi';
import styled, { css } from 'styled-components';
import { Logo } from '../../logo/logo';

const Sidebar = styled.div`
  grid-template-area: sidebar;
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.newPrimary};
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: ${theme.breakpoints.desktop}) {
      height: 100%;
      overflow-y: auto;
      position: fixed;
    }
  `}
`;

const MainItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 1.5rem;
    justify-content: center;

    @media (min-width: ${theme.breakpoints.desktop}) {
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
    }
  `}
`;

const SubItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    @media (min-width: ${theme.breakpoints.desktop}) {
      display: block;
    }
  `}
`;

const SidebarButton = styled.div<{ current?: boolean }>`
  ${({ theme, current }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    color: ${current ? theme.colors.newPrimarySurface : theme.colors.body};
    background: ${current
      ? theme.colors.newPrimaryBody
      : theme.colors.newPrimarySurfaceMute};
    border-radius: 0.5rem;
    width: 100px;
    height: 100px;

    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 150px;
    }
  `}
`;

const SubItemButton = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.newPrimarySurfaceMute};
    border-radius: 0.5rem;
    border: 0;
    color: ${theme.colors.body};
    display: flex;
    justify-content: center;
    width: 100px;
    padding: 1rem 0;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 150px;
    }
  `}
`;

const SidebarItemLabel = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.label};
    text-transform: uppercase;
    font-weight: ${theme.typography.weights.bold};
    letter-spacing: ${theme.typography.spacing.label};
  `}
`;

const SubMenu = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-size: ${theme.typography.sizes.label};
    font-weight: ${theme.typography.weights.bold};
    letter-spacing: ${theme.typography.spacing.label};
    text-transform: uppercase;

    a {
      color: ${theme.colors.logo};
    }
  `}
`;

const SubMenuItem = styled.div`
  padding: .75rem 0;
`;

const LogoContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
`;

export const KioskSidebar = withRouter(({ location }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <Sidebar>
      <Wrapper>
        <MainItems>
          <Link to="/create">
            <SidebarButton current={location.pathname === '/create'}>
              <BiAddToQueue size="28"></BiAddToQueue>
              <SidebarItemLabel>Create</SidebarItemLabel>
            </SidebarButton>
          </Link>
          <Link to="/tickets">
            <SidebarButton current={location.pathname === '/tickets'}>
              <BiListUl size="28"></BiListUl>
              <SidebarItemLabel>Tickets</SidebarItemLabel>
            </SidebarButton>
          </Link>
          <Link to="/archive">
            <SidebarButton current={location.pathname === '/archive'}>
              <BiArchive size="28"></BiArchive>
              <SidebarItemLabel>Archive</SidebarItemLabel>
            </SidebarButton>
          </Link>
        </MainItems>
        <SubItems>
          <SubItemButton onClick={() => void showDrawer()}>
            <BiMenu size="28"></BiMenu>
          </SubItemButton>
        </SubItems>
        <Drawer
          placement="left"
          width={300}
          onClose={closeDrawer}
          visible={visible}
          closable={false}>
          <SubMenu>
            <LogoContainer>
              <Link to="/">
                <Logo color="primary" width="9rem"></Logo>
              </Link>
            </LogoContainer>
            <Link to="/dashboard">
              <SubMenuItem>Dashboard</SubMenuItem>
            </Link>
            <Link to="/logout">
              <SubMenuItem>Logout</SubMenuItem>
            </Link>
          </SubMenu>
        </Drawer>
      </Wrapper>
    </Sidebar>
  );
});
