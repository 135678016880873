import { TicketsState } from '../reducer';

export const followUp = (state: TicketsState, ticketId: string) => {
  // Deep clone first.
  const tickets = [...state.tickets].map(ticket => ({ ...ticket }));

  // Mutate the ticket in question.
  for (const ticket of tickets) {
    if (ticket._id === ticketId) {
      ticket.hasBeenFollowedUp = true;
    }
  }

  return {
    ...state,
    tickets,
  };
};
