import jwtDecode, { JwtPayload } from 'jwt-decode';
import { RouteComponentProps } from 'react-router-dom';

import { TOKEN_LOCAL_STORAGE_KEY } from '../constants/session_constants';

import { PARAM_KEY_STATE } from '../constants/url_constants';

interface AuthTokenPayload extends JwtPayload {
  username: string;
  isAdmin: boolean;
}

export function storeToken(token: string) {
  localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
}

export function storeTokenAndRedirect(
  token: string,
  history: RouteComponentProps['history'],
) {
  storeToken(token);
  const urlParams = new URLSearchParams(window.location.search);
  // Preserve the state that was passed in the redirect URL to ensure the user
  // ends up at the path where they originally were.
  const path = urlParams.get(PARAM_KEY_STATE) ?? '/';
  history.push(path);
}

export function checkForMissingOrExpiredTokenAndMaybeRedirect() {
  if (!hasToken() || isExpired(getToken()!)) {
    window.location.href = getUnauthenticatedRedirectUrl();
  }
}

export function isExpired(token: string): boolean {
  try {
    const decoded = jwtDecode<AuthTokenPayload>(token);

    // If no expiration is set, the token never expires.
    if (!decoded.exp) return false;

    const expirationTimestampMs = decoded.exp * 1000;
    return expirationTimestampMs - Date.now() < 0;
  } catch {
    // Couldn't decode token.
    return true;
  }
}

export function isAdmin(): boolean {
  const token = getToken();
  if (!token) return false;
  const decoded = jwtDecode<AuthTokenPayload>(token);

  return decoded.isAdmin ?? false;
}

export function getToken(): string | undefined {
  return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) ?? undefined;
}

export function hasToken(): boolean {
  return Boolean(getToken());
}

export function deleteToken() {
  return localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
}

export function hasActiveToken(): boolean {
  return hasToken() && !isExpired(getToken()!);
}

export function clearTokenAndRedirect() {
  localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
  checkForMissingOrExpiredTokenAndMaybeRedirect();
}

export function clearToken() {
  localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
}

export function getUnauthenticatedRedirectUrl(): string {
  const currentPath = window.location.pathname;
  if (currentPath) {
    return `/login?state=${currentPath}`;
  }
  return '/login';
}
