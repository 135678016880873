import styled from 'styled-components';
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from 'react-icons/fa';

import { CardDetails } from '../../../../declarations';

const { Text } = Typography;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 110px;
  padding: 1rem;
  width: 190px;
`;
const TopRow = styled.div`
  align-items: flex-start;
  display: flex;
`;
const Label = styled.div`
  color: #bbb;
  font-size: 0.6875rem;
  font-weight: 700;
  height: min-content;
  line-height: 0.6875rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`;
const NameContainer = styled.div`
  flex-grow: 1;
`;
const Name = styled.div`
  line-height: 1rem;
  margin-top: 0;
`;
const BottomRow = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  margin-top: 0.5rem;
`;
const Icon = styled.div`
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  line-height: 1.25rem;
`;
const CardNumber = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;
  line-height: 1rem;
`;
const Expiration = styled.div`
  line-height: 1rem;
`;
const AddNewCardRoot = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;
const AddNewCardIcon = styled.div`
  font-size: 1.25rem;
`;

export interface PaymentCardProps {
  card?: CardDetails;
  forNewCard?: boolean;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  card,
  forNewCard = false,
}) => {
  if (forNewCard) {
    return (
      <Root>
        <AddNewCardRoot>
          <Text type="secondary">Add New Card</Text>
          <AddNewCardIcon>
            <Text type="secondary">
              <PlusOutlined />
            </Text>
          </AddNewCardIcon>
        </AddNewCardRoot>
      </Root>
    );
  }

  if (!card) {
    throw new Error('Expected card to be provided if `forNewCard` = false');
  }

  return (
    <Root>
      <TopRow>
        <NameContainer>
          <Label>Name</Label>
          <Name>{card.name}</Name>
        </NameContainer>
        <Icon>
          {card.default && <CheckCircleOutlined />}
          {getIcon(card.brand)}
        </Icon>
      </TopRow>
      <BottomRow>
        <div>
          <Label>Last 4</Label>
          <CardNumber>
            <span>&middot;&middot;&middot;&middot;</span>
            <span>{card.last4}</span>
          </CardNumber>
        </div>
        <div>
          <Label>Exp</Label>
          <Expiration>
            {card.expMonth}/{card.expYear.toString().slice(-2)}
          </Expiration>
        </div>
      </BottomRow>
    </Root>
  );
};

function getIcon(brand: string): JSX.Element {
  // These brands come from the list under `card` for `PaymentMethod`:
  // https://stripe.com/docs/api/payment_methods/object.
  switch (brand) {
    case 'amex':
      return <FaCcAmex></FaCcAmex>;
    case 'diners':
      return <FaCcDinersClub></FaCcDinersClub>;
    case 'discover':
      return <FaCcDiscover></FaCcDiscover>;
    case 'jcb':
      return <FaCcJcb></FaCcJcb>;
    case 'mastercard':
      return <FaCcMastercard></FaCcMastercard>;
    case 'visa':
      return <FaCcVisa></FaCcVisa>;
    case 'unionpay':
    case 'unknown':
    default:
      return <FaCreditCard></FaCreditCard>;
  }
}
