import styled, { css } from 'styled-components';

const CARET_SIZE = '7px';

const Root = styled.div`
  ${({ theme }) => css`
    border-radius: 0.5rem;
    background: ${theme.colors.speechBubble};
    padding: .5rem 1rem;
    color: ${theme.colors.surface};
    position: relative;
    margin-bottom: ${CARET_SIZE};
  `}
`;

const Caret = styled.div`
  ${({ theme }) => css`
    width: 0; 
    height: 0; 
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: ${CARET_SIZE} solid ${theme.colors.speechBubble};
    position: absolute;
    bottom: 10px;
    right: -${CARET_SIZE};
  `}
`;

export const MessageBubble: React.FC = ({ children }) => {
  return <Root>
    {children}
    <Caret></Caret>
  </Root>;
};
