import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Center } from '../../center/center';

const Root = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const InitiatedPasscodeReset: React.FC = () => {
  return (
    <Root>
      <Center>
        <Typography.Title level={4}>Passcode Reset</Typography.Title>
      </Center>
      <Typography.Paragraph>
        We just sent an admin passcode reset link to the email for the
        account.
      </Typography.Paragraph>
      <Center>
        <Link to="/dashboard">Dashboard</Link>
      </Center>
    </Root>
  );
};
