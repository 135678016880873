import { MerchantApiObject } from '../../../declarations';
import { MerchantState } from '../reducer';
import deepClone from 'deep-clone';

export function setMerchant(
  state: MerchantState,
  merchant: MerchantApiObject,
): MerchantState {
  return {
    ...state,
    merchant: deepClone(merchant),
  };
}
