import styled, { css } from 'styled-components';

interface LinkButtonProps {
  underline?: boolean;
}

export const LinkButton = styled.button<LinkButtonProps>`
  ${({ underline = false }) => css`
    margin: 0;
    padding: .25rem;
    display: inline-block;
    border: none;
    background: transparent;

    ${underline && css`
      text-decoration: underline;
    `}
  `}
`;