import { MarketPartner } from '../declarations';

export class RenderablePartner {
  static RODEO = new RenderablePartner(MarketPartner.RODEO, 'Rodeo');

  private constructor(readonly name: MarketPartner, readonly label: string) {}
}

export function isValidPartner(partnerName: string): boolean {
  return Boolean(getRenderablePartner(partnerName));
}

export function getRenderablePartner(
  partnerName: string,
): RenderablePartner | undefined {
  switch (partnerName) {
    case RenderablePartner.RODEO.name:
      return RenderablePartner.RODEO;
  }
}
