import { Button, Form, Input, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiClient } from '../../api_client/api_client';
import { PASSWORD_RESET_TOKEN_KEY } from '../../constants/url_constants';
import { useQuery } from '../../hooks/query';
import { isExpired } from '../../utils/auth_util';
import { AuthPage } from '../auth_page/auth_page';
import { Center } from '../center/center';
import { Loading } from '../dashboard/loading/loading';
import { InputContainer } from '../input_container/input_container';
import { InputLabel } from '../input_label/input_label';
import { Container } from './container';

const apiClient = new ApiClient();

export const ResetAdminPasscode: React.FC = () => {
  const [passcode, setPasscode] = useState('');
  const [confirmPasscode, setConfirmPasscode] = useState('');
  const query = useQuery();
  const token = query.get(PASSWORD_RESET_TOKEN_KEY);
  const [checking, setChecking] = useState(true);
  const [isTokenValid, setIsValidToken] = useState(false);
  const [complete, setComplete] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!token) {
      setChecking(false);
      return;
    }

    if (isExpired(token)) {
      setChecking(false);
      setIsValidToken(false);
      return;
    }

    (async () => {
      const isValid = await apiClient.verifyAdminPasscodeResetToken(token);
      setChecking(false);
      setIsValidToken(isValid);
    })();
  }, [token]);

  const canSubmit = useCallback(() => {
    if (passcode.length === 0 || confirmPasscode.length === 0) return false;
    return passcode === confirmPasscode;
  }, [passcode, confirmPasscode]);

  const handleSubmit = useCallback(async () => {
    if (!canSubmit() || !token) return;
    setError('');
    setResetting(true);
    const success = await apiClient.resetAdminPasscode({
      newAdminPasscode: passcode,
      token,
    });
    setResetting(false);
    if (success) {
      setComplete(true);
    } else {
      setError('Unable to reset admin passcode. Please try again.');
    }
  }, [passcode, confirmPasscode, token]);

  if (checking) {
    return (
      <Container>
        <Loading></Loading>
      </Container>
    );
  }

  if (!token || !isTokenValid) {
    return (
      <Container>
        <Typography.Paragraph>
          This URL is invalid. Go to the <Link to="/dashboard">dashboard</Link>.
        </Typography.Paragraph>
      </Container>
    );
  }

  if (complete) {
    return (
      <Container>
        <Typography.Paragraph>
          Successfully reset the admin passcode.
        </Typography.Paragraph>
        <Center>
          <Link to="/dashboard">Dashboard</Link>
        </Center>
      </Container>
    );
  }

  return (
    <Container>
      <Form onFinish={handleSubmit}>
        <InputContainer>
          <InputLabel htmlFor="passcode">New Passcode:</InputLabel>
          <Input
            type="password"
            id="passcode"
            value={passcode}
            onChange={e => setPasscode(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="confirm-passcode">
            Confirm New Passcode:
          </InputLabel>
          <Input
            type="password"
            id="confirm-passcode"
            value={confirmPasscode}
            onChange={e => setConfirmPasscode(e.target.value)}></Input>
        </InputContainer>
        <Button
          loading={resetting}
          htmlType="submit"
          disabled={!canSubmit()}
          type="primary"
          size="large">
          Reset
        </Button>
        {error && <Typography.Paragraph style={{marginTop: '1rem'}} type="danger">{error}</Typography.Paragraph>}
      </Form>
    </Container>
  );
};
