
import { useReducer, createContext } from 'react';
import { LocationAction } from './actions';
import { reducer, LocationState } from './reducer';
import { initialState } from './initial_state';

export interface LocationDispatch {
  (action: LocationAction): void;
}

export const LocationContext = createContext([
  initialState,
  () => null,
] as [LocationState, LocationDispatch]);

export const LocationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LocationContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </LocationContext.Provider>
  )
};