import { MouseEvent, useContext } from 'react';
import styled, { css } from 'styled-components';
import {
  BiChevronDown,
} from 'react-icons/bi';
import { formatPhoneNumber } from 'react-phone-number-input';

import { Button, ButtonStyle } from '../../../button/button';
import { RotateIf } from '../../../rotate_if/rotate_if';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ToastContent } from '../../../toast_content/toast_content';
import { NotifyButton } from '../notify_button/notify_button';
import {
  TicketApiObject,
  TicketState,
  UpdateTicketResult,
} from '../../../../declarations';
import {
  markTicketAsFollowedUp,
  markTicketAsPendingPickup,
} from '../../../../contexts/ticket/async_actions';
import { TicketContext } from '../../../../contexts/ticket/provider';
import { KioskLabel } from '../../kiosk_label/kiosk_label';
import { TicketDisplay } from '../../ticket_display/ticket_display';
import { AlreadySent } from '../already_sent/already_sent';
import { DeliveryStatus } from '../delivery_status/delivery_status';

const Root = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: grid;
    grid-template-areas:
      'ticket-container        notify-button-container'
      'notes-container         notes-container'
      'expand-button-container expand-button-container';
    gap: 1rem;
    padding: 1rem;
    width: 100%;

    @media (min-width: ${theme.breakpoints.desktop}) {
      padding: 1rem 2rem;
      gap: 2rem;
      grid-template-areas: 'ticket-container notes-container notify-button-container expand-button-container';
      grid-template-columns: max-content 1fr max-content max-content;
    }
  `}
`;

const TicketContainer = styled.div`
  grid-area: ticket-container;
`;

const NotesContainer = styled.div`
  grid-area: notes-container;
  display: flex;
  flex-direction: column;
`;

const NotifyButtonContainer = styled.div`
  grid-area: notify-button-container;
  display: flex;
  justify-content: flex-end;
`;

const ExpandButtonContainer = styled.div`
  grid-area: expand-button-container;
  display: flex;
  justify-content: center;
`;

const Notes = styled.p<{ truncate: boolean }>`
  ${({ truncate }) => css`
    margin: 0;
    ${truncate &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  `}
`;

const PhoneNumberAndStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: .25rem 1.5rem;
  flex-wrap: wrap;
`;

const PhoneNumberDisplay = styled.h4`
  margin: 0;
`;

export interface TicketContentProps {
  data: TicketApiObject;
  selected: boolean;
  expanded: boolean;
  onExpandToggle: () => void;
}

export const TicketContent: React.FC<TicketContentProps> = ({
  data,
  selected,
  expanded,
  onExpandToggle,
}) => {
  const [inFlight, setInFlight] = useState(false);
  const [, ticketDispatch] = useContext(TicketContext);

  const isOpen = data.state === TicketState.OPEN;
  const isPendingPickup = data.state === TicketState.PENDING_PICKUP;

  const handleButtonClick = async (e: MouseEvent) => {
    // We need to stop propagation so the parent container doesn't think
    // the selected order is being toggled.
    e.stopPropagation();

    if (inFlight) return;
    
    setInFlight(true);
    if (isOpen) {
      const result = await markTicketAsPendingPickup(ticketDispatch, data._id);
      setInFlight(false);
      if (result === UpdateTicketResult.SUCCESS) {
        toast(() => <ToastContent>Notification sent</ToastContent>);
      } else {
        toast(() => (
          <ToastContent error>Unable to send notification</ToastContent>
        ));
      }
    } else if (isPendingPickup) {
      const result = await markTicketAsFollowedUp(ticketDispatch, data._id);
      setInFlight(false);
      if (result === UpdateTicketResult.SUCCESS) {
        toast(() => <ToastContent>Notification sent</ToastContent>);
      } else {
        toast(() => (
          <ToastContent error>Unable to send notification</ToastContent>
        ));
      }
    }
  };
  const handleExpansionButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    onExpandToggle();
  };
  return (
    <Root>
      <TicketContainer>
        <TicketDisplay text={data.ticket}></TicketDisplay>
      </TicketContainer>
      <NotesContainer>
        <PhoneNumberAndStatusContainer>
          <PhoneNumberDisplay>
            {formatPhoneNumber(data.phoneNumber)}
          </PhoneNumberDisplay>
          <DeliveryStatus data={data}></DeliveryStatus>
        </PhoneNumberAndStatusContainer>
        {data.notes && (
          <div>
            <KioskLabel style={{marginTop: '.75rem'}}>Notes:</KioskLabel>
            <Notes truncate={!selected}>{data.notes}</Notes>
          </div>
        )}
      </NotesContainer>
      <NotifyButtonContainer>
        {selected &&
          ((isOpen && !data.hasBeenNotified) ||
            (isPendingPickup && !data.hasBeenFollowedUp)) && (
            <NotifyButton
              inFlight={inFlight}
              data={data}
              onClick={handleButtonClick}></NotifyButton>
          )}
        {selected &&
          data.state === TicketState.OPEN &&
          data.hasBeenNotified && (
            <AlreadySent>Already sent notification.</AlreadySent>
          )}
        {selected &&
          data.state === TicketState.PENDING_PICKUP &&
          data.hasBeenFollowedUp && (
            <AlreadySent>Already sent follow-up.</AlreadySent>
          )}
      </NotifyButtonContainer>
      <ExpandButtonContainer>
        {selected && (
          <Button
            buttonStyle={ButtonStyle.TERTIARY}
            onClick={handleExpansionButtonClick}>
            <RotateIf condition={expanded}>
              <BiChevronDown size={'2.3125rem'}></BiChevronDown>
            </RotateIf>
          </Button>
        )}
      </ExpandButtonContainer>
    </Root>
  );
};
