import { useContext } from 'react';
import styled from 'styled-components';
import { LocationContext } from '../../../contexts/location/provider';
import { TicketLiveDuration } from '../../../contexts/ticket/actions';
import { setLiveDurationAndRefetchTickets } from '../../../contexts/ticket/async_actions';
import { TicketContext } from '../../../contexts/ticket/provider';
import { KioskButton, KioskButtonStyle } from '../kiosk_button/kiosk_button';
import { KioskLabel } from '../kiosk_label/kiosk_label';

const RENDERABLE_DURATIONS = [
  {
    duration: TicketLiveDuration.ONE_HOUR,
    label: '1 Hour',
  },
  {
    duration: TicketLiveDuration.EIGHT_HOURS,
    label: '8 Hours',
  },
  {
    duration: TicketLiveDuration.ONE_DAY,
    label: '1 Day',
  },
  {
    duration: TicketLiveDuration.SEVEN_DAYS,
    label: '7 Days',
  },
  {
    duration: TicketLiveDuration.THIRTY_DAYS,
    label: '30 Days',
  },
];

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const LiveDurationButtons: React.FC = () => {
  const [ticketsState, ticketDispatch] = useContext(TicketContext);
  const [locationState] = useContext(LocationContext);
  const onDurationClick = (duration: TicketLiveDuration) => {
    if (duration === ticketsState.liveDuration) return;
    setLiveDurationAndRefetchTickets(ticketDispatch, locationState.currentLocationId, duration);
  };

  return (
    <Root>
      <KioskLabel>Show tickets from the last:</KioskLabel>
      <Buttons>
        {RENDERABLE_DURATIONS.map(({ duration, label }) => {
          return <KioskButton
            key={`live-duration-buttons-${duration}`}
            buttonStyle={ticketsState.liveDuration === duration ? KioskButtonStyle.FILLED : KioskButtonStyle.BORDERED}
            onClick={() => onDurationClick(duration)}>
            {label}
          </KioskButton>
        })}
      </Buttons>
    </Root>
  );
};
