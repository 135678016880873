import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { clearToken } from "../../utils/auth_util";
import { clearLocation } from "../../utils/session_util";
import { LocationContext } from "../../contexts/location/provider";
import { LocationActionType } from "../../contexts/location/actions";

export const Logout: React.FC = () => {
  const [ , dispatch ] = useContext(LocationContext);

  clearToken();
  clearLocation();
  dispatch({ type: LocationActionType.RESET_LOCATIONS })

  return <Redirect to="/login"></Redirect>;
};
