import { Route } from 'react-router-dom';
import { PartnerSignup } from './partner_signup/partner_signup';
import { SignupForm } from './signup_form/signup_form';

export const Signup: React.FC = () => {
  return (
    <>
      <Route exact path="/signup">
        <SignupForm></SignupForm>
      </Route>
      <Route path="/signup/partner/:partnerName">
        <PartnerSignup></PartnerSignup>
      </Route>
    </>
  );
};
