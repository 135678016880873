import { Card } from 'antd';
import styled, { css } from 'styled-components';
import { Logo } from '../logo/logo';

const AuthBackground = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100vw;
    gap: 2rem;
    padding-top: 4rem;
    background: rgb(13, 78, 131);
    background: linear-gradient(
      32deg,
      rgba(13, 78, 131, 1) 0%,
      rgba(23, 102, 166, 1) 50%,
      rgba(95, 152, 199, 1) 100%
    );

    @media (min-width: ${theme.breakpoints.desktop}) {
      justify-content: center;
      gap: 3rem;
      padding-bottom: 4rem;
    }
  `}
`;

export const AuthPage: React.FC = ({ children }) => {
  return (
    <AuthBackground>
      <Logo color="surface"></Logo>
      <Card style={{ maxWidth: '32rem', width: '100%' }}>{children}</Card>
    </AuthBackground>
  );
};
