import { Card, Collapse } from 'antd';

import styled, { css } from 'styled-components';
import { PageContainer } from '../page_container/page_container';
import Title from 'antd/lib/typography/Title';
import { MerchantModel } from '../../../merchant_service/merchant_model';
import { useEffect, useState } from 'react';
import { LocationApiObject, MessageApiObject } from '../../../declarations';
import { VariableSubstitutions } from './varaible_substitutions/variable_substitutions';
import { ApiClient } from '../../../api_client/api_client';
import { MessagesForLocation } from './messages_for_location/messages_for_location';

const apiClient = new ApiClient();

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: ${theme.breakpoints.desktopWide}) {
      grid-template-columns: 3fr 2fr;
      gap: 2.5rem;
    }
  `}
`;

const LocationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export interface MessagesPageProps {
  merchantModel: MerchantModel;
}

export const MessagesPage: React.FC<MessagesPageProps> = ({
  merchantModel,
}) => {
  const [locationsWithMessageGroup, setLocationsWithMessageGroup] = useState<Array<{
    location: LocationApiObject;
    messages: MessageApiObject[];
  }>>();
  const username = merchantModel.getUsername();

  useEffect(() => {
    (async () => {
      const data = await apiClient.fetchAllMessagesSplitByLocation();
      if (data === null) {
        return;
      }
      setLocationsWithMessageGroup(data.locations);
    })();
  }, [
    // We check merchant username in case it changed (logged out / logged in)
    username
  ]);

  return (
    <PageContainer merchantModel={merchantModel}>
      <Title>Configure Messages per Location</Title>
      <Grid>
        <LocationGroupContainer>
          {locationsWithMessageGroup?.map(({ location, messages }) => (
            <Collapse key={`messages-for-location-${location._id}`}>
              <Collapse.Panel
                key={`messages-for-location-panel-${location._id}`}
                header={<Title level={5} style={{ margin: 0 }}>Location: {location.name}</Title>}
              >
                <MessagesForLocation
                  location={location}
                  messages={messages}
                />
              </Collapse.Panel>
            </Collapse>
          ))}
        </LocationGroupContainer>
        <Card style={{ width: '100%', maxWidth: '35rem' }}>
          <VariableSubstitutions></VariableSubstitutions>
        </Card>
      </Grid>
    </PageContainer>
  );
};
