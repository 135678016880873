import styled from 'styled-components';
import { ArchivedTicketActions } from '../archived_ticket_actions/archived_ticket_actions';
import { LiveTicketActions } from '../live_ticket_actions/live_ticket_actions';
import { StopClickPropagation } from '../../../stop_click_propagation/stop_click_propagation';
import { TicketApiObject, TicketState } from '../../../../declarations';

const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 0 2rem 0;
`;

interface OrderExpansionProps {
  data: TicketApiObject;
}

export const OrderExpansion: React.FC<OrderExpansionProps> = ({
  data
}) => {
  const isArchived = data.state === TicketState.ARCHIVED;

  return (
    <Root>
      <StopClickPropagation>
        {isArchived && (
          <ArchivedTicketActions data={data}></ArchivedTicketActions>
        )}
        {!isArchived && (
          <LiveTicketActions data={data}></LiveTicketActions>
        )}
      </StopClickPropagation>
    </Root>
  );
};
