import { Alert, Button, notification, Typography } from 'antd';
import React, { useState } from 'react';
import { StripeSubscriptionStatus } from '../../../declarations';
import { MerchantModel } from '../../../merchant_service/merchant_model';

const MSG_SUBSCRIPTION_INACTIVE = 'Your subscription is currently inactive.';
const MSG_SUBSCRIPTION_WILL_CANCEL =
  'Your subscription will cancel at the end of this current billing cycle';

const { Text } = Typography;

export interface CancelNoticeProps {
  merchantModel: MerchantModel;
}

export const CancelNotice: React.FC<CancelNoticeProps> = ({
  merchantModel,
}) => {
  const [inFlight, setInFlight] = useState(false);
  const [error, setError] = useState('');
  if (!merchantModel.isCanceledOrWillCancelSubscription()) {
    return <></>;
  }

  const handleReactivateClick = async () => {
    setInFlight(true);
    const success = await merchantModel.reactivateSubscription();
    if (success) {
      notification.success({
        message: 'Your subscription has been reactivated',
      });
    } else {
      setInFlight(false);
      setError(
        'There was an error cancelling the subscription. ' +
          'Please refresh and try again.',
      );
    }
  };

  const message =
    merchantModel.getSubscriptionStatus() === StripeSubscriptionStatus.CANCELED
      ? MSG_SUBSCRIPTION_INACTIVE
      : MSG_SUBSCRIPTION_WILL_CANCEL;
  return (
    <div>
      <Alert
        message="Cancellation Notice"
        description={
          <>
            {error && <Text type="danger">{error}</Text>}
            {!error && <Text>{message}</Text>}
          </>
        }
        type="error"
        action={
          <Button loading={inFlight} onClick={handleReactivateClick}>
            Reactivate
          </Button>
        }
      />
    </div>
  );
};
