import { Redirect, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import assert from 'assert-ts';
import styled, { css } from 'styled-components';
import { useContext, useEffect, useState } from 'react';

import { DashboardNavigation } from './dashboard_navigation/dashboard_navigation';
import { AdminProtected } from '../admin_protected/admin_protected';
import { ExportPage } from './export_page/export_page';
import { MessagesPage } from './messages_page/messages_page';
import { LocationsPage } from './locations_page/locations_page';
import { AccountPage } from './account_page/account_page';
import { merchantService } from '../../merchant_service/merchant_service';
import { MerchantModel } from '../../merchant_service/merchant_model';
import { Loading } from './loading/loading';
import { CancelSubscription } from './cancel_subscription/cancel_subscription';
import { MerchantContext } from '../../contexts/merchant/provider';
import { MerchantActionType } from '../../contexts/merchant/actions';
import { AffiliatePage } from './affiliate_page/affiliate_page';

const stripePromise = loadStripe(
  assert(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
);

const DashboardRoot = styled.div`
  ${({ theme }) => css`
    display: grid;
    width: 100%;
    min-height: 100%;
    grid-template-areas:
      'navigation'
      'content';

    @media (min-width: ${theme.breakpoints.desktop}) {
      grid-template-columns: min-content 1fr;
      grid-template-areas: 'navigation-filler content';
    }
  `}
`;

const NavigationGridArea = styled.div`
  ${({ theme }) => css`
    grid-area: navigation;
    background: ${theme.colors.body};
    color: ${theme.colors.surface};

    @media (min-width: ${theme.breakpoints.desktop}) {
      height: 100%;
      width: 18rem;
      position: fixed;
      overflow-y: auto;
    }
  `}
`;

const NavigationFillter = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.desktop}) {
      width: 18rem;
      grid-area: navigation-filler;
    }
  `}
`;

const ContentGridArea = styled.div`
  ${({ theme }) => css`
    grid-area: content;

    @media (min-width: ${theme.breakpoints.desktop}) {
      height: 100%;
      overflow: auto;
    }
  `}
`;

const DashboardContent: React.FC = () => {
  const [merchantModel, setMerchantModel] = useState<
    MerchantModel | undefined
  >();
  const [, merchantDispatch] = useContext(MerchantContext);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Refactor this, no need to get this from the service.
        // The code below should go inside of an async action after removing
        // MerchantService. `force` forces a re-fetch of the data.
        const merchantModel = await merchantService.getMerchantModel(
          /* force= */ true,
        );
        setMerchantModel(merchantModel);
        const merchantApiObject = merchantService.getMerchantApiObject();
        if (merchantApiObject) {
          merchantDispatch({
            type: MerchantActionType.SET_MERCHANT,
            merchant: merchantApiObject,
          });
        }
      } catch (err) {
        console.log('dashboard err = ', err);
      }
    })();
  }, [merchantDispatch]);

  return (
    <Elements stripe={stripePromise}>
      <DashboardRoot>
        <NavigationFillter></NavigationFillter>
        <NavigationGridArea>
          <DashboardNavigation></DashboardNavigation>
        </NavigationGridArea>
        <ContentGridArea>
          {!merchantModel ? (
            <Loading></Loading>
          ) : (
            <>
              <Route exact path="/dashboard">
                <Redirect to="/dashboard/messages"></Redirect>
              </Route>
              <Route exact path="/dashboard/locations">
                <LocationsPage merchantModel={merchantModel}></LocationsPage>
              </Route>
              <Route exact path="/dashboard/messages">
                <MessagesPage merchantModel={merchantModel}></MessagesPage>
              </Route>
              <Route path="/dashboard/export">
                <ExportPage merchantModel={merchantModel}></ExportPage>
              </Route>
              <Route path="/dashboard/account">
                <AccountPage merchantModel={merchantModel}></AccountPage>
              </Route>
              <Route path="/dashboard/affiliate">
                <AffiliatePage merchantModel={merchantModel}></AffiliatePage>
              </Route>
              <Route path="/dashboard/cancel">
                <CancelSubscription
                  merchantModel={merchantModel}></CancelSubscription>
              </Route>
            </>
          )}
        </ContentGridArea>
      </DashboardRoot>
    </Elements>
  );
};

export const Dashboard: React.FC = () => {
  // We need to wrap the `DashboardContent` because the initialization logic
  // inside `DashboardContent` shouldn't be run within this component.
  return (
    <AdminProtected>
      <DashboardContent></DashboardContent>
    </AdminProtected>
  );
};
