import styled, { css } from 'styled-components';
import PasswordValidator from 'password-validator';
import { ValidationItem } from './validation_item';

const Root = styled.div`
  ${({ theme }) => css`
    margin: 1rem 0;
    border-left: 4px solid ${theme.colors.hairline};
    padding-left: 1rem;
  `}
`;

export const PASSWORD_SCHEMA = new PasswordValidator()
  .is()
  .min(8)
  .is()
  .max(64)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1);

export const PasswordValidation: React.FC<{ password: string }> = ({
  password,
}) => {
  // possible values are 'min', 'max', 'uppercase', 'lowercase', 'digits', 'spaces'
  const validationErrors = PASSWORD_SCHEMA.validate(password, { list: true });

  return (
    <Root>
      <ValidationItem
        isValid={!validationErrors.includes('min')}
        message="At least 8 characters"
      />
      <ValidationItem
        isValid={!validationErrors.includes('max')}
        message="Less than 65 characters"
      />
      <ValidationItem
        isValid={!validationErrors.includes('uppercase')}
        message="At least 1 uppercase letter"
      />
      <ValidationItem
        isValid={!validationErrors.includes('lowercase')}
        message="At least 1 lowercase letter"
      />
      <ValidationItem
        isValid={!validationErrors.includes('digits')}
        message="At least 1 number"
      />
    </Root>
  );
};
