import { useReducer, createContext } from 'react';
import { TicketAction } from './actions';
import { reducer, initialState, TicketsState } from './reducer';

export interface TicketDispatch {
  (action: TicketAction): void;
}

export const TicketContext = createContext([
  initialState,
  () => null,
] as [TicketsState, TicketDispatch]);

export const TicketProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TicketContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </TicketContext.Provider>
  )
};