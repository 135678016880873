import styled from 'styled-components';
import {
  Button,
  Typography,
  Radio,
  notification,
  RadioChangeEvent,
  Empty,
} from 'antd';
import { useState, useEffect } from 'react';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import { ApiClient } from '../../../api_client/api_client';
import { CardDetails } from '../../../declarations';
import { AddPaymentMethod } from './add_payment_method/add_payment_method';
import { PaymentCard } from './payment_card/payment_card';
import { Loading } from '../loading/loading';

const { Title, Text } = Typography;
const apiClient = new ApiClient();

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

const ErrorContainer = styled.div`
  margin-top: 1rem;
`;

export const PaymentMethods: React.FC = () => {
  const [cards, setCards] = useState<CardDetails[]>([]);
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const [updatingDefaultCard, setUpdatingDefaultCard] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fetching, setFetching] = useState(false);

  const fetchCards = async () => {
    setFetching(true);
    const result = await apiClient.fetchStripeCustomerPaymentMethods();
    if (result === null) {
      setError('Error fetching payment methods. Please refresh.');
    } else {
      setCards(result);
    }
    setFetching(false);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    if (!touched) {
      setTouched(true);
    }
    setSelectedPaymentMethodId(e.target.value);
  };

  const handleSave = async () => {
    setUpdatingDefaultCard(true);
    const success = await apiClient.updateStripeCustomerPaymentMethod(
      selectedPaymentMethodId,
    );
    if (success) {
      await fetchCards();
      notification.success({
        message: 'Successfully updated default payment method',
      });
    } else {
      setError('Error updating default payment method. Please try again.');
    }
    setUpdatingDefaultCard(false);
  };

  const handleDelete = async () => {
    setDeleting(true);
    const success = await apiClient.deletePaymentMethod(
      selectedPaymentMethodId,
    );
    if (success) {
      await fetchCards();
      notification.success({
        message: 'Successfully deleted payment method',
      });
    } else {
      setError('Error deleting payment method. Please try again.');
    }
    setDeleting(false);
  };

  const handleAddPaymentMethodSuccess = async () => {
    await fetchCards();
    // No need to show success notification since the underyling <CardInput>
    // handles that for us.
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div>
      <Title level={2}>Payment Methods</Title>
      {fetching && <Loading></Loading>}
      {!fetching && cards.length > 0 && (
        <div>
          <Radio.Group
            onChange={handleRadioChange}
            defaultValue={cards.find(card => card.default)?.id ?? ''}>
            <Cards>
              {cards.map(card => (
                <Radio.Button
                  value={card.id}
                  checked={selectedPaymentMethodId === card.id}
                  key={`payment-card-${card.id}`}
                  disabled={card.default}
                  style={{ height: 'min-content', padding: '0' }}>
                  <PaymentCard card={card}></PaymentCard>
                </Radio.Button>
              ))}
              <AddPaymentMethod onSuccess={handleAddPaymentMethodSuccess}>
                <Button
                  style={{
                    height: 'min-content',
                    borderRadius: '0',
                    padding: '0',
                  }}>
                  <PaymentCard forNewCard={true}></PaymentCard>
                </Button>
              </AddPaymentMethod>
            </Cards>
          </Radio.Group>
          {touched && (
            <Buttons>
              <Button
                disabled={updatingDefaultCard}
                loading={updatingDefaultCard}
                type="primary"
                icon={<CheckOutlined />}
                onClick={handleSave}>
                Set as Default
              </Button>
              <Button
                loading={deleting}
                disabled={deleting}
                icon={<DeleteOutlined />}
                danger
                onClick={handleDelete}>
                Delete
              </Button>
            </Buttons>
          )}
          {error && (
            <ErrorContainer>
              <Text type="danger">{error}</Text>
            </ErrorContainer>
          )}
        </div>
      )}
      {!fetching && cards.length === 0 && (
        <Empty
          description="No saved payment methods"
          image={Empty.PRESENTED_IMAGE_SIMPLE}>
          <AddPaymentMethod onSuccess={handleAddPaymentMethodSuccess}>
            <Button>Add a payment method</Button>
          </AddPaymentMethod>
        </Empty>
      )}
    </div>
  );
};
