import { Card, Typography, Button } from 'antd';
import { Invoice } from '../../../../declarations';
import {
  formatDateTimestampSec,
  formatCurrency,
} from '../../../../utils/string_util';

const { Paragraph, Text } = Typography;

export interface InvoiceProps {
  invoice: Invoice;
}

export const SingleInvoice: React.FC<InvoiceProps> = ({ invoice }) => {
  return (
    <Card
      style={{ width: '50%' }}
      title={`Usage Period: ${formatDateTimestampSec(
        invoice.periodStart,
      )} to ${formatDateTimestampSec(invoice.periodEnd)}`}>
      <Paragraph>
        Messages used:&nbsp;
        <Text strong>{invoice.totalUsage}</Text>
      </Paragraph>
      <Paragraph>
        Total:&nbsp;
        <Text strong>
          {formatCurrency(invoice.total / 100, invoice.currency)}
        </Text>
      </Paragraph>
      <Button href={invoice.hosted_invoice_url}>Pay now</Button>
    </Card>
  );
};
