import { MerchantAction, MerchantActionType } from './actions';
import { MerchantApiObject, OnboardStatus } from '../../declarations';
import { setMerchant } from './reducers';
import { TICKET_LIVE_DURATION_KEY } from '../../constants/session_constants';

export interface MerchantState {
  merchant: MerchantApiObject | null;
  onboardStatus: OnboardStatus;
}

export const reducer = (state: MerchantState, action: MerchantAction) => {
  switch (action.type) {
    case MerchantActionType.SET_MERCHANT:
      return setMerchant(state, action.merchant);
    case MerchantActionType.SET_ONBOARD_STATUS:
      return {
        ...state,
        onboardStatus: action.status,
      };
    default:
      return state;
  }
};

export const initialState: MerchantState = {
  merchant: null,
  onboardStatus: OnboardStatus.NONE,
};
