import { useContext } from 'react';
import { LocationContext } from '../../../contexts/location/provider';
import { TicketContext } from '../../../contexts/ticket/provider';
import { TicketState } from '../../../declarations';
import { KioskHeader } from '../kiosk_header/kiosk_header';
import { KioskLabel } from '../kiosk_label/kiosk_label';
import { FetchSettings } from '../fetch_settings/fetch_settings';
import { Ticket } from '../ticket/ticket';

export const TicketArchive: React.FC = () => {
  const [locationState] = useContext(LocationContext);
  const [ticketsState] = useContext(TicketContext);

  return (
    <div>
      <KioskHeader heading={'Archived Tickets'}></KioskHeader>
      <FetchSettings></FetchSettings>
      <KioskLabel size={20} margin="1rem 0">
        Archived
      </KioskLabel>
      {locationState.currentLocationId && (
        <>
          {ticketsState.tickets
            .filter(data => data.state === TicketState.ARCHIVED)
            .map(data => (
              <Ticket data={data} key={`ticket-list-${data._id}`}></Ticket>
            ))}
        </>
      )}
    </div>
  );
};
