import { TicketAction, TicketActionType, TicketLiveDuration } from './actions';
import { TicketApiObject } from '../../declarations';
import { updateTicketState, followUp, markIssue } from './reducers';
import { TICKET_LIVE_DURATION_KEY } from '../../constants/session_constants';

export interface TicketsState {
  tickets: TicketApiObject[];
  selectedTicketId: string;
  liveDuration: TicketLiveDuration;
}

export const reducer = (state: TicketsState, action: TicketAction) => {
  switch (action.type) {
    case TicketActionType.SET_TICKETS:
      return {
        ...state,
        tickets: [...action.tickets],
      };
    case TicketActionType.UPDATE_TICKET_STATE:
      return updateTicketState(state, action.ticketId, action.ticketState);
    case TicketActionType.FOLLOW_UP:
      return followUp(state, action.ticketId);
    case TicketActionType.MARK_ISSUE:
      return markIssue(state, action.ticketId);
    case TicketActionType.SET_LIVE_DURATION:
      localStorage.setItem(TICKET_LIVE_DURATION_KEY, String(action.duration));
      return {
        ...state,
        liveDuration: action.duration,
      };
    case TicketActionType.SET_SELECTED_TICKET:
      return {
        ...state,
        selectedTicketId: action.ticketId,
      };
    default:
      return state;
  }
};

function getLiveDurationFromLocalStorage(): TicketLiveDuration {
  const valueFromLocalStorage = localStorage.getItem(TICKET_LIVE_DURATION_KEY);
  if (valueFromLocalStorage === null) return TicketLiveDuration.ONE_HOUR;
  const numberValue = Number(valueFromLocalStorage);
  if (isNaN(numberValue)) return TicketLiveDuration.ONE_HOUR;
  return Math.max(numberValue, TicketLiveDuration.ONE_HOUR);
}

export const initialState: TicketsState = {
  tickets: [] as TicketApiObject[],
  selectedTicketId: '',
  liveDuration: getLiveDurationFromLocalStorage(),
};
