/**
 * A generic class for managing a pub/sub for a mapping of event names and
 * callbacks.
 */
export class EventBus<T> {
  private readonly eventToHandlerCallbacksMap = new Map<T, Set<() => void>>();

  on(event: T, callback: () => void) {
    const callbacks = this.getOrInitCallbacksForEvent(event);
    callbacks.add(callback);
  }

  off(event: T, callback: () => void) {
    const callbacks = this.getOrInitCallbacksForEvent(event);
    callbacks.delete(callback);
  }

  clear(event: T) {
    this.eventToHandlerCallbacksMap.delete(event);
  }

  trigger(event: T) {
    const callbacks = this.getOrInitCallbacksForEvent(event);
    callbacks.forEach(callback => void callback());
  }

  private getOrInitCallbacksForEvent(event: T): Set<() => void> {
    this.eventToHandlerCallbacksMap.set(
      event,
      this.eventToHandlerCallbacksMap.get(event) ?? new Set(),
    );
    return this.eventToHandlerCallbacksMap.get(event)!;
  }
}
