import { Card, Typography } from 'antd';
import { Invoice } from '../../../../declarations';
import {
  formatDateTimestampSec,
  formatCurrency,
} from '../../../../utils/string_util';

const { Paragraph, Text } = Typography;

export interface UpcomingInvoiceProps {
  invoice: Invoice;
}

export const UpcomingInvoice: React.FC<UpcomingInvoiceProps> = ({
  invoice,
}) => {
  return (
    <Card
      style={{ width: '50%' }}
      title={`Usage Period: ${formatDateTimestampSec(
        invoice.periodStart,
      )} to ${formatDateTimestampSec(invoice.periodEnd)}`}>
      <Paragraph>
        Messages used:&nbsp;
        <Text strong>{invoice.totalUsage}</Text>
      </Paragraph>
      <Paragraph>
        Amount due at end of current billing cycle:&nbsp;
        <Text strong>
          {formatCurrency(invoice.total / 100, invoice.currency)}
        </Text>
      </Paragraph>
    </Card>
  );
};
