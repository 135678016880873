import { ApiClient } from '../../api_client/api_client';
import { LocationActionType } from './actions';
import { LocationDispatch } from './provider';

const apiClient = new ApiClient();

export async function fetchAndSetLocations(
  dispatch: LocationDispatch,
): Promise<boolean> {
  const locations = await apiClient.fetchLocations();
  if (!locations) {
    return false;
  }
  dispatch({ type: LocationActionType.SET_LOCATIONS, locations });
  return true;
}
