import 'reactjs-popup/dist/index.css';

import { SyntheticEvent } from 'react';
import { BiCheckCircle, BiErrorCircle, BiHelpCircle } from 'react-icons/bi';
import styled, { css } from 'styled-components';
import Popup from 'reactjs-popup';

import { TicketApiObject } from '../../../../declarations';
import {
  DeliveryStatusNature,
  getDeliveryStatusNature,
  getMessageDeliveryStatusLabel,
  getDeliveryStatusMeaning,
} from '../../../../utils/message_util';
import { LinkButton } from '../../../link_button/link_button';
import { KioskLabel } from '../../kiosk_label/kiosk_label';

const PopupContent = styled.div`
  padding: .5rem;
`;

const StatusDisplay = styled.div<{ error: boolean; success: boolean }>`
  ${({ theme, error, success }) => css`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: ${theme.colors.hint};
    margin: 0;

    ${error &&
    css`
      color: ${theme.colors.error};
    `}

    ${success &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

interface DeliveryStatusProps {
  data: TicketApiObject;
}

export const DeliveryStatus: React.FC<DeliveryStatusProps> = ({ data }) => {
  const deliveryStatusLabel = getMessageDeliveryStatusLabel(
    data.messageDeliveryStatus,
    data.hasBeenNotified,
  );
  const deliveryStatusNature = getDeliveryStatusNature(
    data.messageDeliveryStatus,
  );

  if (!deliveryStatusLabel) return null;

  // Needed to ensure the containing ticket's `selected` state is not toggled.
  const preventDefaultAndStopPropagation = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Popup
      trigger={
        <LinkButton underline onClick={preventDefaultAndStopPropagation}>
          <StatusDisplay
            success={deliveryStatusNature === DeliveryStatusNature.SUCCESS}
            error={deliveryStatusNature === DeliveryStatusNature.FAILED}>
            {mapDeliveryStatusNatureToIcon(deliveryStatusNature)}
            <KioskLabel>{deliveryStatusLabel}</KioskLabel>
          </StatusDisplay>
        </LinkButton>
      }
      position="top center">
      <PopupContent>{getDeliveryStatusMeaning(data.messageDeliveryStatus, data.hasBeenNotified)}</PopupContent>
    </Popup>
  );
};

function mapDeliveryStatusNatureToIcon(nature: DeliveryStatusNature) {
  switch (nature) {
    case DeliveryStatusNature.FAILED:
      return <BiErrorCircle></BiErrorCircle>;
    case DeliveryStatusNature.SUCCESS:
      return <BiCheckCircle></BiCheckCircle>;
    case DeliveryStatusNature.UNKNOWN:
      return <BiHelpCircle></BiHelpCircle>;
  }
}
