import { LocationApiObject } from '../../../declarations';
import { LocationState } from '../reducer';
import { setCurrentLocation } from './set_curent_location';

export const setLocations = (
  state: LocationState,
  locations: LocationApiObject[],
) => {
  return {
    ...state,
    // If there is only one location, also set it as the current location.
    ...(locations.length === 1
      ? setCurrentLocation(state, locations[0]._id)
      : {}),
    locationsFetched: true,
    locations: locations,
  };
};
