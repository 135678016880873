import styled, { css } from 'styled-components';
import { SaveOutlined } from '@ant-design/icons';
import { Input, Typography, Button, notification } from 'antd';
import { useState } from 'react';
import { LocationApiObject, MessageApiObject } from "../../../../declarations";
import { mapMessageTypeToLabel } from '../../../../utils/message_util';
import { PreviewMessages } from '../preview_messages/preview_messages';
import { ApiClient } from '../../../../api_client/api_client';

const { TextArea } = Input;
const { Paragraph, Title } = Typography;

const MESSAGE_MAX_LENGTH = 160;
const AUTO_SIZE_CONFIG = {
  minRows: 3,
  maxRows: 6,
};

const apiClient = new ApiClient();

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

const ContentContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-bottom: 1px solid ${theme.colors.hairline};

    @media (min-width: ${theme.breakpoints.desktopWide}) {
      border-bottom: none;
    }
  `}
`;

interface Props {
  location: LocationApiObject;
  messages: MessageApiObject[];
}

export const MessagesForLocation = ({ location, messages }: Props) => {
  const [messageMap, setMessageMap] = useState<Map<string, string>>(
    new Map(messages.map(m => [m.type, m.content]))
  );
  const [saving, setSaving] = useState(false);

  const handleChange = (type: string, updatedContent: string) => {
    setMessageMap(oldMessageMap => {
      const newMessageMap = new Map(oldMessageMap);
      newMessageMap.set(type, updatedContent);
      return newMessageMap;
    });
  };

  const handleSave = async () => {
    setSaving(true);
    const result = await apiClient.updateMessagesForLocation(
      location._id,
      Array
        .from(messageMap)
        .map(([ type, content ]) => ({ type, content }))
    );
    if (result) {
      notification.success({
        message: 'Successfully updated messages.',
        placement: 'bottomRight',
      });
    } else {
      notification.error({
        message: 'Error updating messages.',
        placement: 'bottomRight',
      });
    }
    setSaving(false);
  };

  return (
    <ContentContainer>
      {Array.from(messageMap).map(([type, content]) => {
        return <div key={`${location._id}-${type}`}>
          <Paragraph>{mapMessageTypeToLabel(type)}</Paragraph>
          <TextArea
            showCount
            maxLength={MESSAGE_MAX_LENGTH}
            autoSize={AUTO_SIZE_CONFIG}
            value={content}
            onChange={e => handleChange(type, e.target.value)}></TextArea>
        </div>
      })}
      <Buttons>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          disabled={saving}
          loading={saving}
          onClick={handleSave}>
          Save
        </Button>
        <PreviewMessages messages={
          Array.from(messageMap).map(([type, content]) => ({ type, content }))
        }></PreviewMessages>
      </Buttons>
    </ContentContainer>
  )
            
};