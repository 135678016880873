import { LocationAction, LocationActionType } from './actions';
import { LocationApiObject } from '../../declarations';
import { resetLocations, setCurrentLocation } from './reducers';
import { setLocations } from './reducers/set_locations';

export interface LocationState {
  currentLocationId: string;
  locations: LocationApiObject[];
  locationsFetched: boolean;
}

export const reducer = (state: LocationState, action: LocationAction) => {
  switch (action.type) {
    case LocationActionType.SET_CURRENT_LOCATION:
      return setCurrentLocation(state, action.currentLocationId);
    case LocationActionType.SET_LOCATIONS:
      return setLocations(state, action.locations);
    case LocationActionType.RESET_LOCATIONS:
      return resetLocations();
    default:
      return state;
  }
};
