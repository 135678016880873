import { useContext, useEffect } from 'react';
import { startPollingForTickets, stopPollingForTickets } from '../../../contexts/ticket/poller';
import { LocationContext } from '../../../contexts/location/provider';
import { TicketContext } from '../../../contexts/ticket/provider';
import { Ticket } from '../ticket/ticket';
import { KioskHeader } from '../kiosk_header/kiosk_header';
import { TicketState } from '../../../declarations';
import { KioskLabel } from '../kiosk_label/kiosk_label';
import { fetchTicketsFromLiveDuration } from '../../../contexts/ticket/async_actions';
import { FetchSettings } from '../fetch_settings/fetch_settings';

export const TicketList: React.FC = () => {
  const [locationState] = useContext(LocationContext);
  const [ticketsState, ticketDispatch] = useContext(TicketContext);

  useEffect(() => {
    if (!locationState.currentLocationId) return;
    fetchTicketsFromLiveDuration(
      ticketDispatch,
      locationState.currentLocationId,
      ticketsState.liveDuration,
    );
    startPollingForTickets(
      ticketDispatch,
      locationState.currentLocationId,
      ticketsState.liveDuration,
    );
    return () => {
      stopPollingForTickets();
    };
  }, [locationState.currentLocationId, ticketDispatch]);

  const openTickets = ticketsState.tickets.filter(
    data => data.state === TicketState.OPEN,
  );
  const pendingTickets = ticketsState.tickets.filter(
    data => data.state === TicketState.PENDING_PICKUP,
  );

  return (
    <div>
      <KioskHeader heading={'Open Tickets'}></KioskHeader>
      <FetchSettings></FetchSettings>
      <KioskLabel size={20} margin="1rem 0">
        Open
      </KioskLabel>
      {locationState.currentLocationId && (
        <>
          {openTickets.map(data => (
            <Ticket data={data} key={`ticket-list-${data._id}`}></Ticket>
          ))}
        </>
      )}
      <KioskLabel size={20} margin="1rem 0">
        Pending
      </KioskLabel>
      {locationState.currentLocationId && (
        <>
          {pendingTickets.map(data => (
            <Ticket data={data} key={`ticket-list-${data._id}`}></Ticket>
          ))}
        </>
      )}
    </div>
  );
};
