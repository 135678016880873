import { HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import Ripples from 'react-ripples';
import { LoadingOutlined } from '@ant-design/icons';
import { Theme } from '../../../theme/theme';

export enum KioskButtonColor {
  DEFAULT,
  SUCCESS,
}

export enum KioskButtonStyle {
  FILLED,
  BORDERED,
  BORDERLESS,
}

interface ButtonProps {
  theme: Theme;
  buttonColor: KioskButtonColor;
  buttonStyle: KioskButtonStyle;
  extraPadding?: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, buttonColor, buttonStyle, extraPadding }) => {
    const color = {
      default: buttonColor === KioskButtonColor.DEFAULT,
      success: buttonColor === KioskButtonColor.SUCCESS,
    };
    const style = {
      filled: buttonStyle === KioskButtonStyle.FILLED,
      bordered: buttonStyle === KioskButtonStyle.BORDERED,
      borderless: buttonStyle === KioskButtonStyle.BORDERLESS,
    };
    return css`
      color: var(--kiosk-button-text-color, ${theme.colors.body});
      cursor: pointer;
      border: none;
      padding: 0.5rem 1rem;
      font-weight: ${theme.typography.weights.bold};
      text-transform: uppercase;
      letter-spacing: ${theme.typography.spacing.label};
      font-size: ${theme.typography.sizes.body};
      border-radius: 0.5rem;
      width: 100%;
      background: var(--kiosk-button-bg-color, transparent);
      border: 2px solid var(--kiosk-button-border-color, transparent);

      ${extraPadding &&
      css`
        padding: 1rem 2rem;
      `}

      ${color.default &&
      style.filled &&
      css`
        --kiosk-button-text-color: ${theme.colors.surface};
        --kiosk-button-bg-color: ${theme.colors.newPrimary};
      `}

      ${color.success &&
      style.filled &&
      css`
        --kiosk-button-text-color: ${theme.colors.surface};
        --kiosk-button-bg-color: ${theme.colors.newSuccess};
      `}

      ${color.default &&
      style.bordered &&
      css`
        --kiosk-button-border-color: ${theme.colors.newPrimary};
      `}

      ${color.success &&
      style.bordered &&
      css`
        --kiosk-button-text-color: ${theme.colors.body};
        --kiosk-button-border-color: ${theme.colors.newSuccess};
      `}
    `;
  }}
`;

export interface KioskButtonProps extends HTMLProps<HTMLButtonElement> {
  buttonColor?: KioskButtonColor;

  /** Defaults to FILLED. */
  buttonStyle?: KioskButtonStyle;

  extraPadding?: boolean;

  loading?: boolean;

  disabled?: boolean;
}

export const KioskButton: React.FC<KioskButtonProps> = ({
  buttonColor = KioskButtonColor.DEFAULT,
  buttonStyle = KioskButtonStyle.FILLED,
  children,
  extraPadding,
  onClick,
  loading = false,
  disabled = false,
}) => {
  return (
    <Ripples during={500}>
      <Button
        onClick={onClick}
        buttonColor={buttonColor}
        buttonStyle={buttonStyle}
        extraPadding={extraPadding}
        disabled={loading || disabled}>
        {loading ? <LoadingOutlined /> : children}
      </Button>
    </Ripples>
  );
};
