import { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

export enum ButtonStyle {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export enum ButtonEmphasis {
  PRIMARY,
  SECONDARY,
}

export interface ButtonProps {
  buttonStyle: ButtonStyle;
  buttonEmphasis?: ButtonEmphasis;
  onClick?: MouseEventHandler;
  disabled?: boolean;
}

const Root = styled.button<ButtonProps>`
  ${({ theme, buttonEmphasis }) => css`
    align-items: center;
    border-radius: 0.5rem;
    border: 0;
    cursor: pointer;
    display: flex;
    font-weight: ${theme.typography.weights.bold};
    letter-spacing: ${theme.typography.spacing.label};
    text-transform: uppercase;
    justify-content: center;
    width: max-content;
    padding: 1rem;
    color: ${theme.colors.label};

    ${buttonEmphasis === ButtonEmphasis.PRIMARY &&
    `
      font-size: ${theme.typography.sizes.heading4};
    `}

    ${buttonEmphasis === ButtonEmphasis.SECONDARY &&
    `
      font-size: ${theme.typography.sizes.heading5};
    `}
  `}

  ${({ buttonStyle, theme }) =>
    isPrimaryOrSecondary(buttonStyle) &&
    css`
      color: ${theme.colors.surface};
      padding: 1rem;
      
      @media (min-width: ${theme.breakpoints.desktop}) {
        padding: 1rem 1.5rem;
        min-width: 12rem;
      }
    `}

  ${({ buttonStyle, theme }) =>
    buttonStyle === ButtonStyle.PRIMARY &&
    css`
      background: ${theme.colors.primary};
      border: 0.125rem solid ${theme.colors.primaryShadow};
    `}

  ${({ buttonStyle, theme }) =>
    buttonStyle === ButtonStyle.SECONDARY &&
    css`
      background: ${theme.colors.secondary};
      border: 0.125rem solid ${theme.colors.secondaryShadow};
    `}

  ${({ buttonStyle, theme }) =>
    buttonStyle === ButtonStyle.TERTIARY &&
    css`
      background: ${theme.colors.surfaceTertiary};
      border: 0.125rem solid ${theme.colors.hairline};
    `}
`;

export const Button: React.FC<ButtonProps> = ({
  children,
  buttonEmphasis = ButtonEmphasis.PRIMARY,
  ...props
}) => {
  return <Root {...{ buttonEmphasis, ...props }}>{children}</Root>;
};

function isPrimaryOrSecondary(buttonStyle: ButtonStyle): boolean {
  return (
    buttonStyle === ButtonStyle.PRIMARY || buttonStyle === ButtonStyle.SECONDARY
  );
}
