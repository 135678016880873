import { ThemeProvider, createGlobalStyle, css } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';

import { theme } from './theme/theme';
import { MerchantProvider } from './contexts/merchant/provider';
import { LocationProvider } from './contexts/location/provider';
import { TrackVersion } from './components/track_version/track_version';
import { Routing } from './components/routing/routing';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    body {
      background: ${theme.colors.surface};
      touch-action: pan-y;
      color: ${theme.colors.body};
      font-family: ${theme.typography.fonts.general};
    }

    h1 {
      font-size: ${theme.typography.sizes.heading1};
    }

    h2 {
      font-size: ${theme.typography.sizes.heading2};
    }

    h3 {
      font-size: ${theme.typography.sizes.heading3};
    }

    h4 {
      font-size: ${theme.typography.sizes.heading4};
    }

    h5 {
      font-size: ${theme.typography.sizes.heading5};
    }

    h6 {
      font-size: ${theme.typography.sizes.heading6};
    }

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }
  `}
`;

const App: React.FC = () => {
  return (
    <TrackVersion>
      <Router>
        <ThemeProvider theme={theme}>
          <MerchantProvider>
            <LocationProvider>
              <GlobalStyle></GlobalStyle>
              <Routing></Routing>
            </LocationProvider>
          </MerchantProvider>
        </ThemeProvider>
      </Router>
    </TrackVersion>
  );
};

export default App;
