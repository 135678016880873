import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { BiUndo } from 'react-icons/bi';
import { useSwipeable, SwipeDirections } from 'react-swipeable';

import styled, { css } from 'styled-components';

import { ToastContent } from '../../toast_content/toast_content';
import { ToastButton } from '../../toast_content/toast_button';
import { TicketContent } from './ticket_content/ticket_content';
import { OrderExpansion } from './ticket_expansion/ticket_expansion';
import { TicketApiObject, TicketState, UpdateTicketResult } from '../../../declarations';
import { TicketContext } from '../../../contexts/ticket/provider';
import { TicketActionType } from '../../../contexts/ticket/actions';
import { markTicketAsArchived } from '../../../contexts/ticket/async_actions';
import { OrderArchived } from '../../toast_content/order_archived';

const START_SWIPE_DELTA_THRESHOLD_PX = 6;
const SWIPE_DELTA_THRESHHOLD_PX = 200;

const Background = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  margin-bottom: 0.5rem;
`;

const TicketContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ContainerProps {
  selected: boolean;
  xPositionDelta: number;
  swiped: boolean;
  direction: SwipeDirections | null;
  isOpen?: boolean;
  isPending?: boolean;
  isArchived?: boolean;
}

// We need to use `attrs` here since translateX should be used as an inline
// style since it updates frequently along with the swipe events.
const Container = styled.div.attrs<ContainerProps>(
  ({ xPositionDelta, swiped, direction, theme }) => {
    let translateX = `0px`;
    let otherCss: Record<string, string> = {};

    // Users frequently have some sideways movement as they pan the screen
    // up/down, this ensures the animation doesn't start until some threshold is
    // reached.
    if (Math.abs(xPositionDelta) > START_SWIPE_DELTA_THRESHOLD_PX) {
      translateX = `${Math.floor(xPositionDelta)}px`;
    }

    // Once a `swipe` is registered, we set the translateX to 100% to ensure
    // it goes off screen (or -100% if swiped in the other direction) along
    // with an animation to ensure it fades out smoothly.
    if (swiped) {
      translateX = direction === 'Left' ? '-100%' : '100%';
      otherCss[
        'transition'
      ] = `transform 0.4s ${theme.transitions.easeOutQuad}`;
    }

    return {
      style: {
        transform: `translateX(${translateX})`,
        ...otherCss,
      },
    };
  },
)<ContainerProps>`
  border: 0.375rem solid transparent;
  border-left: 0;
  border-right: 0;
  box-sizing: border-box;

  ${({ isOpen, selected, theme }) =>
    isOpen &&
    css`
      background: ${selected
        ? theme.colors.surfacePrimaryEmphasized
        : theme.colors.surfacePrimary};

      ${selected &&
      `
        border-color: ${theme.colors.primary};
      `}
    `}

  ${({ isPending, selected, theme }) =>
    isPending &&
    css`
      background: ${selected
        ? theme.colors.surfaceSecondaryEmphasized
        : theme.colors.surfaceSecondary};

      ${selected &&
      `
        border-color: ${theme.colors.secondary};
      `}
    `}

  ${({ isArchived, selected, theme }) =>
    isArchived &&
    css`
      background: ${selected
        ? theme.colors.surfaceTertiaryEmphasized
        : theme.colors.surfaceTertiary};

      ${selected &&
      `
        border-color: ${theme.colors.hint};
      `}
    `}

  ${({ selected, theme }) => css`
    ${!selected && theme.mixins.elevation3};
    color: ${selected ? theme.colors.headline : theme.colors.body};
  `}
`;

interface OrderProps {
  data: TicketApiObject;
}

export const Ticket: React.FC<OrderProps> = ({ data }) => {
  const [ticketsState, ticketDispatch] = useContext(TicketContext);
  const [xPositionDelta, setXPositionDelta] = useState(0);
  const [swiped, setSwiped] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirections | null>(
    null,
  );
  const [expanded, setExpanded] = useState(false);

  const selected = ticketsState.selectedTicketId === data._id;
  const isOpen = data.state === TicketState.OPEN;
  const isPending = data.state === TicketState.PENDING_PICKUP;
  const isArchived = data.state === TicketState.ARCHIVED;

  // Ensures the expansion state gets reset when this order is no longer focused.
  if (!selected && expanded) {
    setExpanded(false);
  }

  // You can only swipe-to-archive on a non-selected order. This ensures that
  // someone trying to tap the send text button doesn't accidentally swipe.
  const swipeable = !selected && !isArchived;

  const swipeHandlers = useSwipeable({
    onSwiping: eventData => {
      if (!swipeable) return;
      setXPositionDelta(eventData.deltaX);
    },
    onSwipedLeft: ({ velocity, deltaX, dir }) => {
      if (!swipeable) return;
      if (velocity > 2 || Math.abs(deltaX) > SWIPE_DELTA_THRESHHOLD_PX) {
        setSwiped(true);
        setSwipeDirection(dir);
      }
    },
    onSwipedRight: ({ velocity, deltaX, dir }) => {
      if (!swipeable) return;
      if (velocity > 2 || Math.abs(deltaX) > SWIPE_DELTA_THRESHHOLD_PX) {
        setSwiped(true);
        setSwipeDirection(dir);
      }
    },
    onSwiped: () => {
      if (!swipeable) return;
      setXPositionDelta(0);
      setSwiped(false);
    },
    // Default amount of pixels swiped before it is considered the start of
    // a swipe.
    delta: 0,
  });

  const handleTransitionEnd = async () => {
    if (swiped) {
      const previousState = data.state;
      const result = await markTicketAsArchived(ticketDispatch, data._id);
      toast(() => {
        if (result === UpdateTicketResult.SUCCESS) {
          return (
            <OrderArchived
              data={data}
              previousState={previousState}></OrderArchived>
          );
        } else {
          return (
            <ToastContent error>Unable to archive ticket.</ToastContent>
          )
        }
      });
      if (result !== UpdateTicketResult.SUCCESS) {
        setXPositionDelta(0);
        setSwiped(false);
      }
    }
  };

  const handleContainerClick = () => {
    const isAlreadySelected = ticketsState.selectedTicketId === data._id;
    ticketDispatch({
      type: TicketActionType.SET_SELECTED_TICKET,
      ticketId: isAlreadySelected ? '' : data._id,
    });
  };

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Background>
      <Container
        tabIndex={0}
        onClick={() => void handleContainerClick()}
        selected={selected}
        xPositionDelta={xPositionDelta}
        swiped={swiped}
        direction={swipeDirection}
        onTransitionEnd={handleTransitionEnd}
        isOpen={isOpen}
        isPending={isPending}
        isArchived={isArchived}
        {...swipeHandlers}>
        <TicketContentContainer>
          <TicketContent
            data={data}
            expanded={expanded}
            selected={selected}
            onExpandToggle={handleExpandToggle}></TicketContent>
        </TicketContentContainer>
        {expanded && selected && <OrderExpansion data={data}></OrderExpansion>}
      </Container>
    </Background>
  );
};
