export interface Theme {
  colors: {
    headline: string;
    body: string;
    label: string;
    hint: string;
    hairline: string;
    primary: string;
    primaryShadow: string;
    secondary: string;
    secondaryShadow: string;
    surface: string;
    surfacePrimary: string;
    surfacePrimaryEmphasized: string;
    surfaceSecondary: string;
    surfaceSecondaryEmphasized: string;
    surfaceTertiary: string;
    surfaceTertiaryEmphasized: string;
    mute: string;
    error: string;
    errorOnBody: string;
    newPrimary: string;
    newPrimarySurface: string;
    newPrimarySurfaceMute: string;
    newPrimaryBody: string;
    newAccent: string;
    newAccentBody: string;
    newSuccess: string;
    logo: string;
    speechBubble: string;
  };
  typography: {
    fonts: {
      general: string;
    };
    sizes: {
      heading1: string;
      heading2: string;
      heading3: string;
      heading4: string;
      heading5: string;
      heading6: string;
      body: string;
      label: string;
    };
    spacing: {
      label: string;
    };
    weights: {
      regular: string;
      bold: string;
    };
  };
  mixins: {
    elevation1: string;
    elevation2: string;
    elevation3: string;
  };
  transitions: {
    easeInOutExpo: string;
    easeOutQuad: string;
  };
  breakpoints: {
    desktop: string;
    desktopWide: string;
  };
  spacing: {
    dashboardVerticalGutter: string;
  };
}

const DARK_SHADOW_COLOR = 'rgba(0, 0, 0, 0.4)';
const LIGHT_SHADOW_COLOR = 'rgba(0, 0, 0, 0.2)';
const SHADOW_1_BOTTOM_LAYER = `0 0 0.125rem ${DARK_SHADOW_COLOR}`;
const SHADOW_1_TOP_LAYER = `0 0 0.5rem 0 ${LIGHT_SHADOW_COLOR};`;
const SHADOW_2_BOTTOM_LAYER = `0 0 0.25rem 0 ${DARK_SHADOW_COLOR}`;
const SHADOW_2_TOP_LAYER = `0 0 1rem 0 ${LIGHT_SHADOW_COLOR};`;
const SHADOW_3_BOTTOM_LAYER = `0 0 0.375rem 0 ${DARK_SHADOW_COLOR}`;
const SHADOW_3_TOP_LAYER = `0 0 2rem 0 ${LIGHT_SHADOW_COLOR};`;

export const theme: Theme = {
  colors: {
    headline: '#1d1f1e',
    body: '#2d2f2e',
    label: '#434644',
    hint: '#737875',
    hairline: '#d2d6d4',
    primary: '#549472',
    primaryShadow: '#3b6951',
    secondary: '#daa42a',
    secondaryShadow: '#b3841b',
    surface: '#fff',
    surfacePrimary: '#e2efe6',
    surfacePrimaryEmphasized: '#ccf1d8',
    surfaceSecondary: '#fff7e1',
    surfaceSecondaryEmphasized: '#ffeebf',
    surfaceTertiary: '#f5f5f5',
    surfaceTertiaryEmphasized: '#e6e6e6',
    mute: '#bbb',
    error: '#d63147',
    errorOnBody: '#f296a2',
    // new colors
    newPrimary: '#4F5E75',
    newPrimarySurface: '#e7eef9',
    newPrimarySurfaceMute: '#b9c6db',
    newPrimaryBody: '#222a36',
    newAccent: '#EF8354',
    newAccentBody: '#B9400B',
    newSuccess: '#549472',
    logo: '#1766a6',
    speechBubble: '#1982fc',
  },

  typography: {
    fonts: {
      general:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, ' +
        'Helvetica, Arial, sans-serif, "Apple Color Emoji", ' +
        '"Segoe UI Emoji", "Segoe UI Symbol"',
    },
    sizes: {
      heading1: '2.5rem',
      heading2: '2rem',
      heading3: '1.75rem',
      heading4: '1.25rem',
      heading5: '1rem',
      heading6: '0.825rem',
      body: '1rem',
      label: '.875rem',
    },
    spacing: {
      label: '.0625rem',
    },
    weights: {
      regular: '400',
      bold: '700',
    },
  },

  mixins: {
    elevation1: `box-shadow: ${SHADOW_1_BOTTOM_LAYER}, ${SHADOW_1_TOP_LAYER}`,
    elevation2: `box-shadow: ${SHADOW_2_BOTTOM_LAYER}, ${SHADOW_2_TOP_LAYER}`,
    elevation3: `box-shadow: ${SHADOW_3_BOTTOM_LAYER}, ${SHADOW_3_TOP_LAYER}`,
  },

  transitions: {
    easeInOutExpo: 'cubic-bezier(0.87, 0, 0.13, 1)',
    easeOutQuad: 'cubic-bezier(0.16, 1, 0.3, 1)',
  },

  breakpoints: {
    desktop: '768px',
    desktopWide: '1028px',
  },

  spacing: {
    dashboardVerticalGutter: '2rem',
  },
};
