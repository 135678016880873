import { Typography } from 'antd';
import { AuthPage } from '../auth_page/auth_page';
import { IoHammerOutline } from "react-icons/io5";
import styled, { css } from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div`
  ${({ theme }) => css`
    font-size: 4rem;
    color: ${theme.colors.hairline};
  `}
`;

export const MaintenancePage: React.FC = () => {
  return <AuthPage>
    <Root>
      <Typography.Title level={4}>Under Maintenance</Typography.Title>
      <Icon>
        <IoHammerOutline></IoHammerOutline>
      </Icon>
      <Typography.Paragraph style={{ margin: '0' }}>We've temporarily disabled the app for maintenance. We'll be back online shortly. Thanks for your understanding.</Typography.Paragraph>
    </Root>
  </AuthPage>;
};
