import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { fetchAndSetOnboardStatus } from "../../contexts/merchant/async_actions";
import { MerchantContext } from "../../contexts/merchant/provider";
import { OnboardStatus } from "../../declarations";
import { LoadingPage } from "../loading_page/loading_page";

export const RequireOnboarding: React.FC = ({ children }) => {
  const [merchantState, merchantDispatch] = useContext(MerchantContext);
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const success = await fetchAndSetOnboardStatus(merchantDispatch);
      if (!mounted) return;
      if (!success) {
        setError('Unable to initialize app. Please refresh and try again.');
      }
      setFetching(false);
    })();
    return () => {
      mounted = false;
    };
  }, [merchantDispatch]);

  if (fetching || error) {
    return <LoadingPage errorText={error}></LoadingPage>;
  }

  if (merchantState.onboardStatus !== OnboardStatus.ONBOARDED) {
    return <Redirect to="/onboard"></Redirect>;
  }

  return <>
    {children}
  </>;
};