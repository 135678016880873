import Modal from 'antd/lib/modal/Modal';
import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { LocationActionType } from '../../../../contexts/location/actions';
import { LocationContext } from '../../../../contexts/location/provider';
import { LocationApiObject } from '../../../../declarations';
import { KioskLabel } from '../../kiosk_label/kiosk_label';
import { LocationButtons } from '../location_buttons/location_buttons';

const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hint};
  `}
`;

export interface InitialLocationSelectorModalProps {
  locations: LocationApiObject[];
}
export const InitialLocationSelectorModal: React.FC<
  InitialLocationSelectorModalProps
> = ({ locations }) => {
  const [locationState, dispatch] = useContext(LocationContext);
  const [isModalVisible, setIsModalVisible] = useState(
    locationState.currentLocationId === '' || !locationState.locations.find(l => l._id === locationState.currentLocationId),
  );

  const onClick = (location: LocationApiObject) => {
    dispatch({
      type: LocationActionType.SET_CURRENT_LOCATION,
      currentLocationId: location._id,
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        visible={isModalVisible}
        footer={null}
        closable={false}>
        <Label>
          <KioskLabel>Select a location</KioskLabel>
        </Label>
        <LocationButtons onClick={onClick}></LocationButtons>
      </Modal>
    </>
  );
};
