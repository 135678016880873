import styled, { css } from "styled-components";

export const KioskLabel = styled.p<{size?: number, margin?: string, error?: boolean}>`
  ${({ theme, size, margin, error}) => css`
    font-size: ${size ? `${size / 16}rem` : theme.typography.sizes.label};
    font-weight: ${theme.typography.weights.bold};
    letter-spacing: ${theme.typography.spacing.label};
    text-transform: uppercase;
    margin: ${margin ? margin : '0'};
    ${error && css`
      color: ${theme.colors.error};
    `}
  `}
`;