import styled from 'styled-components';
import { Table } from 'antd';

function getPricingData(startingCredits: number) {
  return [
    {
      key: 'pd-row-1',
      quantity: `Up to ${startingCredits} messages`,
      price: 'Free',
    },
    {
      key: 'pd-row-2',
      quantity: `After ${startingCredits + 1}+ messages`,
      price: '$0.025 / message',
    },
  ];
}

const COLUMN_DATA = [
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'pd-column-quantity',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'pd-column-price',
  },
];

const Root = styled.div`
  margin: 2rem 0;
`;

interface PricingTableProps {
  startingCredits: number;
}

export const PricingTable: React.FC<PricingTableProps> = ({
  startingCredits,
}) => {
  return (
    <Root>
      <Table
        dataSource={getPricingData(startingCredits)}
        columns={COLUMN_DATA}
        bordered={true}
        pagination={{
          hideOnSinglePage: true,
        }}></Table>
    </Root>
  );
};
