import { DatePicker, Radio, RadioChangeEvent, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const SEVEN_DAYS_MS = ONE_DAY_MS * 7;
const THIRTY_DAYS_MS = ONE_DAY_MS * 30;
const ONE_YEAR_MS = ONE_DAY_MS * 365;

export enum DefaultTimeRangeValue {
  ONE_DAY = 'ONE_DAY',
  SEVEN_DAYS = 'SEVEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  ONE_YEAR = 'ONE_YEAR',
  ALL_TIME = 'ALL_TIME',
  CUSTOM = 'CUSTOM',
}

const DEFAULT_TIME_RANGE_OPTIONS = [
  {
    label: '24 Hours',
    value: DefaultTimeRangeValue.ONE_DAY,
  },
  {
    label: '7 days',
    value: DefaultTimeRangeValue.SEVEN_DAYS,
  },
  {
    label: '30 days',
    value: DefaultTimeRangeValue.THIRTY_DAYS,
  },
  {
    label: '1 year',
    value: DefaultTimeRangeValue.ONE_YEAR,
  },
  {
    label: 'All Time',
    value: DefaultTimeRangeValue.ALL_TIME,
  },
  {
    label: 'Custom',
    value: DefaultTimeRangeValue.CUSTOM,
  },
];

interface SelectDateProps {
  startMoment: Moment | null;
  setStartMoment: (momentOrNull: Moment | null) => void;
  endMoment: Moment | null;
  setEndMoment: (momentOrNull: Moment | null) => void;
  selectedDefaultTimeRange: DefaultTimeRangeValue;
  setSelectedDefaultTimeRange: (range: DefaultTimeRangeValue) => void;
}

export function getStartTimestampMsFromTimeRange(timeRange: DefaultTimeRangeValue): number|undefined {
  const now = Date.now();
  switch (timeRange) {
    case DefaultTimeRangeValue.ONE_DAY:
      return now - ONE_DAY_MS;
    case DefaultTimeRangeValue.SEVEN_DAYS:
      return now - SEVEN_DAYS_MS;
    case DefaultTimeRangeValue.THIRTY_DAYS:
      return now - THIRTY_DAYS_MS;
    case DefaultTimeRangeValue.ONE_YEAR:
      return now - ONE_YEAR_MS;
    default:
      return;
  }
}

export const SelectDate: React.FC<SelectDateProps> = ({
  startMoment,
  setStartMoment,
  endMoment,
  setEndMoment,
  selectedDefaultTimeRange,
  setSelectedDefaultTimeRange,
}) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const onChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setSelectedDefaultTimeRange(newValue);
    setShowDateRangePicker(newValue === DefaultTimeRangeValue.CUSTOM);
    if (newValue === DefaultTimeRangeValue.CUSTOM) {
      setStartMoment(null);
      setEndMoment(null);
    } else {
      setSelectedDefaultTimeRange(newValue);
      setStartMoment(null);
      setEndMoment(null);
    }
  };

  const onDateRangeChange = (value: [Moment | null, Moment | null] | null) => {
    if (value === null) {
      setStartMoment(null);
      setEndMoment(null);
    } else {
      const [startMoment, endMoment] = value;
      setStartMoment(startMoment);
      setEndMoment(endMoment);
    }
  };

  return (
    <Root>
      <Typography.Title level={4}>Choose Date Range</Typography.Title>
      <div>
        <Radio.Group
          options={DEFAULT_TIME_RANGE_OPTIONS}
          onChange={onChange}
          value={selectedDefaultTimeRange}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      {showDateRangePicker && (
        <div>
          <DatePicker.RangePicker
            value={[startMoment, endMoment]}
            autoFocus
            onChange={onDateRangeChange}></DatePicker.RangePicker>
        </div>
      )}
    </Root>
  );
};
