import { ApiClient } from '../api_client/api_client';
import { MerchantApiObject } from '../declarations';
import { EventBus } from '../event_bus/event_bus';
import { MerchantModel } from './merchant_model';

// TODO: Migrate this to a Merchant Context.

const apiClient = new ApiClient();

/**
 * @deprecated
 */
class MerchantService {
  readonly events = new EventBus();

  private merchantObject?: MerchantApiObject;
  private merchantModel?: MerchantModel;
  private initialized = false;

  async initialize(force = false): Promise<this> {
    if (!force && this.initialized) return this;
    this.initialized = await this.fetchAndCacheMerchant();
    return this;
  }

  async getMerchantModel(force = false): Promise<MerchantModel | undefined> {
    await this.initialize(force);
    return this.merchantModel;
  }

  private async fetchAndCacheMerchant(): Promise<boolean> {
    try {
      const merchant = await apiClient.fetchMerchant();
      if (!merchant) {
        console.error('Merchant was not found');
        return false;
      }
      this.merchantObject = merchant;
      const {
        username,
        name,
        email,
        orderReadyMessage,
        followUpMessage,
        issueWithOrderMessage,
        subscriptionStatus,
        willCancelAtPeriodEnd,
        trialCredits,
        affiliateId,
      } = merchant;
      this.merchantModel = new MerchantModel({
        username,
        name,
        email,
        orderReadyMessage,
        followUpMessage,
        issueWithOrderMessage,
        subscriptionStatus,
        willCancelAtPeriodEnd,
        trialCredits,
        affiliateId,
      });
      return true;
    } catch (err) {
      console.log('err = ', err);
      return false;
    }
  }

  getMerchantApiObject(): MerchantApiObject | undefined {
    return this.merchantObject;
  }
}

export const merchantService = new MerchantService();
