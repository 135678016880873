import styled, { css } from 'styled-components';
import { LocationSelector } from '../location_selector/location_selector';

const Root = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;
  
  @media (min-width: ${theme.breakpoints.desktop}) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  `}
`;

const Heading = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.heading3};
    text-transform: uppercase;
    margin: 0;
    font-weight: 700;
    color: ${theme.colors.body};
  `}
`;

export const KioskHeader: React.FC<{ heading: string }> = ({ heading }) => {
  return (
    <Root>
      <Heading>{heading}</Heading>
      <LocationSelector></LocationSelector>
    </Root>
  );
};