const DATE_TIME_FORMATTER = new Intl.DateTimeFormat('en', {
  month: 'short',
  day: 'numeric',
});

export function formatDate(date: Date): string {
  return DATE_TIME_FORMATTER.format(date);
}

export function formatDateTimestampSec(timestamp: number) {
  return formatDate(new Date(timestamp * 1000));
}

export function formatCurrency(amount: number, currency: string): string {
  return new Intl.NumberFormat('en', { style: 'currency', currency }).format(
    amount,
  );
}
