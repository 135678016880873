import { useEffect, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { fetchAndSetLocations } from '../../../contexts/location/async_actions';
import { LocationContext } from '../../../contexts/location/provider';
import { Spinner } from '../../spinner/spinner';
import { KioskButton } from '../kiosk_button/kiosk_button';
import { KioskError } from '../kiosk_error/kiosk_error';
import { InitialLocationSelectorModal } from './initial_location_selector_modal/initial_location_selector_modal';
import { LocationDrawer } from './location_drawer/location_drawer';

const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    gap: .75rem;
    
    @media (min-width: ${theme.breakpoints.desktop}) {
      justify-content: flex-end;
    }
  `}
`;

const Label = styled.div`
`;

const Value = styled.div`
`;

export const LocationSelector: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState('');
  const [fetchingLocations, setFetchingLocations] = useState(false);
  const [locationState, locationDispatch] = useContext(LocationContext);

  const onClose = () => {
    setVisible(false);
  };

  const onClick = () => {
    setVisible(true);
  };

  useEffect(() => {
    (async () => {
      if (locationState.locationsFetched) return;
      setFetchingLocations(true);
      const success = await fetchAndSetLocations(locationDispatch);
      if (!success) {
        setError('Unable to fetch locations. Please refresh the page.');
      }
      setFetchingLocations(false);
    })();
  }, [locationState.locationsFetched, locationDispatch]);

  if (fetchingLocations) return <Spinner></Spinner>;

  const isCurrentLocationIdValid = Boolean(locationState.locations.find(l => l._id === locationState.currentLocationId));
  if (!locationState.currentLocationId || !isCurrentLocationIdValid) {
    return <InitialLocationSelectorModal locations={locationState.locations}></InitialLocationSelectorModal>;
  }

  if (error) return <KioskError>{error}</KioskError>;

  const currentLocation = locationState.locations.find(
      location => location._id === locationState.currentLocationId);

  if (locationState.locations.length === 1) {
    return <></>;
  }

  return (
    <>
      <Root>
        <Label>Location:</Label>
        <Value>{currentLocation?.name}</Value>
        <KioskButton onClick={onClick}>Change</KioskButton>
      </Root>
      <LocationDrawer onClose={onClose} visible={visible}></LocationDrawer>
    </>
  )
}