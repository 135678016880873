import styled from 'styled-components';
import { AuthPage } from '../../auth_page/auth_page';
import { Form, Input, Typography, Button } from 'antd';
import { InputLabel } from '../../input_label/input_label';
import { InputContainer } from '../../input_container/input_container';
import { useCallback, useState } from 'react';
import { ApiClient } from '../../../api_client/api_client';
import { Link } from 'react-router-dom';
import { Center } from '../../center/center';

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const InitiatePasswordReset: React.FC = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [inFlight, setInFlight] = useState(false);
  const [maybeSent, setMaybeSent] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = useCallback(async () => {
    setInFlight(true);
    const success = await apiClient.initiatePasswordReset({ usernameOrEmail });
    if (success) {
      setMaybeSent(true);
    } else {
      setError('An error occured. Please try again.');
    }
    setInFlight(false);
  }, [usernameOrEmail]);

  return (
    <AuthPage>
      <Form onFinish={handleSubmit}>
        <Root>
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            Reset Password
          </Typography.Title>
          {maybeSent ? (
            <>
              <Typography.Paragraph>
                If that username or email exists, we just sent a link to reset
                your password to the email associated with that account.
              </Typography.Paragraph>
              <Typography.Paragraph>
                It can take a couple of minutes for the email to arrive.
              </Typography.Paragraph>
              <Typography.Paragraph>
                <Center>
                  <Link to="/login">Login</Link>
                </Center>
              </Typography.Paragraph>
            </>
          ) : (
            <>
              <InputContainer>
                <InputLabel htmlFor="username_or_email">
                  Username or Email:
                </InputLabel>
                <Input
                  id="username_or_email"
                  type="text"
                  value={usernameOrEmail}
                  onChange={e => setUsernameOrEmail(e.target.value)}></Input>
              </InputContainer>
              <ButtonContainer>
                <Button loading={inFlight} type="primary" size="large" htmlType="submit">
                  Submit
                </Button>
              </ButtonContainer>
              {error && (
                <Typography.Paragraph type="danger">
                  {error}
                </Typography.Paragraph>
              )}
            </>
          )}
        </Root>
      </Form>
    </AuthPage>
  );
};
