import { Card, Typography } from "antd";
import styled, { css } from "styled-components";

export interface FreeCreditsProps {
  credits: number;
}

const Root = styled.div`
  margin-bottom: 1rem;
`;

const CreditCount = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.typography.weights.bold};
    font-size: ${theme.typography.sizes.heading1};
    margin: 0;
  `}
`;

export const FreeCredits: React.FC<FreeCreditsProps> = ({ credits }) => {
  return <Root>
    <Typography.Title level={3}>
      Trial Credits Left
    </Typography.Title>
    <CreditCount>{credits}</CreditCount>
    <Typography.Paragraph>Once these trial credits are used, we'll begin billing for your app usage at $0.025/message (2.5 cents per message).</Typography.Paragraph>
  </Root>
};