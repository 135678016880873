import { Button, Form, Input, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ApiClient } from '../../../api_client/api_client';
import { AuthPage } from '../../auth_page/auth_page';
import { Center } from '../../center/center';
import { InputContainer } from '../../input_container/input_container';
import { InputLabel } from '../../input_label/input_label';
import {
  PasswordValidation,
  PASSWORD_SCHEMA,
} from '../../password_validation/password_validation';

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ResetFormProps {
  token: string;
}

export const ResetForm: React.FC<ResetFormProps> = ({ token }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [succeeded, setSucceeded] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!isValid()) return;
    const success = await apiClient.resetPassword({
      newPassword: password,
      token,
    });
    if (success) {
      setSucceeded(true);
    } else {
      setError('An error occurred. Please try again.');
    }
  }, [password, confirmPassword, token]);

  const isValid = useCallback(() => {
    return PASSWORD_SCHEMA.validate(password) && password === confirmPassword;
  }, [password, confirmPassword]);

  if (succeeded) {
    return (
      <AuthPage>
        <Typography.Paragraph>
          Successfully reset password.
        </Typography.Paragraph>
        <Link to="/login" style={{ display: 'inline-block' }}><Button type="primary">Login</Button></Link>
      </AuthPage>
    );
  }

  return (
    <AuthPage>
      <Center>
        <Typography.Title level={4}>Reset Password</Typography.Title>
      </Center>
      <Form onFinish={handleSubmit}>
        <Root>
          <InputContainer>
            <InputLabel htmlFor="password">New password:</InputLabel>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}></Input>
            <PasswordValidation password={password}></PasswordValidation>
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="confirm-password">
              Confirm new password:
            </InputLabel>
            <Input
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}></Input>
          </InputContainer>
          <ButtonContainer>
            <Button disabled={!isValid()} type="primary" htmlType="submit">
              Reset password
            </Button>
          </ButtonContainer>
          {error && (
            <Typography.Paragraph type="danger">{error}</Typography.Paragraph>
          )}
        </Root>
      </Form>
    </AuthPage>
  );
};
