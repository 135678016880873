import { EventBus } from '../event_bus/event_bus';
import { ApiClient } from '../api_client/api_client';
import { MerchantApiObject } from '../declarations';
import { StripeSubscriptionStatus } from '../declarations';

// TODO: Migrate this to Merchant Context.

export enum MerchantModelProperty {
  /** Triggered when the email changes. */
  EMAIL,

  /** Triggered when any of the configured messages changes. */
  MESSAGE,

  /** Triggered when the subscription status is changed. */
  SUBSCRIPTION,
}

const apiClient = new ApiClient();

export class MerchantModel {
  readonly events = new EventBus<MerchantModelProperty>();

  private username: string;
  private name: string;
  private email: string;
  private orderReadyMessage: string;
  private followUpMessage: string;
  private issueWithOrderMessage: string;
  private subscriptionStatus: StripeSubscriptionStatus;
  private willCancelAtPeriodEnd: boolean;
  private trialCredits: number;
  private affiliateId?: string;

  constructor({
    username,
    name,
    email,
    affiliateId,
    orderReadyMessage,
    followUpMessage,
    issueWithOrderMessage,
    subscriptionStatus,
    willCancelAtPeriodEnd,
    trialCredits,
  }: MerchantApiObject) {
    this.username = username;
    this.name = name;
    this.email = email;
    this.orderReadyMessage = orderReadyMessage;
    this.followUpMessage = followUpMessage;
    this.issueWithOrderMessage = issueWithOrderMessage;
    this.subscriptionStatus = subscriptionStatus;
    this.willCancelAtPeriodEnd = willCancelAtPeriodEnd;
    this.trialCredits = trialCredits;
    this.affiliateId = affiliateId;
  }

  getUsername(): string {
    return this.username;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): this {
    if (name === this.name) return this;
    this.name = name;
    return this;
  }

  getEmail(): string {
    return this.email;
  }

  setEmail(email: string): this {
    if (this.email === email) return this;
    this.email = email;
    this.events.trigger(MerchantModelProperty.EMAIL);
    return this;
  }

  getOrderReadyMessage(): string {
    return this.orderReadyMessage;
  }

  setOrderReadyMessage(message: string): this {
    if (this.orderReadyMessage === message) return this;
    this.orderReadyMessage = message;
    this.events.trigger(MerchantModelProperty.MESSAGE);
    return this;
  }

  getFollowUpMessage(): string {
    return this.followUpMessage;
  }

  setFollowUpMessage(message: string): this {
    if (this.followUpMessage === message) return this;
    this.followUpMessage = message;
    this.events.trigger(MerchantModelProperty.MESSAGE);
    return this;
  }

  getIssueWithOrderMessage(): string {
    return this.issueWithOrderMessage;
  }

  setIssueWithOrderMessage(message: string): this {
    if (this.issueWithOrderMessage === message) return this;
    this.issueWithOrderMessage = message;
    this.events.trigger(MerchantModelProperty.MESSAGE);
    return this;
  }

  isCanceledOrWillCancelSubscription(): boolean {
    return (
      this.subscriptionStatus === StripeSubscriptionStatus.CANCELED ||
      (this.subscriptionStatus === StripeSubscriptionStatus.ACTIVE &&
        this.willCancelAtPeriodEnd)
    );
  }

  getSubscriptionStatus(): StripeSubscriptionStatus {
    return this.subscriptionStatus;
  }

  willCancelSubscription(): boolean {
    return this.willCancelAtPeriodEnd;
  }

  getTrialCredits(): number {
    return this.trialCredits;
  }

  getAffiliateId(): string | undefined {
    return this.affiliateId;
  }

  async cancelSubscriptionAtPeriodEnd(): Promise<boolean> {
    const success = await apiClient.cancelStripeSubscriptionAtPeriodEnd();
    if (success) {
      this.willCancelAtPeriodEnd = true;
      this.events.trigger(MerchantModelProperty.SUBSCRIPTION);
    }
    return success;
  }

  async reactivateSubscription(): Promise<boolean> {
    const success = await apiClient.reactivateStripeSubscription();
    if (success) {
      this.willCancelAtPeriodEnd = false;
      this.subscriptionStatus = StripeSubscriptionStatus.ACTIVE;
      this.events.trigger(MerchantModelProperty.SUBSCRIPTION);
    }
    return success;
  }

  // TODO: Update this to also rehydrate the model with the latest
  // `MerchantApiObject`.
  async save(): Promise<boolean> {
    return await apiClient.updateMerchant(this);
  }
}
