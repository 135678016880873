import { ImSpinner8 } from 'react-icons/im';
import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Root = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    animation: ${rotate} 1.2s cubic-bezier(0.87, 0, 0.13, 1) infinite;
  `}
`;

export const Spinner: React.FC = () => {
  return (
    <Root>
      <ImSpinner8></ImSpinner8>
    </Root>
  );
};
