import styled from 'styled-components';
import { Button, Modal, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { KnownMessageType } from '../../../../declarations';
import { useEffect, useState } from 'react';
import { Loading } from '../../loading/loading';
import { ApiClient } from '../../../../api_client/api_client';
import { MessageBubble } from './message_bubble';
import { mapMessageTypeToLabel } from '../../../../utils/message_util';

const apiClient = new ApiClient();

const SingleMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
`;

interface PreviewMessagesProps {
  messages: Array<{
    type: KnownMessageType|string;
    content: string;
  }>;
}

export const PreviewMessages: React.FC<PreviewMessagesProps> = ({
  messages,
}) => {
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previews, setPreviews] = useState<string[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!isModalVisible) {
      setLoading(true);
    } else {
      // We put this behind a set timeout to make explicit to the end
      // user that we're loading the previews with a loading icon.
      setTimeout(async () => {
        const previews = await apiClient.previewMessages(
          messages.map(({ content }) => content),
        );
        if (!previews) {
          setError('Unable to preview messages');
        } else {
          setPreviews(previews);
        }
        setLoading(false);
      }, 800);
    }
  }, [messages, isModalVisible]);

  return (
    <div>
      <Button
        type="default"
        icon={<EyeOutlined />}
        onClick={() => setIsModalVisible(true)}>
        Preview
      </Button>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}>
        <Typography.Title level={4}>Preview Messages</Typography.Title>

        {loading ? (
          <Loading></Loading>
        ) : error ? (
          <Typography.Paragraph type="danger">{error}</Typography.Paragraph>
        ) : (
          <LoadedPreviews
            previews={previews}
            messages={messages}></LoadedPreviews>
        )}
      </Modal>
    </div>
  );
};

const LoadedPreviews: React.FC<{
  previews: string[];
  messages: PreviewMessagesProps['messages'];
}> = ({ previews, messages }) => {
  return (
    <div>
      {previews.length === 0 ? (
        <div>No messages to show</div>
      ) : (
        <div>
          {previews.map((previewMessage, index) => (
            <SingleMessage
              key={`message-bubble-${index}-${previewMessage.slice(0, 10)}`}>
              <Typography.Text>
                {mapMessageTypeToLabel(messages[index].type)}
              </Typography.Text>
              <MessageBubble>{previewMessage}</MessageBubble>
            </SingleMessage>
          ))}
        </div>
      )}
    </div>
  );
};
