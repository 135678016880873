import { MerchantApiObject, OnboardStatus } from '../../declarations';

export enum MerchantActionType {
  SET_MERCHANT = 'SET_MERCHANT',
  SET_ONBOARD_STATUS = 'SET_ONBOARD_STATUS',
}

export type MerchantAction =
  | {
      type: MerchantActionType.SET_MERCHANT;
      merchant: MerchantApiObject;
    }
  | {
      type: MerchantActionType.SET_ONBOARD_STATUS;
      status: OnboardStatus;
    };
