import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import assert from 'assert-ts';

import {
  getToken,
  checkForMissingOrExpiredTokenAndMaybeRedirect,
  storeToken,
} from '../utils/auth_util';
import {
  KnownMessageType,
  CardDetails,
  Invoice,
  OnboardStatus,
  MerchantApiObject,
  LocationApiObject,
  TicketApiObject,
  TicketState,
  ExportOptionColumn,
  UpdateTicketResult,
  MarketPartner,
  MessageApiObject,
  ReferralMerchant,
} from '../declarations';
import { MerchantModel } from '../merchant_service/merchant_model';
import { SignupResponse } from './declarations';
import { CreateMerchantStatus } from '../declarations';

export interface SignupParameters {
  username: string;
  password: string;
  email: string;
  merchantName: string;
  adminPasscode: string;
  affiliateReferrerId?: string;
  marketPartner?: MarketPartner;
}

export interface InitiatePasswordResetParams {
  usernameOrEmail: string;
}

export interface ResetPasswordParams {
  newPassword: string;
  token: string;
}

export interface ResetAdminPasscodeParams {
  newAdminPasscode: string;
  token: string;
}

export interface UpdatePasswordParams {
  currentPassword: string;
  newPassword: string;
}

export interface UpdateAdminPasscodeParams {
  currentAdminPasscode: string;
  newAdminPasscode: string;
}

export enum MessageType {
  ORDER_READY,
  FOLLOW_UP,
  ISSUE_WITH_ORDER,
}

export interface UpdateMerchantParams {
  email: string;
  messages: Map<string, string>;
}

export interface CreateTicketParams {
  locationId: string;
  phoneNumber: string;
  notes?: string;
}

export interface CreateLocationParams {
  name: string;
  notes: string;
}

export interface UpdateLocationParams extends CreateLocationParams {
  id: string;
}

export interface DeleteLocationParams {
  id: string;
}

export interface FetchRecentTicketsParams {
  locationId: string;
  sinceMs?: number;
}

export interface UpdateTicketParams {
  id: string;
  state?: TicketState;
  hasBeenFollowedUp?: boolean;
  hasIssue?: boolean;
  sendMessage?: boolean;
}

export interface ExportTicketParams {
  columns: ExportOptionColumn[];
  /** Ignored if `includeAllLocations=true` */
  locations: Array<LocationApiObject['_id']>;
  includeAllLocations: boolean;
  startTimestampMs?: number;
  endTimestampMs?: number;
}

export class ApiClient {
  private lastRequestedOrderTimestampMs: number;

  constructor(initialTimestamp: number = Date.now()) {
    this.lastRequestedOrderTimestampMs = initialTimestamp;
  }

  /**
   * Fetches the current build short SHA (first 7 characters of git commit hash).
   */
  async fetchShortSha(): Promise<string> {
    try {
      const fetchShaRequest: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/sha',
      };
      const resp = await axios(fetchShaRequest);
      return resp.data.result;
    } catch (err: any) {
      logAxiosError('Could not fetch sha.', err);
      return '';
    }
  }

  /**
   * @returns the jwt token or an empty string if login was invalid.
   */
  async login(usernameOrEmail: string, password: string): Promise<string> {
    try {
      const loginRequest: AxiosRequestConfig = {
        method: 'post',
        url: '/api/auth/login',
        data: {
          usernameOrEmail,
          password,
        },
      };
      const resp = await axios(loginRequest);
      return resp.data.token;
    } catch (err: any) {
      logAxiosError('Could not login.', err);
      return '';
    }
  }

  /**
   * @returns the jwt token or an empty string if sign up was unsuccessful.
   */
  async signup({
    username,
    password,
    email,
    merchantName,
    adminPasscode,
    affiliateReferrerId,
    marketPartner,
  }: SignupParameters): Promise<SignupResponse> {
    try {
      const signupRequest: AxiosRequestConfig = {
        method: 'post',
        url: '/api/auth/sign_up',
        data: {
          username,
          password,
          email,
          merchantName,
          adminPasscode,
          affiliateReferrerId,
          marketPartner,
        },
      };
      const resp = await axios(signupRequest);
      return {
        status: CreateMerchantStatus.SUCCESS,
        token: resp.data.token,
      };
    } catch (err: any) {
      logAxiosError('Could not sign up', err);
      return {
        status: err.response?.data.status,
      };
    }
  }

  /**
   * @returns a boolean to indicate success. `false` means that
   * a server-side error occured. `true` does not guarantee that an
   * email was actually sent since the username or email may not
   * have been found, but we hide this from the end-user.
   */
  async initiatePasswordReset({
    usernameOrEmail,
  }: InitiatePasswordResetParams): Promise<boolean> {
    try {
      const initiatePasswordResetsRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/auth/init_reset_password',
        data: {
          usernameOrEmail,
        },
      };
      await axios(initiatePasswordResetsRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  async verifyPasswordResetToken(token: string): Promise<boolean> {
    try {
      const verifyPasswordResetTokenRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/auth/verify_reset_password_token',
        data: {
          token,
        },
      };
      const resp = await axios(verifyPasswordResetTokenRequest);
      return resp.data.success ?? false;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  async resetPassword({ newPassword, token }: ResetPasswordParams) {
    try {
      const resetPasswordRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/auth/reset_password',
        data: {
          password: newPassword,
          token,
        },
      };
      const resp = await axios(resetPasswordRequest);
      return resp.data.success;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  /**
   * @returns a boolean to indicate success. `false` means that
   * a server-side error occured. `true` does not guarantee that an
   * email was actually sent since the username or email may not
   * have been found, but we hide this from the end-user.
   */
  async initiateAdminPasscodeReset(): Promise<boolean> {
    try {
      const initiateAdminPasscodeResetsRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/auth/init_reset_admin_passcode',
        headers: {
          ...this.getAuthHeader(),
        },
      };
      await axios(initiateAdminPasscodeResetsRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  async verifyAdminPasscodeResetToken(token: string): Promise<boolean> {
    try {
      const verifyAdminPasscodeResetTokenRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/auth/verify_reset_admin_passcode_token',
        data: {
          token,
        },
      };
      const resp = await axios(verifyAdminPasscodeResetTokenRequest);
      return resp.data.success ?? false;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  async resetAdminPasscode({
    newAdminPasscode,
    token,
  }: ResetAdminPasscodeParams): Promise<boolean> {
    try {
      const resetAdminPasscodeRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/auth/reset_admin_passcode',
        data: {
          adminPasscode: newAdminPasscode,
          token,
        },
      };
      const resp = await axios(resetAdminPasscodeRequest);
      return resp.data.success;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  async updatePassword({ currentPassword, newPassword }: UpdatePasswordParams) {
    try {
      const updatePasswordRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/admin/merchant/password',
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          currentPassword,
          newPassword,
        },
      };
      const resp = await axios(updatePasswordRequest);
      return resp.data.success;
    } catch (err: any) {
      logAxiosError('Unable to update password', err);
      return false;
    }
  }

  async updateAdminPasscode({
    currentAdminPasscode,
    newAdminPasscode,
  }: UpdateAdminPasscodeParams) {
    try {
      const updatePasswordRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/admin/merchant/admin-passcode',
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          currentAdminPasscode,
          newAdminPasscode,
        },
      };
      const resp = await axios(updatePasswordRequest);
      return resp.data.success;
    } catch (err: any) {
      logAxiosError('Unable to update admin passcode', err);
      return false;
    }
  }

  /**
   * @returns The ticket string.
   */
  async createTicket({ locationId, phoneNumber, notes }: CreateTicketParams) {
    try {
      const createTicketRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/ticket',
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          locationId,
          phoneNumber,
          notes,
        },
      };
      const resp = await axios(createTicketRequest);
      return resp.data.ticket;
    } catch (err: any) {
      // TODO: add error handling
      console.error('err = ', err);
      return [];
    }
  }

  async fetchTickets({
    locationId,
    sinceMs,
  }: FetchRecentTicketsParams): Promise<TicketApiObject[] | null> {
    try {
      const fetchTicketsRequest: AxiosRequestConfig = {
        method: 'GET',
        url: `/api/ticket/${locationId}`,
        headers: {
          ...this.getAuthHeader(),
        },
        params: {
          sinceMs: sinceMs,
        },
      };
      const resp = await axios(fetchTicketsRequest);
      const tickets: TicketApiObject[] = resp.data.results;
      return tickets;
    } catch (err: any) {
      // TODO: add error handling
      console.error('err = ', err);
      return null;
    }
  }

  async updateTicket({
    id,
    state,
    hasBeenFollowedUp,
    hasIssue,
    sendMessage,
  }: UpdateTicketParams): Promise<UpdateTicketResult> {
    try {
      const fetchTicketsRequest: AxiosRequestConfig = {
        method: 'PUT',
        url: `/api/ticket/${id}`,
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          state,
          hasBeenFollowedUp,
          hasIssue,
          sendMessage,
        },
      };
      const resp = await axios(fetchTicketsRequest);
      return resp.data.result;
    } catch (err: any) {
      console.error('err = ', err);
      return UpdateTicketResult.FAILED_UNKNOWN;
    }
  }

  /**
   * Checks for admin status by requesting an admin token.
   * Stores the token if authenticated.
   */
  async checkAdmin(adminPasscode: string): Promise<boolean> {
    const getAdminTokenRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/auth/get_admin_token',
      headers: {
        ...this.getAuthHeader(),
      },
      data: {
        adminPasscode,
      },
    };
    try {
      const resp = await axios(getAdminTokenRequest);
      if (resp.data.isAdmin) {
        storeToken(resp.data.token);
        return true;
      }
      return false;
    } catch (err: any) {
      logAxiosError('Could not check admin status.', err);
      return false;
    }
  }

  /**
   * Fetches the list of locations.
   *
   * @returns `null` to signal an error.
   */
  async fetchLocations(): Promise<LocationApiObject[] | null> {
    const fetchLocationsRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/location',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(fetchLocationsRequest);
      return resp.data.results;
    } catch (err: any) {
      logAxiosError('Could not fetch locations.', err);
      return null;
    }
  }

  /**
   * Creates a new location with `name` and `notes`.
   * @returns the ID of the location or `null` to signal an error.
   */
  async createLocation({
    name,
    notes,
  }: CreateLocationParams): Promise<string | null> {
    try {
      const createLocationRequest: AxiosRequestConfig = {
        method: 'post',
        url: '/api/location',
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          name,
          notes,
        },
      };
      const resp = await axios(createLocationRequest);
      return resp.data.id;
    } catch (err: any) {
      logAxiosError('Could not create location.', err);
      return null;
    }
  }

  /**
   * Updates the location with id `id`.
   *
   * @returns `null` to signal an error.
   */
  async updateLocation({
    id,
    name,
    notes,
  }: UpdateLocationParams): Promise<string | null> {
    const updateLocationRequest: AxiosRequestConfig = {
      method: 'put',
      url: `/api/location/${id}`,
      headers: {
        ...this.getAuthHeader(),
      },
      data: {
        name,
        notes,
      },
    };
    try {
      const resp = await axios(updateLocationRequest);
      return resp.data.id;
    } catch (err: any) {
      logAxiosError('Could not update location.', err);
      return null;
    }
  }

  /**
   * Deletes the location with `id`.
   *
   * @returns the ID of the deleted location, or `null` to signal an error.
   */
  async deleteLocation({ id }: DeleteLocationParams): Promise<string | null> {
    try {
      const deleteLocationRequest: AxiosRequestConfig = {
        method: 'delete',
        url: `/api/location/${id}`,
        headers: {
          ...this.getAuthHeader(),
        },
      };
      const resp = await axios(deleteLocationRequest);
      return resp.data.id;
    } catch (err: any) {
      logAxiosError('Could not update location.', err);
      return null;
    }
  }

  /**
   * Returns the payment secret to complete the subscription payment.
   */
  async createStripeSubscription(): Promise<boolean> {
    const createStripeSubscriptionRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/admin/subscription',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      await axios(createStripeSubscriptionRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not fetch payment intent', err);
      return false;
    }
  }

  async cancelStripeSubscriptionAtPeriodEnd(): Promise<boolean> {
    const cancelStripeSubscriptionRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/admin/subscription/cancel',
      headers: {
        ...this.getAuthHeader(),
      },
    };

    try {
      await axios(cancelStripeSubscriptionRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not fetch payment intent', err);
      return false;
    }
  }

  async reactivateStripeSubscription(): Promise<boolean> {
    const reactivateStripeSubscriptionRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/admin/subscription/reactivate',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      await axios(reactivateStripeSubscriptionRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not fetch payment intent', err);
      return false;
    }
  }

  async createStripeSetupIntent(): Promise<string | undefined> {
    const createStripeSetupIntentRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/admin/create-setup-intent',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(createStripeSetupIntentRequest);
      return resp.data.clientSecret;
    } catch (err: any) {
      logAxiosError('Could not fetch payment intent', err);
      return;
    }
  }

  async updateStripeCustomerPaymentMethod(
    stripePaymentMethodId: string,
  ): Promise<boolean> {
    const updateStripeCustomerPaymentMethodRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/admin/update-payment',
      data: {
        stripePaymentMethodId,
      },
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      await axios(updateStripeCustomerPaymentMethodRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not update stripe customer payment method', err);
      return false;
    }
  }

  async deletePaymentMethod(stripePaymentMethodId: string): Promise<boolean> {
    const deleteStripeCustomerPaymentMethodRequest: AxiosRequestConfig = {
      method: 'delete',
      url: '/api/admin/payment-methods',
      data: {
        stripePaymentMethodId,
      },
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      await axios(deleteStripeCustomerPaymentMethodRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not delete stripe customer payment method', err);
      return false;
    }
  }

  // TODO: Update types.
  async fetchStripeCustomerPaymentMethods(): Promise<CardDetails[] | null> {
    const fetchStripeCustomerPaymentMethodsRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/admin/payment-methods',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(fetchStripeCustomerPaymentMethodsRequest);
      return resp.data.paymentMethods;
    } catch (err: any) {
      logAxiosError('Could not fetch customer payment methods', err);
      return null;
    }
  }

  async fetchMerchant(): Promise<MerchantApiObject | undefined> {
    const fetchMerchantRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/admin/merchant',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(fetchMerchantRequest);
      return resp.data;
    } catch (err: any) {
      logAxiosError('Could not fetch merchant', err);
      return undefined;
    }
  }

  async fetchReferrals(): Promise<ReferralMerchant[] | undefined> {
    const fetchReferralsRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/admin/merchant/referrals',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(fetchReferralsRequest);
      return resp.data.referrals;
    } catch (err: any) {
      logAxiosError('Could not fetch merchant', err);
      return undefined;
    }
  }

  async fetchOnboardStatus(): Promise<OnboardStatus | undefined> {
    const fetchOnboardStatusRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/admin/merchant/onboard-status',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(fetchOnboardStatusRequest);
      return resp.data.onboardStatus;
    } catch (err: any) {
      logAxiosError('Could not fetch merchant', err);
      return undefined;
    }
  }

  async updateMerchant(merchantModel: MerchantModel): Promise<boolean> {
    const updateMerchantRequest: AxiosRequestConfig = {
      method: 'post',
      url: '/api/admin/merchant',
      headers: {
        ...this.getAuthHeader(),
      },
      data: {
        name: merchantModel.getName(),
        email: merchantModel.getEmail(),
        messages: [
          [KnownMessageType.ORDER_READY, merchantModel.getOrderReadyMessage()],
          [KnownMessageType.FOLLOW_UP, merchantModel.getFollowUpMessage()],
          [
            KnownMessageType.ISSUE_WITH_ORDER,
            merchantModel.getIssueWithOrderMessage(),
          ],
        ],
      },
    };
    try {
      await axios(updateMerchantRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not update merchant', err);
      return false;
    }
  }

  async fetchAllMessagesSplitByLocation(): Promise<{
    locations: Array<{
      location: LocationApiObject;
      messages: MessageApiObject[];
    }>;
  } | null> {
    try {
      const fetchAllMessagesRequest: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/admin/message',
        headers: { ...this.getAuthHeader() },
      };
      const response = await axios(fetchAllMessagesRequest);
      return response.data;
    } catch (err: any) {
      logAxiosError('Could not fetch messages', err);
      return null;
    }
  }

  async updateMessagesForLocation(
    locationId: string,
    messages: Array<{ type: KnownMessageType | string; content: string }>,
  ) {
    try {
      const updateMessagesRequest: AxiosRequestConfig = {
        method: 'PUT',
        url: '/api/admin/message',
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          locationId,
          messages,
        },
      };
      await axios(updateMessagesRequest);
      return true;
    } catch (err: any) {
      logAxiosError('Could not update messages', err);
      return false;
    }
  }

  async previewMessages(messages: string[]): Promise<string[] | null> {
    try {
      const previewMessagesRequest: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/message/preview',
        headers: {
          ...this.getAuthHeader(),
        },
        data: {
          messages,
        },
      };
      const resp = await axios(previewMessagesRequest);
      return resp.data.results;
    } catch (err: any) {
      logAxiosError('Could not preview messages', err);
      return null;
    }
  }

  async fetchInvoices(): Promise<{
    upcomingInvoice?: Invoice;
    invoices?: Invoice[];
  }> {
    const fetchInvoicesRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/admin/invoice',
      headers: {
        ...this.getAuthHeader(),
      },
    };
    try {
      const resp = await axios(fetchInvoicesRequest);
      return {
        upcomingInvoice: resp.data.upcomingInvoice,
        invoices: resp.data.invoices,
      };
    } catch (err: any) {
      logAxiosError('Could not fetch upcoming invoice', err);
      return {};
    }
  }

  async exportTickets({
    columns,
    includeAllLocations,
    locations,
    startTimestampMs,
    endTimestampMs,
  }: ExportTicketParams): Promise<string> {
    let timezone = '';
    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {}
    const fetchExportRequest: AxiosRequestConfig = {
      method: 'get',
      url: '/api/export',
      headers: {
        ...this.getAuthHeader(),
      },
      params: {
        columns: columns,
        locationIds: includeAllLocations ? undefined : locations,
        startTimestampMs,
        endTimestampMs,
        timezone,
      },
    };
    try {
      const resp = await axios(fetchExportRequest);
      return resp.data.results;
    } catch (err: any) {
      logAxiosError('Could not export tickets', err);
      return '';
    }
  }

  async isMaintenanceMode(): Promise<boolean> {
    try {
      const isMaintenanceModeRequest: AxiosRequestConfig = {
        method: 'get',
        url: '/api/config/is_maintenance_mode',
      };
      const resp = await axios(isMaintenanceModeRequest);
      return resp.data.isMaintenanceMode;
    } catch (err: any) {
      logAxiosError('Could not fetch maintenance mode info', err);
      return false;
    }
  }

  async fetchStartingCredits(): Promise<{ success: boolean; result: number }> {
    try {
      const fetchStartingCreditsRequest: AxiosRequestConfig = {
        method: 'get',
        url: '/api/config/starting_credits',
        headers: {
          ...this.getAuthHeader(),
        },
      };
      const resp = await axios(fetchStartingCreditsRequest);
      return { success: true, result: resp.data.result };
    } catch (err: any) {
      logAxiosError('Could not fetch starting credits', err);
      return { success: false, result: 0 };
    }
  }

  private getAuthHeader(): { Authorization: string } {
    checkForMissingOrExpiredTokenAndMaybeRedirect();
    return {
      Authorization: `Bearer ${assert(getToken())}`,
    };
  }
}

function logAxiosError(message: string, err: AxiosError) {
  console.error(
    message,
    'status =',
    err.response?.status,
    'data =',
    err.response?.data,
  );
}
