import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MerchantModel } from '../../../merchant_service/merchant_model';
import { PageContainer } from '../page_container/page_container';
import styled from 'styled-components';
import { Alert, Card, Divider, Input, Progress, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ReferralMerchant } from '../../../declarations';
import { ApiClient } from '../../../api_client/api_client';

export interface AffiliatePageProps extends RouteComponentProps {
  merchantModel: MerchantModel;
}

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputContainer = styled.div`
  max-width: 30rem;
`;

export const AffiliatePage = withRouter<
  AffiliatePageProps,
  React.FunctionComponent<AffiliatePageProps>
>(({ merchantModel }) => {
  const [referrals, setReferrals] = useState<ReferralMerchant[]>([]);
  const [fetchReferralsError, setFetchReferralsError] = useState('');

  useEffect(() => {
    (async () => {
      const data = await apiClient.fetchReferrals();
      if (data) {
        setReferrals(data);
        console.log('data = ', data);
      } else {
        setFetchReferralsError('Uh oh, an error ocurred. Please try again.');
      }
    })();
  }, []);

  const inputRef = useRef<any>(null);
  const handleUrlClick = () => {
    inputRef.current?.focus({ cursor: 'all' });
  };
  return (
    <PageContainer merchantModel={merchantModel}>
      <Root>
        <Typography.Title>
          Refer a Friend: Get 1000 Free Credits
        </Typography.Title>
        <Alert
          message="The free credits will be deposited to your account after your referral uses 1000 paid messages (not trial credits)."
          type="info"
          showIcon
        />
        <InputContainer style={{ marginTop: '1rem' }}>
          <Typography.Paragraph>Your affiliate URL:</Typography.Paragraph>
          <Input
            ref={inputRef}
            onClick={handleUrlClick}
            value={`https://app.swiftexpo.io/signup?ref=${merchantModel.getAffiliateId()}`}
          />
        </InputContainer>
        <Divider />
        <Typography.Title level={2}>Your Referrals</Typography.Title>
        {fetchReferralsError ? (
          <Typography.Paragraph type="danger">
            {fetchReferralsError}
          </Typography.Paragraph>
        ) : (
          referrals.map(r => (
            <Card>
              <Typography.Paragraph strong>
                Store Name: {r.name}
              </Typography.Paragraph>
              <Typography.Paragraph>
                {Math.min(1000, r.sentCount)} / 1000 messages sent
              </Typography.Paragraph>
              <Progress
                percent={Math.floor((r.sentCount / 1000) * 100)}
                showInfo={false}
              />
            </Card>
          ))
        )}
      </Root>
    </PageContainer>
  );
});
