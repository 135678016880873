import { Typography } from 'antd';
import { AuthPage } from '../auth_page/auth_page';

export const Container: React.FC = ({ children }) => {
  return (
    <AuthPage>
      <Typography.Title level={4}>Reset Admin Passcode</Typography.Title>
      {children}
    </AuthPage>
  );
};
