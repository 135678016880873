import { LocationState } from '../../contexts/location/reducer';
import { CURRENT_LOCATION_ID_LOCAL_STORAGE_KEY } from '../../constants/session_constants';
import { LocationApiObject } from '../../declarations';

export const initialState: LocationState = {
  currentLocationId:
    localStorage.getItem(CURRENT_LOCATION_ID_LOCAL_STORAGE_KEY) ?? '',
  locations: [] as LocationApiObject[],
  locationsFetched: false,
};
