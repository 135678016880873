import { useContext } from 'react';
import { Drawer } from 'antd';
import { LocationContext } from '../../../../contexts/location/provider';
import { LocationApiObject } from '../../../../declarations';
import { LocationActionType } from '../../../../contexts/location/actions';
import { LocationButtons } from '../location_buttons/location_buttons';

export interface LocationDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export const LocationDrawer: React.FC<LocationDrawerProps> = ({
  onClose,
  visible,
}) => {
  const [, locationDispatch] = useContext(LocationContext);

  const onClick = (location: LocationApiObject) => {
    locationDispatch({
      type: LocationActionType.SET_CURRENT_LOCATION,
      currentLocationId: location._id,
    });
    onClose();
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}>
      <LocationButtons onClick={onClick}></LocationButtons>
    </Drawer>
  );
};
