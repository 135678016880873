import styled, { css } from "styled-components"

const Root = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
  `}
`;

export const KioskError: React.FC = ({ children }) => (
  <Root>{children}</Root>
)