/**
 * Key used to store the JWT in local storage.
 */
export const TOKEN_LOCAL_STORAGE_KEY = 'hzkCVs';

/**
 * Key used to store the current location ID in local storage.
 */
export const CURRENT_LOCATION_ID_LOCAL_STORAGE_KEY = '3fs3V';

/**
 * Key used to store the ticket live duration setting in local storage.
 */
export const TICKET_LIVE_DURATION_KEY = 'Yz0Kfm';
