import { BiErrorCircle } from 'react-icons/bi';
import styled, { css } from 'styled-components';

const Root = styled.div<{error?: boolean}>`
  ${({ theme, error }) => css`
    align-items: center;
    background: ${theme.colors.body};
    border-radius: 0.25rem;
    color: ${theme.colors.surface};
    display: flex;
    font-size: ${theme.typography.sizes.heading5};
    justify-content: center;
    max-width: 350px;
    padding: 0.5rem 0.75rem;
    gap: .5rem;

    ${error && css`
      color: ${theme.colors.errorOnBody};
    `}
  `}
`;

export const ToastContent: React.FC<{ error?: boolean }> = ({ children, error = false }) => {
  return <Root error={error}>
    {error && <BiErrorCircle size={22}></BiErrorCircle>}
    {children}
  </Root>;
};
