import styled, { css } from 'styled-components';
import { NumberPadButton } from './number_pad_button';

const Root = styled.div`
  ${({ theme }) => css`
    display: grid;
    aspect-ratio: 1/1;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    @media (min-width: ${theme.breakpoints.desktop}) {
      aspect-ratio: 3/4;
    }
  `}
`;

export interface NumberPadProps {
  onClick: (value: number) => void;
  onDeleteClick: () => void;
  showDelete: boolean;
}

export const NumberPad: React.FC<NumberPadProps> = ({
  onClick,
  showDelete,
  onDeleteClick,
}) => {
  return (
    <Root>
      <NumberPadButton onClick={() => onClick(1)}>1</NumberPadButton>
      <NumberPadButton onClick={() => onClick(2)}>2</NumberPadButton>
      <NumberPadButton onClick={() => onClick(3)}>3</NumberPadButton>
      <NumberPadButton onClick={() => onClick(4)}>4</NumberPadButton>
      <NumberPadButton onClick={() => onClick(5)}>5</NumberPadButton>
      <NumberPadButton onClick={() => onClick(6)}>6</NumberPadButton>
      <NumberPadButton onClick={() => onClick(7)}>7</NumberPadButton>
      <NumberPadButton onClick={() => onClick(8)}>8</NumberPadButton>
      <NumberPadButton onClick={() => onClick(9)}>9</NumberPadButton>
      <div></div>
      <NumberPadButton onClick={() => onClick(0)}>0</NumberPadButton>
      {showDelete ? (
        <NumberPadButton onClick={onDeleteClick} deleteButton>X</NumberPadButton>
      ) : (
        <div></div>
      )}
    </Root>
  );
};
