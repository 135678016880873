import styled from 'styled-components';
import { Typography, Button, Divider } from 'antd';
import React, { useState } from 'react';

import { PageContainer } from '../page_container/page_container';
import {
  MerchantModel,
  MerchantModelProperty,
} from '../../../merchant_service/merchant_model';
import { PaymentMethods } from '../payment_methods/payment_methods';
import { Usage } from '../usage/usage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { EditPassword } from './edit_password/edit_password';
import { EditAdminPasscode } from './edit_admin_passcode/edit_admin_passcode';
import { BasicSettings } from './basic_settings/basic_settings';

const { Title } = Typography;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export interface AccountPageProps extends RouteComponentProps {
  merchantModel: MerchantModel;
}

export const AccountPage = withRouter<
  AccountPageProps,
  React.FunctionComponent<AccountPageProps>
>(({ merchantModel, history }: AccountPageProps) => {
  const [isCanceledOrWillCancel, setIsCancledOrWillCancel] = useState(
    merchantModel.isCanceledOrWillCancelSubscription(),
  );

  useEffect(() => {
    const handleSubscriptionStatusChange = () => {
      setIsCancledOrWillCancel(
        merchantModel.isCanceledOrWillCancelSubscription(),
      );
    };
    merchantModel.events.on(
      MerchantModelProperty.SUBSCRIPTION,
      handleSubscriptionStatusChange,
    );
    return () => {
      merchantModel.events.off(
        MerchantModelProperty.SUBSCRIPTION,
        handleSubscriptionStatusChange,
      );
    };
  }, [merchantModel, merchantModel.events]);

  return (
    <PageContainer merchantModel={merchantModel}>
      <Root>
        <Title>Account</Title>
        <BasicSettings merchantModel={merchantModel}></BasicSettings>
        <Divider></Divider>
        <EditPassword></EditPassword>
        <Divider></Divider>
        <EditAdminPasscode></EditAdminPasscode>
        <Divider></Divider>
        <PaymentMethods></PaymentMethods>
        <Divider></Divider>
        <Usage></Usage>
        {!isCanceledOrWillCancel && (
          <>
            <Divider></Divider>
            <div>
              <Title level={2}>Cancel Subscription</Title>
              <Button
                danger
                onClick={() => {
                  history.push('/dashboard/cancel');
                }}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Root>
    </PageContainer>
  );
});
