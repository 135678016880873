import { Switch, Route } from 'react-router-dom';

import { Kiosk } from '../kiosk/kiosk';
import { Dashboard } from '../dashboard/dashboard';
import { Onboard } from '../onboard/onboard';
import { Login } from '../login/login';
import { Signup } from '../signup/signup';
import { RequireOnboarding } from '../require_onboarding/require_onboarding';
import { Logout } from '../logout/logout';
import { ResetPassword } from '../reset_password/reset_password';
import { ResetAdminPasscode } from '../reset_admin_passcode/reset_admin_passcode';
import { MaintenancePage } from '../maintenance_page/maintenance_page';
import { CheckMaintenanceMode } from '../maintenance_page/check_maintenance_mode';

export const Routing: React.FC = () => {
  return (
    <CheckMaintenanceMode>
      <Switch>
        <Route path="/maintenance">
          <MaintenancePage></MaintenancePage>
        </Route>
        <Route path="/logout">
          <Logout></Logout>
        </Route>
        <Route path="/reset_password">
          <ResetPassword></ResetPassword>
        </Route>
        <Route path="/reset_admin_passcode">
          <ResetAdminPasscode></ResetAdminPasscode>
        </Route>
        <Route path="/login">
          <Login></Login>
        </Route>
        <Route path="/signup">
          <Signup></Signup>
        </Route>
        <Route path="/onboard">
          <Onboard></Onboard>
        </Route>
        <Route path="/dashboard">
          <RequireOnboarding>
            <Dashboard></Dashboard>
          </RequireOnboarding>
        </Route>
        <Route path="/">
          <RequireOnboarding>
            <Kiosk></Kiosk>
          </RequireOnboarding>
        </Route>
      </Switch>
    </CheckMaintenanceMode>
  );
};
