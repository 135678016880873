import { TicketApiObject, TicketState } from '../../declarations';

export enum TicketActionType {
  SET_TICKETS = 'set_tickets',
  UPDATE_TICKET_STATE = 'update_ticket_state',
  SET_SELECTED_TICKET = 'set_selected_ticket',
  FOLLOW_UP = 'follow_up',
  MARK_ISSUE = 'mark_issue',

  /** Used to set how far back ticket fetches should go. Defaults to 1 hour. */
  SET_LIVE_DURATION = 'set_live_duration',
}

/**
 * The underlying values are duration representations in milliseconds.
 */
export enum TicketLiveDuration {
  ONE_HOUR = 1000 * 60 * 60,
  EIGHT_HOURS = 1000 * 60 * 60 * 8,
  ONE_DAY = 1000 * 60 * 60 * 24,
  SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7,
  THIRTY_DAYS = 1000 * 60 * 60 * 24 * 30,
}

export type TicketAction =
  | { type: TicketActionType.SET_TICKETS; tickets: TicketApiObject[] }
  | {
      type: TicketActionType.UPDATE_TICKET_STATE;
      ticketId: string;
      ticketState: TicketState;
    }
  | {
      type: TicketActionType.SET_SELECTED_TICKET;
      ticketId: string;
    }
  | {
      type: TicketActionType.FOLLOW_UP;
      ticketId: string;
    }
  | {
      type: TicketActionType.MARK_ISSUE;
      ticketId: string;
    }
  | {
      type: TicketActionType.SET_LIVE_DURATION;
      duration: TicketLiveDuration;
    };
