import { useState } from 'react';
import { Button, Modal, Input, Typography } from 'antd';
import styled from 'styled-components';
import { ApiClient } from '../../../../api_client/api_client';

const apiClient = new ApiClient();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export interface AddLocationProps {
  onSubmit: () => void;
}

export const AddLocation: React.FC<AddLocationProps> = ({ onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');
  const [creating, setCreating] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setCreating(true);
    try {
      const createdId = await apiClient.createLocation({ name, notes });
      if (!createdId) throw new Error();
      setIsModalVisible(false);
      onSubmit();
    } catch (err) {
      setError('Unable to create location. Please refresh and try again.');
    }
    setCreating(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add Location
      </Button>
      <Modal title="Add New Location" visible={isModalVisible} onOk={handleOk} okButtonProps={{ loading: creating }} onCancel={handleCancel} okText="Add">
        <Container>
          <div>
            <Typography.Paragraph strong>
              <label htmlFor="name">Location Name:</label>
            </Typography.Paragraph>
            <Input type="text" value={name} name="name" onChange={(e) => void setName(e.target.value)}></Input>
          </div>
          <div>
            <Typography.Paragraph strong>
              <label htmlFor="notes">Location Notes:</label>
            </Typography.Paragraph>
            <Input.TextArea rows={5} value={notes} name="notes" onChange={(e) => void setNotes(e.target.value)}></Input.TextArea>
          </div>
          {error && (
            <Typography.Paragraph type="danger">
              {error}
            </Typography.Paragraph>
          )}
        </Container>
      </Modal>
    </>
  )
};