import styled from 'styled-components';
import { Typography } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { ApiClient } from '../../../api_client/api_client';
import { MerchantContext } from '../../../contexts/merchant/provider';
import { Invoice } from '../../../declarations';
import { FreeCredits } from './free_credits/free_credits';
import { SingleInvoice } from './single_invoice/single_invoice';
import { UpcomingInvoice } from './upcoming_invoice/upcoming_invoice';

const { Title, Text } = Typography;

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Usage: React.FC = () => {
  const [merchantState] = useContext(MerchantContext);

  const [openInvoices, setOpenInvoices] = useState<Invoice[] | undefined>();
  const [upcomingInvoice, setUpcomingInvoice] = useState<Invoice | undefined>();
  const [error, setError] = useState('');

  const fetchInvoices = async () => {
    const { invoices, upcomingInvoice } = await apiClient.fetchInvoices();
    if (invoices && upcomingInvoice) {
      setOpenInvoices(invoices);
      setUpcomingInvoice(upcomingInvoice);
    } else {
      setError('Error fetching invoice. Please refresh');
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);
  
  if (merchantState.merchant === null) return <></>;

  return (
    <Root>
      <Title level={2}>Usage</Title>
      {merchantState.merchant.trialCredits > 0 && (
        <FreeCredits credits={merchantState.merchant.trialCredits}></FreeCredits>
      )}
      {error && <Text type="danger">{error}</Text>}
      {upcomingInvoice && (
        <>
          <Title level={3}>Next Invoice</Title>
          <UpcomingInvoice invoice={upcomingInvoice}></UpcomingInvoice>
        </>
      )}
      {openInvoices && openInvoices.length > 0 && (
        <>
          <Title level={3}>Open Invoices</Title>
          {openInvoices.map(invoice => (
            <SingleInvoice invoice={invoice} key={invoice.id}></SingleInvoice>
          ))}
        </>
      )}
    </Root>
  );
};
