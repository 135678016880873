import { Button, notification, Typography } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { MerchantModel } from '../../../merchant_service/merchant_model';
import { PageContainer } from '../page_container/page_container';

const { Title, Paragraph } = Typography;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export interface CancelSubscriptionProps extends RouteComponentProps {
  merchantModel: MerchantModel;
}

export const CancelSubscription = withRouter<
  CancelSubscriptionProps,
  React.FC<CancelSubscriptionProps>
>(({ history, merchantModel }) => {
  const [error, setError] = useState('');

  const handleConfirm = async () => {
    const success = await merchantModel.cancelSubscriptionAtPeriodEnd();
    if (success) {
      notification.success({
        message: 'Your subscription has been canceled.',
      });
      handleGoBack();
    } else {
      setError('There was an error cancelling the subscription');
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <PageContainer merchantModel={merchantModel}>
      <Title>Cancel Subscription</Title>
      <Paragraph>
        The subscription will cancel at the end of the current billing cycle, at
        which point you will be billed one last time for this cycle's usage.
      </Paragraph>
      <Paragraph>
        After the current billing cycle ends, you will no longer be invoiced and
        you will no longer be able to send messages through the app.
      </Paragraph>
      {error && <Paragraph type="danger">{error}</Paragraph>}
      <ButtonContainer>
        <Button danger type="primary" onClick={handleConfirm}>
          Cancel Subscription
        </Button>
        <Button onClick={handleGoBack}>Go back</Button>
      </ButtonContainer>
    </PageContainer>
  );
});
