import { Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ApiClient } from '../../api_client/api_client';
import { FIVE_MINUTES_MS } from '../../constants/time_constants';

const apiClient = new ApiClient();

let intervalId = 0;

async function fetchSha(): Promise<string> {
  try {
    return apiClient.fetchShortSha();
  } catch {
    // Worst case, if this request fails, we don't care to show an error
    // to the user as it's non-essential.
    return '';
  }
}

export const TrackVersion: React.FC = ({ children }) => {
  const [initialSha, setInitialSha] = useState('');
  const [prompted, setPrompted] = useState(false);

  const fetchAndStoreSha = useCallback(async () => {
    if (initialSha !== '') return;
    const currentSha = await fetchSha();
    setInitialSha(currentSha);
  }, [setInitialSha]);

  const fetchShaAndMaybeShowRefreshDialog = useCallback(
    async (prompted: boolean, initialSha: string) => {
      try {
        if (prompted) return;

        const currentSha = await fetchSha();
        if (currentSha === initialSha) return;

        setPrompted(true);
        Modal.confirm({
          title: 'Our app has updated.',
          icon: <ExclamationCircleOutlined />,
          content:
            'Please refresh the browser to ensure things work as expected.',
          onOk: () => {
            window.location.reload();
          },
          okText: 'Refresh',
          onCancel: () => {
            setPrompted(false);
          },
        });
      } catch {}
    },
    [prompted, initialSha, setPrompted],
  );

  useEffect(() => {
    if (intervalId) {
      window.clearInterval(intervalId);
    }

    fetchAndStoreSha();
    intervalId = window.setInterval(
      () => fetchShaAndMaybeShowRefreshDialog(prompted, initialSha),
      FIVE_MINUTES_MS,
    );

    // Ensures the poller is torn down if component unmounts.
    return () => {
      window.clearInterval(intervalId);
    };
  }, [prompted, initialSha]);

  return <>{children}</>;
};
