import styled, { css } from 'styled-components';
import { BiMessageRoundedError, BiArchiveIn } from 'react-icons/bi';

import { Button, ButtonEmphasis, ButtonStyle } from '../../../button/button';
import { ButtonContent } from '../../../button/button_content';
import { useContext, useState } from 'react';
import { Spinner } from '../../../spinner/spinner';
import toast from 'react-hot-toast';
import { ToastContent } from '../../../toast_content/toast_content';
import { TicketApiObject, UpdateTicketResult } from '../../../../declarations';
import {
  markTicketAsArchived,
  markTicketAsHavingAnIssue,
} from '../../../../contexts/ticket/async_actions';
import { TicketContext } from '../../../../contexts/ticket/provider';
import { OrderArchived } from '../../../toast_content/order_archived';
import { AlreadySent } from '../already_sent/already_sent';

const Root = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.desktop}) {
      flex-direction: row;
      justify-content: center;
    }
  `}
`;

// We assign min width/height to ensure the button size doesn't change when
// the in flight spinnner replaces the button contents.
const EnsureMinSize = styled.div`
  display: flex;
  justify-content: center;
  min-width: 15rem;
  min-height: 1.75rem;
`;

interface LiveTicketActionsProps {
  data: TicketApiObject;
}

export const LiveTicketActions: React.FC<LiveTicketActionsProps> = ({
  data,
}) => {
  const [hasIssueInFlight, setHasIssueInFlight] = useState(false);
  const [archiveInFlight, setArchiveInFlight] = useState(false);
  const [, ticketDispatch] = useContext(TicketContext);

  const handleIssueWithOrderClick = async () => {
    if (hasIssueInFlight) {
      return;
    }
    setHasIssueInFlight(true);
    const result = await markTicketAsHavingAnIssue(ticketDispatch, data._id);
    setHasIssueInFlight(false);
    toast(
      result === UpdateTicketResult.SUCCESS ? (
        <ToastContent>Notified customer that there was an issue</ToastContent>
      ) : (
        <ToastContent error>Unable to send notification</ToastContent>
      ),
    );
  };

  const handleArchiveOrderClick = async () => {
    const previousState = data.state;
    setArchiveInFlight(true);
    const result = await markTicketAsArchived(ticketDispatch, data._id);
    toast(() => {
      if (result === UpdateTicketResult.SUCCESS) {
        return (
          <OrderArchived
            data={data}
            previousState={previousState}></OrderArchived>
        );
      } else {
        return <ToastContent error>Unable to archive ticket.</ToastContent>;
      }
    });
    setArchiveInFlight(false);
  };

  return (
    <Root>
      <div>
        {data.hasIssue ? (
          <AlreadySent>Already sent issue notification.</AlreadySent>
        ) : (
          <Button
            buttonStyle={ButtonStyle.TERTIARY}
            buttonEmphasis={ButtonEmphasis.SECONDARY}
            onClick={handleIssueWithOrderClick}>
            <EnsureMinSize>
              {hasIssueInFlight ? (
                <Spinner></Spinner>
              ) : (
                <ButtonContent gap={'0.75rem'}>
                  <BiMessageRoundedError
                    size={'1.75rem'}></BiMessageRoundedError>
                  Notify of Issue
                </ButtonContent>
              )}
            </EnsureMinSize>
          </Button>
        )}
      </div>
      <div>
        <Button
          buttonStyle={ButtonStyle.TERTIARY}
          buttonEmphasis={ButtonEmphasis.SECONDARY}
          onClick={handleArchiveOrderClick}>
          <EnsureMinSize>
            {archiveInFlight ? (
              <Spinner></Spinner>
            ) : (
              <ButtonContent gap={'0.75rem'}>
                <BiArchiveIn size={'1.75rem'}></BiArchiveIn>
                Archive Ticket
              </ButtonContent>
            )}
          </EnsureMinSize>
        </Button>
      </div>
    </Root>
  );
};
