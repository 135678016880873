import styled, { css } from 'styled-components';

type LogoColor = 'surface' | 'primary';

const Root = styled.div<{ color: LogoColor; width?: string }>`
  ${({ theme, color, width }) => css`
    fill: ${color === 'surface' ? theme.colors.surface : theme.colors.logo};
    width: ${width ? width : '12.5rem'};
  `}
`;

export const Logo: React.FC<{
  color?: LogoColor;
  width?: string;
}> = ({ color = 'primary', width }) => {
  return (
    <Root color={color} width={width}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1293.13 271.82">
        <circle cx="583.39" cy="68.42" r="17.98" />
        <path d="M354.46,84.32a5.43,5.43,0,0,1-7.7.25,27.57,27.57,0,0,0-9-5.76,43.7,43.7,0,0,0-16.46-3.15,41.66,41.66,0,0,0-9.93,1.17,27,27,0,0,0-8.64,3.73,19.66,19.66,0,0,0-6.19,6.66,19.25,19.25,0,0,0-2.33,9.69q0,8.4,5.84,12.84a57.26,57.26,0,0,0,14.47,7.71q8.64,3.27,18.92,6.3a77.37,77.37,0,0,1,18.92,8.41,46.8,46.8,0,0,1,14.47,14.25q5.83,8.87,5.84,23.58,0,13.32-4.9,23.24a47.3,47.3,0,0,1-13.2,16.46,57,57,0,0,1-19.26,9.81,80.6,80.6,0,0,1-23.12,3.27,86.88,86.88,0,0,1-29.66-5.14,56.16,56.16,0,0,1-20.84-13.29,5.36,5.36,0,0,1,.09-7.64l14.1-13.65a5.37,5.37,0,0,1,7.74.28,37.3,37.3,0,0,0,10.71,8.26,39.59,39.59,0,0,0,18.56,4.56,39,39,0,0,0,10.27-1.4,32,32,0,0,0,9.35-4.21,22,22,0,0,0,6.77-7.12,19.08,19.08,0,0,0,2.57-9.93q0-9.1-5.84-14a51.6,51.6,0,0,0-14.48-8.29q-8.64-3.37-18.91-6.42a79.47,79.47,0,0,1-18.92-8.29,45.93,45.93,0,0,1-14.48-14q-5.83-8.76-5.84-23.47,0-12.84,5.26-22.18A47.86,47.86,0,0,1,282.44,61.3a60.19,60.19,0,0,1,19.49-9.23,83.88,83.88,0,0,1,22.42-3,81.63,81.63,0,0,1,25.34,4,57.87,57.87,0,0,1,17.55,9.25,5.39,5.39,0,0,1,.6,8Z" />
        <path d="M381.23,106.48H400.1a5.36,5.36,0,0,1,5.12,3.77L428.34,185h.47l21.53-74.6a5.36,5.36,0,0,1,5.15-3.87h22.38a5.34,5.34,0,0,1,5.11,3.77L506.1,185h.47l22.21-74.64a5.37,5.37,0,0,1,5.13-3.83H550.7a5.36,5.36,0,0,1,5.07,7.08L521.35,214.94a5.36,5.36,0,0,1-5.07,3.63H496.13a5.36,5.36,0,0,1-5.06-3.59L465.7,142h-.46L442.81,214.8a5.34,5.34,0,0,1-5.11,3.77H416.5a5.36,5.36,0,0,1-5.06-3.59L376.17,113.6A5.36,5.36,0,0,1,381.23,106.48Zm179.38,6.42" />
        <path d="M597.4,211.57v7h-7A7,7,0,0,0,597.4,211.57Z" />
        <path d="M576.39,218.58h-7v-7A7,7,0,0,0,576.39,218.58Z" />
        <path d="M597.4,111.84V213.22a5.36,5.36,0,0,1-5.36,5.36h-17.3a5.36,5.36,0,0,1-5.36-5.36V111.85a5.36,5.36,0,0,1,5.36-5.36h17.31A5.34,5.34,0,0,1,597.4,111.84Z" />
        <path d="M769,124.92v-13a5.38,5.38,0,0,0-5.38-5.39H743.6a5.38,5.38,0,0,1-5.38-5.38V79.41A5.38,5.38,0,0,0,732.84,74H715.58a5.38,5.38,0,0,0-5.38,5.39V101.1a5.38,5.38,0,0,1-5.39,5.38H667.24a5.38,5.38,0,0,1-5.38-5.38V86.17a64.42,64.42,0,0,1,.58-8.88A18.41,18.41,0,0,1,665,69.94a14.19,14.19,0,0,1,5.49-5,19.83,19.83,0,0,1,9.34-1.87,36.85,36.85,0,0,1,5.84.46h0A5.42,5.42,0,0,0,692,58.58l1-12.79A5.4,5.4,0,0,0,688.33,40a96.64,96.64,0,0,0-12.69-.8q-13.08,0-21.25,3.62a29,29,0,0,0-12.73,10.39,41.28,41.28,0,0,0-6.19,16.23,124.62,124.62,0,0,0-1.63,21.13v10.5a5.38,5.38,0,0,1-5.39,5.38H616.1a5.38,5.38,0,0,0-5.38,5.39v13a5.38,5.38,0,0,0,5.38,5.38h12.35a5.38,5.38,0,0,1,5.39,5.38v77.51a5.38,5.38,0,0,0,5.38,5.38h17.26a5.38,5.38,0,0,0,5.38-5.38V135.68a5.38,5.38,0,0,1,5.38-5.38h37.57a5.38,5.38,0,0,1,5.39,5.38v46A87.69,87.69,0,0,0,711.71,199a28.26,28.26,0,0,0,5.61,12.49,24.1,24.1,0,0,0,11.21,7.47q7.11,2.46,18.33,2.46a91.87,91.87,0,0,0,11.32-.82,43.45,43.45,0,0,0,7-1.44A5.42,5.42,0,0,0,769,214v-12a5.37,5.37,0,0,0-6.45-5.27l-.55.11a45,45,0,0,1-8.18.82q-9.33,0-12.49-4.91t-3.15-13.31V135.68a5.38,5.38,0,0,1,5.38-5.38h20.06A5.38,5.38,0,0,0,769,124.92Z" />
        <path d="M794.13,53.24h98.71a5.41,5.41,0,0,1,5.41,5.4V74.46a5.41,5.41,0,0,1-5.41,5.4H823.55a5.4,5.4,0,0,0-5.4,5.4v29.83a5.4,5.4,0,0,0,5.4,5.4h65.09a5.41,5.41,0,0,1,5.4,5.41v15.81a5.41,5.41,0,0,1-5.4,5.41H823.55a5.4,5.4,0,0,0-5.4,5.4v34a5.4,5.4,0,0,0,5.4,5.4h73.5a5.41,5.41,0,0,1,5.4,5.41v15.81a5.4,5.4,0,0,1-5.4,5.4H794.13a5.4,5.4,0,0,1-5.41-5.4V58.64A5.41,5.41,0,0,1,794.13,53.24Z" />
        <path d="M947.54,155.1l-29.91-40a5.39,5.39,0,0,1,4.31-8.62H942.4a5.38,5.38,0,0,1,4.48,2.4l16.52,24.77a5.38,5.38,0,0,0,8.85.17l18.13-25.1a5.36,5.36,0,0,1,4.36-2.24h18.56a5.39,5.39,0,0,1,4.34,8.59l-29.58,40a5.4,5.4,0,0,0,0,6.43L1024.31,210a5.38,5.38,0,0,1-4.31,8.61H999.4a5.36,5.36,0,0,1-4.35-2.22l-23.24-32a5.39,5.39,0,0,0-8.7,0l-23.45,32a5.38,5.38,0,0,1-4.35,2.2H915.64a5.38,5.38,0,0,1-4.31-8.61l36.21-48.41A5.39,5.39,0,0,0,947.54,155.1Z" />
        <path d="M1042.94,106.48h15.86a5.38,5.38,0,0,1,5.38,5.39V123.3h.47a36,36,0,0,1,16.34-15,53.05,53.05,0,0,1,22.19-4.67,56.62,56.62,0,0,1,23.12,4.55,51.9,51.9,0,0,1,17.51,12.5,53.63,53.63,0,0,1,11,18.68,70.18,70.18,0,0,1,3.73,23.12,66.1,66.1,0,0,1-4,23.12,57.49,57.49,0,0,1-11.09,18.68,51.13,51.13,0,0,1-17.16,12.49,52.47,52.47,0,0,1-22,4.56,54.13,54.13,0,0,1-14-1.64,48.28,48.28,0,0,1-10.74-4.2,42.8,42.8,0,0,1-7.94-5.49,39.38,39.38,0,0,1-5.37-5.72h-.7v62.11a5.38,5.38,0,0,1-5.38,5.38h-17.26a5.38,5.38,0,0,1-5.38-5.38V111.87A5.38,5.38,0,0,1,1042.94,106.48Zm87.56,56.05a36.44,36.44,0,0,0-2.22-12.61,31.6,31.6,0,0,0-6.54-10.74,33.11,33.11,0,0,0-48.34,0,31.42,31.42,0,0,0-6.53,10.74,36.92,36.92,0,0,0,0,25.22,31.42,31.42,0,0,0,6.53,10.74,33.13,33.13,0,0,0,48.34,0,31.6,31.6,0,0,0,6.54-10.74A36.44,36.44,0,0,0,1130.5,162.53Z" />
        <path d="M1171.23,162.53a59.29,59.29,0,0,1,4.79-24.17,56.51,56.51,0,0,1,13-18.57,57.4,57.4,0,0,1,19.38-11.91,69.65,69.65,0,0,1,47.64,0,57.4,57.4,0,0,1,19.38,11.91,56.51,56.51,0,0,1,13,18.57,63.38,63.38,0,0,1,0,48.34,56.37,56.37,0,0,1-13,18.56A57.08,57.08,0,0,1,1256,217.17a69.49,69.49,0,0,1-47.64,0A57.08,57.08,0,0,1,1189,205.26a56.37,56.37,0,0,1-13-18.56A59.32,59.32,0,0,1,1171.23,162.53Zm28,0a36.43,36.43,0,0,0,2.21,12.61,31.6,31.6,0,0,0,6.54,10.74,33.13,33.13,0,0,0,48.34,0,31.6,31.6,0,0,0,6.54-10.74,36.92,36.92,0,0,0,0-25.22,31.6,31.6,0,0,0-6.54-10.74,33.1,33.1,0,0,0-48.34,0,31.6,31.6,0,0,0-6.54,10.74A36.43,36.43,0,0,0,1199.26,162.53Z" />
        <path d="M86.48,49.43A86,86,0,0,0,43.26,61a36.64,36.64,0,0,0-.77,7.16Q42.49,85.7,52.61,95a78.58,78.58,0,0,0,25.11,15.18,304.57,304.57,0,0,0,32.69,10.51,139.69,139.69,0,0,1,32.7,13,76,76,0,0,1,25.1,22.57c.58.81,1.12,1.65,1.64,2.51A86.47,86.47,0,0,0,86.48,49.43Z" />
        <path d="M86.48,222.38a86,86,0,0,0,51.84-17.26,44.85,44.85,0,0,0,.31-5.38q0-16-10.12-24.33a87.92,87.92,0,0,0-25.1-14q-15-5.64-32.7-10.9A132.48,132.48,0,0,1,38,135.91,80,80,0,0,1,12.91,110.8a54.52,54.52,0,0,1-5.2-10.58A86.47,86.47,0,0,0,86.48,222.38Z" />
      </svg>
    </Root>
  );
};
