import { useState } from 'react';
import { Modal, Typography } from 'antd';
import { CardInput } from '../../../onboard/card_input/card_input';
import { ApiClient } from '../../../../api_client/api_client';
import { Loading } from '../../loading/loading';

const apiClient = new ApiClient();
const { Paragraph, Text } = Typography;

export interface AddPaymentMethodProps {
  onSuccess: () => void;
}

export const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({
  children,
  onSuccess,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [setupIntentSecret, setSetupIntentSecret] = useState('');

  const handleChildClick = async () => {
    if (showModal) return;
    setShowModal(true);
    const secret = await apiClient.createStripeSetupIntent();
    if (!secret) {
      setError('Error initializing payment method setup. Please refresh.');
    } else {
      setSetupIntentSecret(secret);
    }
  };

  const handlesuccess = () => {
    reset();
    onSuccess();
  };

  const handleCancel = () => {
    reset();
  };

  const reset = () => {
    setShowModal(false);
    setSetupIntentSecret('');
    setError('');
  };

  return (
    <div>
      <div onClick={handleChildClick}>{children}</div>
      <Modal
        title="Add payment method"
        visible={showModal}
        onCancel={handleCancel}
        footer={null}>
        {setupIntentSecret ? (
          <CardInput
            clientSecret={setupIntentSecret}
            onSuccess={handlesuccess}
            successMessage="Successfully added payment method."></CardInput>
        ) : error ? (
          <Paragraph>
            <Text type="danger">{error}</Text>
          </Paragraph>
        ) : (
          <Loading></Loading>
        )}
      </Modal>
    </div>
  );
};
