import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const Loading: React.FC = () => {
  return (
    <LoadingContainer>
      <LoadingOutlined></LoadingOutlined>
    </LoadingContainer>
  );
};
