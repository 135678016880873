import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Card, Button, Typography, notification, Modal } from 'antd';
import { LocationApiObject } from '../../../../declarations';
import { ApiClient } from '../../../../api_client/api_client';

const apiClient = new ApiClient();

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`;

const Left = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;

    @media (min-width: ${theme.breakpoints.desktop}) {
      display: grid;
      align-items: flex-start;
      gap: 2rem;
      grid-template-columns: 2fr 3fr;
    }
  `}
`;

const Right = styled.div``;

const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    margin-bottom: 0.5rem;
  `}
`;

export interface LocationItemProps {
  data: LocationApiObject;
  showDeleteButton?: boolean;
  onDelete: () => void;
}

export const LocationItem: React.FC<LocationItemProps> = ({
  data,
  showDeleteButton,
  onDelete,
}) => {
  const [name, setName] = useState(data.name);
  const [notes, setNotes] = useState(data.notes);
  const [error, setError] = useState('');
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const deleteLocation = async () => {
    setShowConfirmDelete(false);
    setDeleting(true);
    try {
      const deletedId = await apiClient.deleteLocation({ id: data._id });
      if (!deletedId) throw new Error();
      notification.success({ message: 'Deleted location' });
      setDeleting(false);
      onDelete();
    } catch {
      setDeleting(false);
      setError('Unable to delete location. Refresh the page and try again.');
    }
  };

  const save = async () => {
    setSaving(true);
    try {
      await apiClient.updateLocation({
        id: data._id,
        name,
        notes,
      });
      notification.success({ message: 'Updated location' });
      setDirty(false);
    } catch (err) {
      setError('Unable to update location. Please refreh and try again.');
    }
    setSaving(false);
  };

  const onNameChange = (value: string) => {
    setName(value);
    setDirty(value !== data.name || notes !== data.notes);
  };

  const onNotesChange = (value: string) => {
    setNotes(value);
    setDirty(value !== data.notes || name !== data.name);
  };

  return (
    <Card>
      <Container>
        <Left>
          <div>
            <Label>Name</Label>
            <Typography.Title
              level={4}
              style={{ margin: 0 }}
              editable={{ onChange: onNameChange }}>
              {name}
            </Typography.Title>
          </div>
          <div>
            <Label>Notes</Label>
            <Typography.Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: onNotesChange,
                autoSize: { maxRows: 7, minRows: 5 },
              }}>
              {notes}
            </Typography.Paragraph>
          </div>
        </Left>
        <Right>
          {showDeleteButton && (
            <Button
              danger
              onClick={() => setShowConfirmDelete(true)}
              loading={deleting}>
              Delete
            </Button>
          )}
        </Right>
      </Container>
      {dirty && (
        <div>
          <Button onClick={save} loading={saving}>
            Save
          </Button>
        </div>
      )}
      {error && (
        <div>
          <Typography.Paragraph type="danger">{error}</Typography.Paragraph>
        </div>
      )}
      <Modal
        title="Confirm Deletion"
        visible={showConfirmDelete}
        onOk={deleteLocation}
        onCancel={() => setShowConfirmDelete(false)}
        okText="Delete"
        cancelText="Cancel">
        <Typography.Paragraph strong>
          Are you sure you want to delete this location?
        </Typography.Paragraph>
        <Typography.Paragraph>
          Deleting this location will prevent you from being able to export tickets from this location.
        </Typography.Paragraph>
      </Modal>
    </Card>
  );
};
