import { Typography, Checkbox } from 'antd';
import styled, { css } from 'styled-components';
import { LocationState } from '../../../../contexts/location/reducer';
import { LocationApiObject } from '../../../../declarations';
import { Loading } from '../../loading/loading';

const { Title } = Typography;

const Options = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: ${theme.breakpoints.desktop}) {
      gap: 2rem;
      flex-direction: row;
    }
  `}
`;

interface LocationCheckboxOption {
  label: LocationApiObject['name'];
  value: LocationApiObject['_id'];
}

export interface SelectLocationProps {
  exportAllLocations: boolean;
  setExportAllLocations: (a: boolean) => void;
  selectedLocations: Array<LocationApiObject['_id']>;
  setSelectedLocations: (a: Array<LocationApiObject['_id']>) => void;
  locationState: LocationState;
}

function mapLocationToCheckboxOption(
  location: LocationApiObject,
): LocationCheckboxOption {
  return {
    label: location.name,
    value: location._id,
  };
}

export const SelectLocation: React.FC<SelectLocationProps> = ({
  exportAllLocations,
  setExportAllLocations,
  selectedLocations,
  setSelectedLocations,
  locationState,
}) => {
  if (!locationState.locationsFetched) {
    return <Loading></Loading>;
  }

  const options = locationState.locations.map(mapLocationToCheckboxOption);

  return (
    <div>
      <Title level={4}>Select Location(s)</Title>
      <Options>
        {options.length > 1 && (
          <Checkbox
            checked={exportAllLocations}
            onChange={e => void setExportAllLocations(e.target.checked)}>
            Export All Locations
          </Checkbox>
        )}
        <Checkbox.Group
          disabled={exportAllLocations || options.length === 1}
          options={options}
          value={selectedLocations}
          onChange={selectedValues =>
            void setSelectedLocations(selectedValues as string[])
          }
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}></Checkbox.Group>
      </Options>
    </div>
  );
};
