import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  MerchantModel,
  MerchantModelProperty,
} from '../../../merchant_service/merchant_model';
import { CancelNotice } from '../cancel_notice/cancel_notice';

const Root = styled.div`
  ${({ theme }) => css`
    box-sizing: border-box;
    max-height: 100%;
    height: auto;
    width: 100%;
    max-width: 64rem;
    padding: ${theme.spacing.dashboardVerticalGutter} 2.5rem;

    @media (min-width: ${theme.breakpoints.desktopWide}) {
      max-width: 75rem;
    }
  `}
`;

const CancelNoticeWrapper = styled.div`
  margin-bottom: 2rem;
`;

export interface PageContainerProps {
  merchantModel: MerchantModel;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  merchantModel,
}) => {
  const [isCanceledOrWillCancel, setIsCanceledOrWillCancel] = useState(
    merchantModel.isCanceledOrWillCancelSubscription(),
  );

  useEffect(() => {
    const handleSubscriptionChange = () => {
      setIsCanceledOrWillCancel(
        merchantModel.isCanceledOrWillCancelSubscription(),
      );
    };

    merchantModel.events.on(
      MerchantModelProperty.SUBSCRIPTION,
      handleSubscriptionChange,
    );
    return () => {
      merchantModel.events.off(
        MerchantModelProperty.SUBSCRIPTION,
        handleSubscriptionChange,
      );
    };
  }, [merchantModel, merchantModel.events]);

  return (
    <Root>
      {isCanceledOrWillCancel && (
        <CancelNoticeWrapper>
          <CancelNotice merchantModel={merchantModel}></CancelNotice>
        </CancelNoticeWrapper>
      )}
      {children}
    </Root>
  );
};
