import { Button, Form, Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ApiClient } from '../../api_client/api_client';
import { PASSWORD_RESET_TOKEN_KEY } from '../../constants/url_constants';
import { useQuery } from '../../hooks/query';
import { isExpired } from '../../utils/auth_util';
import { AuthPage } from '../auth_page/auth_page';
import { Loading } from '../dashboard/loading/loading';
import { LoadingPage } from '../loading_page/loading_page';
import { InitiatePasswordReset } from './initiate_password_reset/initiate_password_reset';
import { ResetForm } from './reset_form/reset_form';

const apiClient = new ApiClient();

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResetPassword: React.FC = () => {
  const query = useQuery();
  const token = query.get(PASSWORD_RESET_TOKEN_KEY);
  const [checking, setChecking] = useState(true);
  const [isTokenValid, setIsValidToken] = useState(false);

  useEffect(() => {
    if (!token) return;
    if (isExpired(token)) {
      setChecking(false);
      setIsValidToken(false);
      return;
    }

    (async () => {
      const isValid = await apiClient.verifyPasswordResetToken(token);
      setChecking(false);
      setIsValidToken(isValid);
    })();
  }, [token]);

  if (!token) {
    return <InitiatePasswordReset></InitiatePasswordReset>;
  }

  if (checking) {
    return <AuthPage>
      <Typography.Title level={4}>Reset Password</Typography.Title>
      <Loading></Loading>
    </AuthPage>;
  }

  if (!isTokenValid) {
    return (
      <AuthPage>
        <Typography.Title level={4}>Reset Password</Typography.Title>
        <Root>
          <Typography.Paragraph>
            This URL is either invalid or expired.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Please try <Link to="/reset_password">resetting the password</Link>{' '}
            again.
          </Typography.Paragraph>
        </Root>
      </AuthPage>
    );
  }

  return <ResetForm token={token}></ResetForm>;
};
