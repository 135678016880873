import styled, { css } from 'styled-components';
import { Spinner } from '../spinner/spinner';

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  ${({ theme }) => `
    font-size: ${theme.typography.sizes.heading3};
  `}
`;

const LoadingText = styled.h1`
  ${({ theme }) => `
    font-size: ${theme.typography.sizes.heading4};
  `}
`;

const ErrorText = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.heading4};
    color: ${theme.colors.error};
  `}
`;

export interface LoadingPageProps {
  errorText?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({ errorText }) => (
  <Root>
    {errorText ? (
      <ErrorText>{errorText}</ErrorText>
    ) : (
      <LoadingText>Loading...</LoadingText>
    )}
    <Spinner></Spinner>
  </Root>
);
