import { TicketState } from '../../../declarations';
import { TicketsState } from '../reducer';

export function updateTicketState(
  state: TicketsState,
  ticketId: string,
  ticketState: TicketState,
): TicketsState {
  // Deep clone first.
  const tickets = [...state.tickets].map(ticket => ({ ...ticket }));

  // Mutate the ticket in question.
  for (const ticket of tickets) {
    if (ticket._id === ticketId) {
      ticket.state = ticketState;
    }
  }

  return {
    ...state,
    selectedTicketId: '',
    tickets,
  };
}
