import styled, { css } from 'styled-components';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const Root = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const IconContainer = styled.div<{isValid: boolean}>`
  ${({ theme, isValid }) => css`
    color: ${isValid ? theme.colors.primary : theme.colors.error};
  `}
`;

interface ValidationItemProps {
  isValid: boolean;
  message: string;
}

export const ValidationItem: React.FC<ValidationItemProps> = ({
  isValid,
  message,
}) => {

  return <Root>
    <IconContainer isValid={isValid}>
      {isValid ? <CheckOutlined /> : <CloseOutlined />}
    </IconContainer>
    <Typography.Text>{message}</Typography.Text>
  </Root>;
};
