import { MouseEventHandler } from 'react';
import { BiMessageRounded } from 'react-icons/bi';

import { ButtonContent } from '../../../button/button_content';
import { Spinner } from '../../../spinner/spinner';
import { Button, ButtonStyle } from '../../../button/button';
import { TicketApiObject, TicketState } from '../../../../declarations';

export interface NotifyButtonProps {
  data: TicketApiObject;
  inFlight: boolean;
  onClick: MouseEventHandler;
}

export const NotifyButton: React.FC<NotifyButtonProps> = ({
  data,
  inFlight,
  onClick,
}) => {
  const isOpen = data.state === TicketState.OPEN;

  return (
    <Button
      buttonStyle={isOpen ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY}
      onClick={onClick}
      disabled={inFlight}>
      {inFlight ? (
        <Spinner></Spinner>
      ) : (
        <ButtonContent>
          {isOpen ? <span>Notify</span> : <span>Follow Up</span>}
          <BiMessageRounded></BiMessageRounded>
        </ButtonContent>
      )}
    </Button>
  );
};
