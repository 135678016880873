import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { LocationContext } from '../../../../contexts/location/provider';
import { LocationApiObject } from '../../../../declarations';

export interface LocationButtonsProps {
  onClick: (location: LocationApiObject) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Button = styled.button<{ current: boolean }>`
  ${({ theme, current }) => css`
    font-weight: ${theme.typography.weights.bold};
    letter-spacing: ${theme.typography.spacing.label};
    font-size: ${theme.typography.sizes.label};
    border: 0;
    background: transparent;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    cursor: pointer;

    ${current &&
    css`
      background: ${theme.colors.newPrimary};
      color: ${theme.colors.surface};
    `}
  `}
`;

export const LocationButtons: React.FC<LocationButtonsProps> = ({
  onClick,
}) => {
  const [locationState] = useContext(LocationContext);

  return (
    <Root>
      {locationState.locations.map(location => (
        <Button
          key={`location_drawer_${location._id}`}
          onClick={() => onClick(location)}
          current={location._id === locationState.currentLocationId}>
          {location.name}
        </Button>
      ))}
    </Root>
  );
};
