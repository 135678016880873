import styled from 'styled-components';
import { MerchantModel } from '../../../merchant_service/merchant_model';
import { PageContainer } from '../page_container/page_container';
import { Typography, Checkbox, Card, Button } from 'antd';
import { ExportOptionColumn, LocationApiObject } from '../../../declarations';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../contexts/location/provider';
import { fetchAndSetLocations } from '../../../contexts/location/async_actions';
import { Loading } from '../loading/loading';
import { SelectLocation } from './select_location/select_location';
import { ApiClient } from '../../../api_client/api_client';
import { saveAs } from 'file-saver';
import { DefaultTimeRangeValue, getStartTimestampMsFromTimeRange, SelectDate } from './select_date/select_date';
import { ExportOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

const apiClient = new ApiClient();

const { Title, Paragraph } = Typography;

const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CardRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ExportButtonContainer = styled.div`
  margin-top: 1rem;
`;

interface ColumnCheckboxOption {
  label: string;
  value: ExportOptionColumn;
}

const OPTIONS: Array<ColumnCheckboxOption> = [
  { label: 'Created Date', value: ExportOptionColumn.CREATED_DATE },
  { label: 'Location', value: ExportOptionColumn.LOCATION },
  { label: 'Phone number', value: ExportOptionColumn.PHONE },
  { label: 'Ticket number', value: ExportOptionColumn.TICKET },
  { label: 'Ticket Notes', value: ExportOptionColumn.NOTES },
];

export interface ExportPageProps {
  merchantModel: MerchantModel;
}

export const ExportPage: React.FC<ExportPageProps> = ({ merchantModel }) => {
  const [columnOptions, setColumnOptions] = useState<ExportOptionColumn[]>([
    ExportOptionColumn.CREATED_DATE,
    ExportOptionColumn.PHONE,
  ]);
  const [locationState, locationDispatch] = useContext(LocationContext);
  const [exportAllLocations, setExportAllLocations] = useState(
    !locationState.currentLocationId,
  );
  const [selectedLocations, setSelectedLocations] = useState<
    Array<LocationApiObject['_id']>
  >(locationState.currentLocationId ? [locationState.currentLocationId] : []);
  const [selectedDefaultTimeRange, setSelectedDefaultTimeRange] = useState(
    DefaultTimeRangeValue.ONE_DAY,
  );
  const [startMoment, setStartMoment] = useState<null | Moment>(null);
  const [endMoment, setEndMoment] = useState<null | Moment>(null);
  const [error, setError] = useState('');
  const [exportError, setExportError] = useState('');
  const [exportInFlight, setExportInFlight] = useState(false);

  const onChange = (selectedValues: any) => {
    setColumnOptions(selectedValues);
  };

  useEffect(() => {
    (async () => {
      if (!locationState.locationsFetched) {
        const success = await fetchAndSetLocations(locationDispatch);
        if (!success) {
          setError('Unable to fetch locations. Please refresh the page.');
        }
      }
    })();
  }, [locationState.locationsFetched, locationDispatch]);

  const doExport = async () => {
    let startTimestampMs;
    let endTimestampMs;
    if (startMoment && endMoment) {
      startTimestampMs = moment(startMoment).startOf('day').unix() * 1000;
      endTimestampMs = moment(endMoment).endOf('day').unix() * 1000;
    } else {
      startTimestampMs = getStartTimestampMsFromTimeRange(selectedDefaultTimeRange);
    }

    setExportInFlight(true);
    setExportError('');
    const csvContent = await apiClient.exportTickets({
      includeAllLocations: exportAllLocations,
      locations: selectedLocations,
      columns: columnOptions,
      startTimestampMs,
      endTimestampMs,
    });
    setExportInFlight(false);
    if (!csvContent) {
      setExportError('Unable to export. Please refresh and try again.');
      return;
    }
    const blob = new Blob([csvContent], {
      type: 'text/csv',
    });
    saveAs(blob, `swiftexpo_export_${new Date().getTime()}.csv`);
  };

  if (!locationState.locationsFetched) {
    return <Loading></Loading>;
  }

  if (error) {
    return <Paragraph type="danger">{error}</Paragraph>;
  }

  const shouldDisableExportButton = () => {
    if (
      selectedDefaultTimeRange === DefaultTimeRangeValue.CUSTOM &&
      (startMoment === null || endMoment === null)
    ) {
      return true;
    }
    return false;
  };

  return (
    <PageContainer merchantModel={merchantModel}>
      <Spacer>
        <Title>Export Tickets to CSV</Title>
        <Card>
          <Title level={2}>Options</Title>
          <Title level={4}>Select Columns</Title>
          <CardRoot>
            <div>
              <Checkbox.Group
                options={OPTIONS}
                value={columnOptions}
                onChange={onChange}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '.5rem',
                }}
              />
            </div>
            <SelectLocation
              locationState={locationState}
              exportAllLocations={exportAllLocations}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              setExportAllLocations={setExportAllLocations}></SelectLocation>
            <SelectDate
              startMoment={startMoment}
              setStartMoment={setStartMoment}
              endMoment={endMoment}
              setEndMoment={setEndMoment}
              selectedDefaultTimeRange={selectedDefaultTimeRange}
              setSelectedDefaultTimeRange={
                setSelectedDefaultTimeRange
              }></SelectDate>
            <ExportButtonContainer>
              <Button
                disabled={shouldDisableExportButton()}
                size="large"
                icon={<ExportOutlined />}
                loading={exportInFlight}
                type="primary"
                onClick={doExport}>
                Export
              </Button>
            </ExportButtonContainer>
            {exportError && <Paragraph type="danger">{exportError}</Paragraph>}
          </CardRoot>
        </Card>
      </Spacer>
    </PageContainer>
  );
};
