import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ApiClient } from '../../api_client/api_client';

const apiClient = new ApiClient();

export const CheckMaintenanceMode: React.FC = ({ children }) => {
  const [maintenance, setMaintenance] = useState(false);
  let location = useLocation();

  useEffect(() => {
    (async () => {
      if (location.pathname === '/maintenance') return;
      const isMaintenanceMode = await apiClient.isMaintenanceMode();
      setMaintenance(isMaintenanceMode);
    })();
  }, [location.pathname]);

  if (location.pathname !== '/maintenance' && maintenance) {
    return <Redirect to="/maintenance"></Redirect>;
  }

  return <>{children}</>;
};
