import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { Button, Input, Form } from 'antd';
import { AuthPage } from '../auth_page/auth_page';
import { InputContainer } from '../input_container/input_container';
import { InputLabel } from '../input_label/input_label';
import { ApiClient } from '../../api_client/api_client';
import { isAdmin as hasAdminToken } from '../../utils/auth_util';
import { Typography } from 'antd';
import { Center } from '../center/center';
import { InitiatedPasscodeReset } from './initiated_passcode_reset/initiated_passcode_reset';
import { Loading } from '../dashboard/loading/loading';

const apiClient = new ApiClient();

const Error = styled.div`
  margin-top: 1rem;
`;

export const AdminProtected: React.FC = ({ children }) => {
  const [adminPasscode, setAdminPasscode] = useState('');
  const [isAdmin, setIsAdmin] = useState(hasAdminToken());
  const [error, setError] = useState('');
  const [initiatedReset, setInitiatedReset] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const [initiatingReset, setInitiatingReset] = useState(false);

  const handleSubmit = async () => {
    setInFlight(true);
    setError('');

    const isAdmin = await apiClient.checkAdmin(adminPasscode);
    if (isAdmin) {
      setIsAdmin(true);
    } else {
      setError('Invalid passcode');
    }
    setInFlight(false);
  };

  const onForgotPasscodeClick = useCallback(async () => {
    setInitiatingReset(true);
    const success = await apiClient.initiateAdminPasscodeReset();
    setInitiatingReset(false);
    if (success) {
      setInitiatedReset(true);
    } else {
      setError('Unable to initiate passcode reset. Please try again.');
    }
  }, []);

  if (isAdmin) {
    return <>{children}</>;
  }

  if (initiatedReset) {
    return (
      <AuthPage>
        <InitiatedPasscodeReset></InitiatedPasscodeReset>
      </AuthPage>
    );
  }

  return (
    <AuthPage>
      <Center>
        <Typography.Title level={4}>Dashboard</Typography.Title>
      </Center>
      <Form onFinish={() => handleSubmit()}>
        <InputContainer>
          <InputLabel htmlFor="passcode">Admin Passcode:</InputLabel>
          <Input
            type="password"
            value={adminPasscode}
            onChange={e => void setAdminPasscode(e.target.value)}></Input>
        </InputContainer>
        <Button
          htmlType="submit"
          type="primary"
          loading={inFlight}
          disabled={!adminPasscode || inFlight}>
          Submit
        </Button>
        <Center style={{marginTop: '1rem'}}>
          {initiatingReset ? (
            <Loading></Loading>
          ) : (
            <Button type="link" onClick={onForgotPasscodeClick}>Forgot passcode?</Button>
          )}
        </Center>
        {error && (
          <Error>
            <Typography.Text type="danger">{error}</Typography.Text>
          </Error>
        )}
      </Form>
    </AuthPage>
  );
};
